<template>
    <v-dialog :value="value" max-width="1000" @click:outside="$emit('input', false)">
        <v-card>
            <v-form v-model="valid">
                <slot name="title">
                    <v-card-title class="headline">{{ $t('new_message') }}</v-card-title>
                </slot>
                <slot>
                    <v-card-text>
                        <v-checkbox
                            v-if="showCommentCheckbox"
                            v-model="commentcheckbox"
                            :value="empfaengerlist === null"
                            :label="$t('gesuch_comment')"
                        >
                        </v-checkbox>
                        <v-select
                            v-if="empfaengerlist"
                            v-model="empfaenger"
                            :disabled="commentcheckbox"
                            :items="empfaengerlist"
                            :label="$t('empfaenger')"
                            :rules="[commentcheckbox || empfaengerRules]"
                            multiple
                        >
                        </v-select>
                        <kp-textfield v-model="subject" :label="$t('betreff')" :rules="[rules.required]"></kp-textfield>
                        <v-textarea
                            v-model="mitteilung"
                            :label="$t('mitteilung')"
                            :rules="[rules.required]"
                        ></v-textarea>
                        <div
                            ref="filedrop"
                            class="filedrop"
                            @dragover="addHover"
                            @dragleave="removeHover"
                            @drop="removeHover"
                        >
                            <span>{{ $t('dateien_anhaengen') }}</span>
                        </div>
                        <div v-for="attachment in attachments" :key="attachment.name" class="fileItem">
                            {{ attachment.name
                            }}<v-btn
                                icon
                                color="stop white--text"
                                :disabled="uploading"
                                @click="onFileItemRemove(attachment)"
                            >
                                <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                        </div>
                    </v-card-text>
                </slot>
                <slot name="actions">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="decline" @click="cancel">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn color="action" :disabled="!valid || uploading" @click="send">
                            <v-icon left>fas fa-check</v-icon>
                            {{ $t('send') }}
                        </v-btn>
                    </v-card-actions>
                </slot>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { formRules } from 'js/lib/helpers.js';
import { mapActions } from 'vuex';
import { Mitteilung, MODE } from 'js/models/Mitteilung';
import { STATUS } from 'js/models/Gesuch';
import { errToStr } from 'js/lib/helpers.js';
import { getCsrfToken } from 'js/lib/auth';
import { buildUrl } from 'js/lib/api';
import Flow from '@flowjs/flow.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    props: {
        gesuch: {
            type: Object,
            default: null
        },
        empfaengerlist: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data: function () {
        return {
            value: Boolean,
            empfaenger: null,
            subject: null,
            mitteilung: null,
            rules: formRules,
            valid: false,
            commentcheckbox: false,
            attachments: [],
            uploading: false
        };
    },
    computed: {
        showCommentCheckbox() {
            return (
                this.gesuch.status === STATUS.NEW ||
                this.gesuch.status === STATUS.READY_FOR_SUBMIT ||
                this.gesuch.status === STATUS.SUBMIT
            );
        }
    },
    watch: {},
    mounted() {
        this.flow = new Flow({
            singleFile: false,
            simultaneousUploads: 1,
            target: buildUrl('/mitteilung/uploadAttachment'),
            testChunks: false,
            chunkSize: (flowFile) => flowFile.size,
            headers: {
                'X-CSRF-Token': getCsrfToken()
            }
        });
        this.flow.on('fileAdded', this.onFlowFileAdded.bind(this));
        this.flow.on('fileRemoved', this.removeFile.bind(this));

        this.flow.assignBrowse(this.$refs.filedrop);
        this.flow.assignDrop(this.$refs.filedrop);
    },

    beforeDestroy() {
        this.flow.off();
    },
    methods: {
        ...mapActions('mitteilung', ['storeMitteilung']),
        cancel() {
            this.$emit('close');
        },
        async send() {
            this.uploading = true;
            let msg = new Mitteilung({
                gesuch_id: this.gesuch.id,
                empfaenger_rollen: this.commentcheckbox ? 'gesuchcomment' : this.empfaenger,
                mode: this.commentcheckbox ? MODE.BROADCAST : MODE.STANDARD,
                subject: this.subject,
                mitteilung: this.mitteilung
            });
            try {
                await this.storeMitteilung(msg);
                if (!msg.id) {
                    this.$toast({ message: this.$t('fehler_beim_speichern'), color: 'error' });
                }
                await this.sendAttachments(msg);
                this.$emit('mitteilungSent');
            } catch (res) {
                console.error(res);
                this.$toast({ message: errToStr(res), color: 'error' });
            }

            this.uploading = false;
        },

        empfaengerRules(value) {
            let ret = false;
            if (value) {
                ret = value.length > 0 ? true : this.$t('empfaneger_missing');
            } else {
                ret = this.$t('empfaneger_missing');
            }
            return ret;
        },

        addHover(e) {
            e.target && e.target.classList.add('hover');
        },

        removeHover(e) {
            e.target && e.target.classList.remove('hover');
        },

        onFlowFileAdded(file) {
            this.attachments.push(file);
        },

        onFileItemRemove(file) {
            this.flow.removeFile(file);
        },

        removeFile(file) {
            for (var i = this.attachments.length - 1; i >= 0; i--) {
                if (this.attachments[i] === file) {
                    this.attachments.splice(i, 1);
                }
            }
        },

        sendAttachments(msg) {
            if (this.attachments.length === 0) {
                return;
            }
            return new Promise((resolve, reject) => {
                this.disableFileInput();
                this.uploading = true;
                this.flow.opts.query = {
                    mitteilung_id: msg.id
                };

                let fn = () => {
                    this.flow.off('complete', fn);
                    resolve();
                };

                this.flow.on('complete', fn);

                this.flow.on('error', (message) => {
                    this.flow.cancel();
                    this.flow.off('complete', fn);
                    reject(message);
                });

                this.flow.upload();
            });
        },

        disableFileInput() {
            this.$refs.filedrop.getElementsByTagName('input')[0].disabled = true;
        },

        enableFileInput() {
            this.$refs.filedrop.getElementsByTagName('input')[0].disabled = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.filedrop {
    margin-top: 2em;
    padding: 3em;
    border: 5px dashed lightgray;
    border-radius: 10px;
    color: gray;
    text-align: center;
    vertical-align: middle;

    &.hover {
        border-color: seagreen;
        color: seagreen;
    }
}
</style>
