/**
 * Store module: downloadcount
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { DownloadCountCollection } from 'js/models/DownloadCount';

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        /**
         *
         * @param {Object} store The vuex store
         */
        async fetchDownloadCounts({ commit }, options = {}) {
            let list = new DownloadCountCollection();

            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        }
    }
};
