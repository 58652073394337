import Vue from 'vue';
import Vuex from 'vuex';
import benutzer from 'js/store/modules/benutzer';
import adressen from 'js/store/modules/adressen';
import news from 'js/store/modules/news';
import downloadcount from 'js/store/modules/downloadcount';
import anrede from 'js/store/modules/anrede';
import land from 'js/store/modules/land';
import liegenschaften from 'js/store/modules/liegenschaften';
import teams from 'js/store/modules/teams';
import kanton from 'js/store/modules/kanton';
import foerderkategorie_gruppe from 'js/store/modules/foerderkategorie_gruppe';
import gesuch from 'js/store/modules/gesuch';
import lookup from 'js/store/modules/lookup';
import apollonConfFk from 'js/store/modules/apollonConfFk';
import mitteilung from 'js/store/modules/mitteilung';
import eigentuemerschaft from 'js/store/modules/eigentuemerschaft';

Vue.use(Vuex);

let _store = null;

const init = function (initialData) {
    _store = new Vuex.Store({
        state: {
            session: null,
            appVersion: '',
            webroot: '',
            loading: false,
            loadingCounter: 0,
            snackbarContent: '',
            redirectUrl: '/',
            customer: null,
            config: {},
            ...initialData
        },
        mutations: {
            reset(state) {
                state.session = null;
            },

            setSession(state, session) {
                state.session = session;
            },

            loading(state, loading) {
                if (loading) {
                    state.loadingCounter++;
                } else {
                    state.loadingCounter = Math.max(0, state.loadingCounter - 1);
                }
                state.loading = state.loadingCounter > 0;
            },
            toast(state, payload) {
                state.snackbarContent = payload.content;
            },
            setRedirectUrl(state, url) {
                state.redirectUrl = url;
            },
            setConfig(state, config) {
                state.config = config;
            },
            setCustomer(state, customer) {
                state.customer = customer;
            }
        },
        actions: {
            reset({ commit }) {
                commit('reset');
                commit('benutzer/reset');
            },
            async runAsync({ commit }, runner) {
                commit('loading', true);
                let res = null;
                try {
                    res = await runner();
                } finally {
                    commit('loading', false);
                }
                return res;
            },
            async redirectAfterLogin({ commit, state }) {
                const url = state.redirectUrl;
                window.location.href = window.location.href.replace(/\?.*|#.*/, '') + '#' + decodeURIComponent(url);
                commit('setRedirectUrl', '/');
            }
        },
        getters: {
            isAuthenticated: (state) => state.benutzer.record !== null,
            isAdmin: (state) => state.benutzer.record !== null && state.benutzer.record.rolle === 'admin',
            getBenutzer: (state) => state.benutzer.record
        },
        modules: {
            adressen,
            anrede,
            apollonConfFk,
            benutzer,
            foerderkategorie_gruppe,
            gesuch,
            kanton,
            land,
            liegenschaften,
            teams,
            lookup,
            mitteilung,
            eigentuemerschaft,
            news,
            downloadcount
        }
    });
    return _store;
};

export const getStore = function () {
    if (!_store) {
        throw new Error('Store is not initialized. Use init().');
    }
    return _store;
};

export default {
    init,
    getStore
};
