/**
 * Store module: foerderkategorie_gruppe
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { FoerderkategorieGruppe } from 'js/models/FoerderkategorieGruppe';
import { LookupCollection } from 'js/models/BaseCollection';

export default {
    namespaced: true,
    state: {
        records: null,
        loading: false
    },
    mutations: {
        loading(state, isLoading) {
            state.loading = !!isLoading;
        },
        setFoerderkategorieGruppen(state, gruppen) {
            state.records = gruppen || [];
        }
    },
    actions: {
        /**
         * Loads all FoerderkategorieGruppen, once, and commits it to the store
         * while also returning them.
         *
         * @param {Object} store The vuex store
         */
        async fetchFoerderkategorieGruppen({ commit, state }) {
            if (state.records || state.loading) {
                return state.records;
            }
            try {
                commit('loading', true);
                let collection = new LookupCollection(null, { model: FoerderkategorieGruppe });
                await collection.query();
                commit('setFoerderkategorieGruppen', collection.models);
            } finally {
                commit('loading', false);
            }
            return state.records;
        }
    }
};
