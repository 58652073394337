/**
 * Base collection model. All Apollon model collections should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Collection } from 'vue-mc';
import { instance as getApi, getTokenSource } from 'js/lib/api.js';

const globalCancelTokens = {};

export default class BaseCollection extends Collection {
    defaults() {
        return {
            // The total record count as delivered by the backend
            remoteTotal: 0,
            apiCancelToken: null
        };
    }
    /**
     * Override in child classes: This is the API endpoint's
     * entity name (e.g. like in /api/Benutzer/list --> Benutzer)
     *
     * @return {String} The API endpoint's Entity name
     */
    entityName() {
        return this.constructor.name;
    }

    /**
     * Creates a new cancel token, cancels an ALREADY issued token for this collection.
     * If the 'apiCancelToken' attribute is set on this collection, a global token
     * will be used, otherwise an instance-only token is used.
     */
    createCancelToken() {
        let tokenSrc = null;
        let cancelTokenIdent = this.get('apiCancelToken');

        // Cancel an already running request, if applicable:
        if (cancelTokenIdent && globalCancelTokens[cancelTokenIdent]) {
            tokenSrc = globalCancelTokens[cancelTokenIdent];
        } else {
            tokenSrc = this._cancelTokenSrc;
        }

        if (tokenSrc) {
            tokenSrc.cancel();
            this.removeCancelToken();
        }

        tokenSrc = getTokenSource();

        // If requested, store a global cancel token for later cancellation:
        if (cancelTokenIdent) {
            globalCancelTokens[cancelTokenIdent] = tokenSrc;
        }
        this._cancelTokenSrc = tokenSrc;
        return tokenSrc.token;
    }

    removeCancelToken() {
        let cancelTokenIdent = this.get('apiCancelToken');
        if (cancelTokenIdent) {
            delete globalCancelTokens[cancelTokenIdent];
        }
        this._cancelTokenSrc = null;
    }

    /**
     * Simple, generic query function: calls /entity/list API, with
     * the given options as request params.
     *
     * Fills this collection with the returned records.
     *
     * @param {Object} options request parameters
     */
    async query(options) {
        let cancelToken = this.createCancelToken();
        let response = await getApi().post(`/${this.entityName()}/list`, options, {
            cancelToken: cancelToken
        });
        this.removeCancelToken();

        this.replace(response ? response.data.records : null);
        this.set('remoteTotal', response ? response.data.total : null);
        return this;
    }
}

/**
 * Generic Collection for the /lookup/ routes:
 *
 * Provide the model you want in the constructor, e.g.
 *
 * l = new LookupCollection(null, {model: Ort});
 * l.query('foo'); ==> request to: /api/lookup/ort?query=foo
 */
export class LookupCollection extends BaseCollection {
    /**
     * Simple, generic query function: calls /entity/list API, with
     * the given options as request params.
     *
     * Fills this collection with the returned records.
     *
     * @param {Object} options request parameters
     */
    async query(options) {
        let Model = this.getOption('model');
        let m = new Model();
        let entityName = m.entityName();
        let cancelToken = this.createCancelToken();
        let response = await getApi().get(`/lookup/${entityName}`, {
            params: options,
            cancelToken: cancelToken
        });
        this.removeCancelToken();

        this.replace(response ? response.data.records : null);
        return this;
    }

    /**
     * Fills this collection with the returned records.
     *
     * @param {Object} options request parameters
     */
    async queryValues(options) {
        let cancelToken = this.createCancelToken();
        let response = await getApi().get(`/lookup/queryValues`, {
            params: options,
            cancelToken: cancelToken
        });
        this.removeCancelToken();

        this.replace(response ? response.data.records : null);
        return this;
    }
}
