<template>
    <v-dialog :value="value" max-width="1000" @click:outside="$emit('input', false)">
        <v-card>
            <slot name="title">
                <v-card-title class="headline">{{ $t('assign_object') }}</v-card-title>
            </slot>
            <slot>
                <v-card-text>
                    <v-card tile height="220px" :loading="loading">
                        <v-card-text>
                            <div v-if="newLiegenschaft">
                                <v-card-title>
                                    {{ newLiegenschaft.strasse }} {{ newLiegenschaft.strassen_nummer }}
                                    <v-spacer />
                                </v-card-title>
                                <v-card-text>
                                    {{ newLiegenschaft.ort_plz }} {{ newLiegenschaft.ort }}
                                    <br />
                                    {{ $t('gemeinde') }}: {{ newLiegenschaft.gemeinde_bezeichnung }}
                                    <br />
                                    {{ $t('baujahr') }}: {{ newLiegenschaft.baujahr }}
                                    <br />
                                    {{ $t('egid') }}: {{ newLiegenschaft.egid }}
                                    <br />
                                    {{ $t('parzellen_nummer') }}: {{ newLiegenschaft.parzellen_nummer }}
                                    <br />
                                    {{ $t('assekuranz_nummer') }}: {{ newLiegenschaft.assekuranz_nummer }}
                                </v-card-text>
                            </div>
                            <div v-else>
                                <v-card-subtitle>{{ $t('choose_object') }}</v-card-subtitle>
                            </div>
                        </v-card-text>
                    </v-card>
                    <LiegenschaftGrid
                        :edit="false"
                        :dense="true"
                        :selectable="true"
                        :height="300"
                        :addbtn="true"
                        :selection="newLiegenschaft"
                        @selection="selectionChanged"
                        @new-liegenschaft="$emit('new-liegenschaft')"
                    ></LiegenschaftGrid>
                </v-card-text>
            </slot>
            <slot name="actions">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="decline" :disabled="loading" @click="cancel">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="action" :loading="loading" :disabled="!newLiegenschaft" @click="assign">
                        <v-icon left>fas fa-check</v-icon>
                        {{ $t('assign') }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
import { Gesuch } from 'js/models/Gesuch';
import { Liegenschaft } from 'js/models/Liegenschaft';
import LiegenschaftGrid from 'js/components/liegenschaft-grid.vue';

export default {
    components: {
        LiegenschaftGrid
    },
    props: {
        value: Boolean, // v-model (true: show, hide: false)
        loading: { type: Boolean, default: false },
        gesuch: { type: Gesuch, default: null },
        liegenschaft: { type: Liegenschaft, default: null }
    },
    data: function () {
        return {
            newLiegenschaft: null
        };
    },
    watch: {
        liegenschaft: function (liegenschaft) {
            this.newLiegenschaft = liegenschaft;
        }
    },
    methods: {
        assign() {
            this.$emit('assign', this.newLiegenschaft);
        },
        selectionChanged(liegenschaft) {
            this.newLiegenschaft = liegenschaft;
        },
        cancel() {
            this.newLiegenschaft = this.liegenschaft;
            this.$emit('close');
        }
    }
};
</script>
