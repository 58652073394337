<template>
    <v-snackbar v-model="show" :color="color" :top="top" :timeout="timeout">
        {{ message }}
        <v-btn text @click="show = false">Schliessen</v-btn>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            message: '',
            color: 'success',
            timeout: 4000,
            top: true
        };
    },

    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'toast') {
                this.message = state.snackbarContent.message;
                this.color = state.snackbarContent.color || 'success';
                this.timeout =
                    state.snackbarContent.timeout >= 0
                        ? state.snackbarContent.timeout
                        : state.snackbarContent.color === 'error'
                        ? 0
                        : 4000;
                this.show = true;
            }
        });
    }
};
</script>
