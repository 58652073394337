// TBD. For now, we mock it.

import { instance as getApi } from 'js/lib/api.js';
import { vueI18n } from 'js/lib/i18n';

/** only used when localStorage is not available */
let noStorageCsrfToken = null;

/**
 * Stores the CSRF token in the browser's localStorage
 * for further use during the session.
 *
 * This is needed to support multiple browser windows.
 *
 * @param {String} token
 */
export function storeCsrfToken(token) {
    if (Storage) {
        localStorage.setItem('apollon.csrfToken', token);
    } else {
        noStorageCsrfToken = token;
    }
}

/**
 * Retrieves the csrf token from the browser's
 * local storage
 */
export function getCsrfToken() {
    if (Storage) {
        return localStorage.getItem('apollon.csrfToken') || '';
    } else {
        return noStorageCsrfToken || '';
    }
}

export async function login(username, password) {
    try {
        let response = await getApi().post('/auth/login', {
            email: username,
            password: password
        });
        if (response.data.benutzer) {
            let redirectUrl = '/';
            let split = window.location.href.split('?redirect=');
            if (split.length === 2) {
                redirectUrl = split[1].replace(/#.*/, '');
            }
            return { user: response.data.benutzer, redirectUrl: redirectUrl };
        } else {
            throw new Error(vueI18n.t('denied_wrong_login'));
        }
    } catch (e) {
        if (e.response && e.response.data && e.response.data.error && e.response.data.error.msg) {
            throw new Error(vueI18n.t(e.response.data.error.msg));
        }
        throw new Error(vueI18n.t('denied_wrong_login'));
    }
}

export async function resetPassword(username) {
    try {
        await getApi().post('/auth/reset', {
            email: username
        });
    } catch (e) {
        throw new Error('Hoppla, da ist was schief gelaufen...');
    }
}

export async function logout() {
    await getApi().get('/auth/logout');
}

export async function verifyUser(password) {
    try {
        let response = await getApi().post('/auth/verifyUser', {
            password: password
        });
        return response.data.verified;
    } catch (e) {
        throw new Error('Hoppla, da ist was schief gelaufen...');
    }
}

export default {
    login,
    logout,
    resetPassword,
    verifyUser
};
