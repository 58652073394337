<template>
    <v-card>
        <v-card-title>{{ $t('bestaetigung') }}</v-card-title>
        <v-card-subtitle>Bitte kontrollieren Sie Ihre Angaben nochmals:</v-card-subtitle>
        <v-card-text>
            <v-simple-table v-if="kanton && apollonConfFk && rolle">
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <th>
                                {{ $t('kanton') }}
                            </th>
                            <td>
                                <div class="d-flex">
                                    <img :src="kanton.icon" width="24px" class="mr-2" />
                                    {{ kanton.kurz }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('foerderkategorie') }}
                            </th>
                            <td>
                                {{ apollonConfFk.bezeichnung_apollon }} ({{ apollonConfFk.foerderkategorie_gruppe }})
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('role') }}
                            </th>
                            <td>
                                {{ rolleDisplay }}
                            </td>
                        </tr>
                        <tr v-if="organisation">
                            <th>
                                {{ $t('organisation') }}
                            </th>
                            <td>
                                {{ orgDisplay }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-btn color="action lighten-1 white--text" @click="$emit('back')">
                <v-icon left>fas fa-arrow-left</v-icon>
                {{ $t('back') }}
            </v-btn>
            <v-btn
                color="action lighten-1 white--text"
                :disabled="!kanton || !apollonConfFk || !rolle"
                @click="$emit('create')"
            >
                {{ $t('create_gesuch') }}
                <v-icon right>fas fa-check</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { gesuchRoles } from 'js/lib/helpers';
import { Kanton } from 'js/models/Kanton';

export default {
    props: {
        kanton: {
            type: Kanton,
            default: null
        },
        apollonConfFk: {
            type: Object,
            default: null
        },
        rolle: {
            type: String,
            default: null
        },
        organisation: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {};
    },
    computed: {
        rolleDisplay() {
            return (
                this.apollonConfFk.kontakte[this.rolle].label ||
                (gesuchRoles[this.rolle] ? gesuchRoles[this.rolle].text : '')
            );
        },
        orgDisplay() {
            if (this.organisation) {
                return this.organisation.organisation_name + ', ' + this.organisation.plz_ort;
            }
            return '';
        }
    },
    watch: {},
    methods: {
        onSelect(value) {
            this.rolle = value;
            this.$emit('input', value);
        }
    }
};
</script>
