/**
 * Store module: teams
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { Team, TeamCollection } from 'js/models/Team';
import { TeamMitgliedCollection } from 'js/models/TeamMitglied';
import { instance as api } from 'js/lib/api';

export default {
    namespaced: true,
    state: {
        teamRequests: []
    },
    mutations: {
        setTeamRequests(state, teamRequests) {
            state.teamRequests = teamRequests || [];
        }
    },
    actions: {
        /**
         * Update team data
         *
         * @param {Object} store The vuex store
         * @param {Team} team A team entity record
         */
        async storeTeam({ commit }, team) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await team.save();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         * Delete team record
         *
         * @param {Object} store The vuex store
         * @param {Team} team A team entity record
         */
        async destroyTeam({ commit }, team) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await team.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         * @param {Object} store The vuex store
         * @param {Object} options Future options like query params, paging etc.
         */
        async fetchTeams({ commit }, options = {}) {
            let list = new TeamCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        /**
         * Loads / creates a team entity.
         *
         * If id is 'new', a new one will be created, otherwise loaded from the backend.
         *
         * @param {Object} store The vuex store
         * @param {Number|String} id The id to load, or 'new'
         */
        async loadTeam({ commit }, id) {
            let team = null;
            try {
                commit('loading', true, { root: true });
                if (!id || id === 'new') {
                    team = new Team();
                } else {
                    team = new Team({ id: id });
                    await team.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return team;
        },

        /**
         * @param {Object} store The vuex store
         * @param {int} teamId The Team ID to load the members for
         */
        async loadMembers({ commit }, teamId) {
            let list = new TeamMitgliedCollection();
            try {
                commit('loading', true, { root: true });
                await list.query({ team_id: teamId });
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        /**
         * executes a New Team Member request:
         * Requests to add the given member's email to the given team:
         * The backend sends an invitation email to an already existing user.
         *
         * @param {Object} store The vuex store
         * @param {Object} options An object containing the Team record and the email (string)
         */
        async addMemberRequest({ commit }, { team, email }) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                res = await api().post('/team/addMember', {
                    team_id: team.id,
                    email: email
                });
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         * Removes a member from a team
         *
         * @param {Object} store The vuex store
         * @param {TeamMitglied} member The TeamMitglied to be removed
         */
        async removeMember({ commit }, member) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                res = await member.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         * @param {Object} store The vuex store
         */
        async loadTeamRequests({ commit, state }) {
            try {
                commit('loading', true, { root: true });
                let res = await api().get('/team/loadTeamRequests');
                commit('setTeamRequests', res.data.records);
            } finally {
                commit('loading', false, { root: true });
            }
            return state.teamRequests;
        },

        async answerRequest({ commit }, options = {}) {
            let res = null;
            try {
                commit('loading', true, { root: true });
                res = await api().post('/team/answerRequests', {
                    team_id: options.team_id,
                    benutzer_id: options.benutzer_id,
                    join: options.join
                });
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        }
    }
};
