<!-- eslint-disable vue/no-v-html -->
<template>
    <div :class="`customer-${customer}`">
        <v-snackbar
            v-model="showNotSupported"
            style="z-index: 1000;"
            :timeout="-1"
            absolute
            centered
            color="#d50000"
            elevation="24"
            top
        >
            <div style="display: flex;">
                <v-icon style="margin-right: 10px;">fas fa-exclamation-triangle</v-icon>
                <div>
                    <h3>
                        <span v-html="notSupportedMsg"></span>
                    </h3>
                </div>
            </div>
        </v-snackbar>
        <Guest v-if="!authenticated" />
        <AuthRootView v-if="authenticated" />
    </div>
</template>

<script>
import Guest from 'js/views/guest/Guest.vue';
import AuthRootView from 'js/views/auth/AuthRootView.vue';
import colors from 'vuetify/lib/util/colors';

export default {
    components: { Guest, AuthRootView },
    data: function () {
        return {
            showNotSupported: false,
            notSupportedMsg: null,
            themes: [
                {
                    name: 'default',
                    light: {
                        primary: '#1976d3'
                    }
                },
                {
                    name: 'tg',
                    light: {
                        // primary: '#41beee',
                        // info: '#41beee',
                        // accent: '#72a536',
                        // action: '#72a536',
                        // subtleHint: '#41beee',

                        primary: '#40a536',
                        info: '#40a536',
                        accent: '#40a536',
                        action: '#40a536',
                        subtleHint: '#ffec0e',

                        anchor: '#0645ad',
                        accept: colors.green.darken1,
                        decline: colors.grey.accent4,
                        stop: colors.red.accent4
                    }
                },
                {
                    name: 'sh',
                    light: {
                        // anchor: '#0645ad',
                        // primary: '#464646',
                        // info: '#FFAA00',
                        // accent: '#FFAA00',
                        // action: '#FFAA00',
                        // accept: colors.green.darken1,
                        // decline: colors.grey.accent4,
                        // subtleHint: '#FFDC00',
                        // stop: colors.red.accent4
                        anchor: '#0645ad',
                        primary: '#464646',
                        info: '#FFDC00',
                        accent: '#FFDC00',
                        action: '#FFDC00',
                        accept: colors.green.darken1,
                        decline: colors.grey.accent4,
                        subtleHint: '#FFDC00',
                        stop: colors.red.accent4
                    }
                }
            ]
        };
    },
    computed: {
        authenticated() {
            return this.$store.getters.isAuthenticated;
        },
        customer() {
            return this.$store.state.customer;
        }
    },
    watch: {
        customer(value) {
            this.setTheme(this.themes.find((t) => t.name === value) || 'default');
        }
    },
    mounted() {
        this.setTheme(this.themes.find((t) => t.name === this.$store.state.customer) || 'default');
        let browser = this.getBrowser();
        if (!this.isSupported(browser)) {
            this.notSupportedMsg = this.$t('browser_error_msg');
            if (browser.name === 'IE' || browser.name === 'MSIE') {
                this.notSupportedMsg = this.$t('ie_error_msg');
            }
            this.showNotSupported = true;
            console.log(this.notSupportedMsg);
        }
    },
    methods: {
        setTheme(theme) {
            const name = theme.name;
            const light = theme.light;
            Object.keys(light).forEach((i) => {
                this.$vuetify.theme.themes.light[i] = light[i];
            });
            this.$vuetify.theme.themes.name = name;
        },

        isSupported(browser) {
            var supported = false;
            switch (browser.name) {
                case 'Chrome':
                case 'Edge':
                    supported = browser.version > 90;
                    break;
                case 'Firefox':
                    supported = browser.version > 90;
                    break;
                case 'Opera':
                    supported = browser.version > 80;
                    break;
                case 'Safari':
                    supported = browser.version > 14;
                    break;
                case 'MSIE':
                case 'IE':
                    supported = false;
                    break;
                default:
                    supported = true;
            }
            return supported;
        },

        getBrowser() {
            var ua = navigator.userAgent,
                tem,
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return { name: 'IE', version: tem[1] || '' };
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\bOPR\/(\d+)/);
                if (tem !== null) {
                    return { name: 'Opera', version: tem[1] };
                }
            }
            if (window.navigator.userAgent.indexOf('Edge') > -1) {
                tem = ua.match(/Edge\/(\d+)/);
                if (tem !== null) {
                    return { name: 'Edge', version: tem[1] };
                }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
                M.splice(1, 1, tem[1]);
            }
            return {
                name: M[0],
                version: +M[1]
            };
        }
    }
};
</script>

<style>
html {
    overflow-y: auto !important;
}
</style>
