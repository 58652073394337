<template>
    <v-footer :app="app" class="footer" color="grey lighten-4">
        <span>
            &copy; 2020&nbsp;
            <a href="https://energie.tg.ch/" target="_blank" rel="noopener noreferrer">Amt für Energie TG</a>
            &nbsp;in Zusammenarbeit mit&nbsp;<a
                href="https://kadenpartner.ch/"
                target="_blank"
                rel="noopener noreferrer"
            >
                Kaden und Partner AG
            </a>
        </span>
        <v-spacer />
        <span class="version">V.{{ version }}</span>
        <v-icon :style="{ visibility: loading ? 'visible' : 'hidden' }">fas fa-circle-notch fa-spin</v-icon>
    </v-footer>
</template>

<script>
export default {
    props: {
        app: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    computed: {
        version() {
            return this.$store.state.appVersion;
        }
    }
};
</script>

<style lang="scss" scoped>
.footer {
    font-size: 10.5pt;
}
.version {
    padding-right: 1em;
}
</style>
