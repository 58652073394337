<template>
    <v-tooltip v-if="infotext" :left="left" :right="right" :top="top" :bottom="bottom">
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" class="ml-4" v-on="on">fas fa-info-circle</v-icon>
        </template>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-if="html" v-html="infotext"></span>
        <span v-else>{{ infotext }}</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        infotext: {
            type: String,
            default: null
        },
        html: {
            type: Boolean,
            default: false
        },
        left: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        },
        bottom: {
            type: Boolean,
            default: false
        },
        top: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    }
};
</script>
