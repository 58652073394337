<template>
    <v-card>
        <v-card-title>{{ $t('choose_role') }}</v-card-title>
        <v-card-subtitle>Sie sind:</v-card-subtitle>
        <v-card-text>
            <v-radio-group :value="value" @change="onSelect">
                <v-radio v-for="r in getRollen()" :key="r.ident" :value="r.ident">
                    <template v-slot:label>
                        <span style="min-width: 150px;">{{ r.text }}</span>
                        <field-info-icon v-if="r.help" html :infotext="r.help" bottom />
                    </template>
                </v-radio>
            </v-radio-group>
        </v-card-text>
        <v-card-actions>
            <v-btn color="action lighten-1 white--text" @click="$emit('back')">
                <v-icon left>fas fa-arrow-left</v-icon>
                {{ $t('back') }}
            </v-btn>
            <v-btn color="action lighten-1 white--text" :disabled="!value" @click="$emit('next')">
                {{ $t('next') }}
                <v-icon right>fas fa-arrow-right</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import FieldInfoIcon from 'js/components/field-info-icon.vue';
import { SICHTBARKEIT } from 'js/models/apollon_conf_fk/KontaktConfig';

export default {
    components: {
        'field-info-icon': FieldInfoIcon
    },
    props: {
        apollonConfFk: {
            type: Object,
            default: null
        },
        value: {
            type: String,
            default: null
        }
    },
    data: function () {
        return {};
    },
    methods: {
        onSelect(value) {
            this.$emit('input', value);
        },

        getRollen() {
            let res = {};
            if (!this.apollonConfFk) {
                return res;
            }
            if (
                this.apollonConfFk.kontakte.eigentuemer.sichtbarkeit === SICHTBARKEIT.IMMER ||
                this.apollonConfFk.kontakte.eigentuemer.sichtbarkeit === SICHTBARKEIT.EINREICHUNG
            ) {
                res.eigentuemer = {
                    ident: 'eigentuemer',
                    text: this.apollonConfFk.kontakte.eigentuemer.label || this.$t('eigentuemer'),
                    help: this.apollonConfFk.kontakte.eigentuemer.hilfetext_kurz
                };
            }
            if (
                this.apollonConfFk.kontakte.unternehmer.sichtbarkeit === SICHTBARKEIT.IMMER ||
                this.apollonConfFk.kontakte.unternehmer.sichtbarkeit === SICHTBARKEIT.EINREICHUNG
            ) {
                res.unternehmer = {
                    ident: 'unternehmer',
                    text: this.apollonConfFk.kontakte.unternehmer.label || this.$t('unternehmer'),
                    help: this.apollonConfFk.kontakte.unternehmer.hilfetext_kurz
                };
            }
            if (
                this.apollonConfFk.kontakte.bevollmaechtigter.sichtbarkeit === SICHTBARKEIT.IMMER ||
                this.apollonConfFk.kontakte.bevollmaechtigter.sichtbarkeit === SICHTBARKEIT.EINREICHUNG
            ) {
                res.bevollmaechtigter = {
                    ident: 'bevollmaechtigter',
                    text: this.apollonConfFk.kontakte.bevollmaechtigter.label || this.$t('bevollmaechtigter'),
                    help: this.apollonConfFk.kontakte.bevollmaechtigter.hilfetext_kurz
                };
            }
            if (
                this.apollonConfFk.kontakte.kontakt.sichtbarkeit === SICHTBARKEIT.IMMER ||
                this.apollonConfFk.kontakte.kontakt.sichtbarkeit === SICHTBARKEIT.EINREICHUNG
            ) {
                res.kontakt = {
                    ident: 'kontakt',
                    text: this.apollonConfFk.kontakte.kontakt.label || this.$t('kontakt'),
                    help: this.apollonConfFk.kontakte.kontakt.hilfetext_kurz
                };
            }
            return res;
        }
    }
};
</script>
