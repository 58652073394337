/**
 * Routes: /news/ and below
 *
 * Will me merged into the router's 'route' array
 */
import { getStore } from 'js/store';
import { awaitAsyncFunction } from 'js/lib/helpers';

const newsList = () => import(/* webpackChunkName: "admin" */ 'js/views/auth/admin/NewsList.vue');
const newsForm = () => import(/* webpackChunkName: "admin" */ 'js/views/auth/admin/NewsForm.vue');

const routes = [
    {
        path: '/news',
        name: 'news',
        component: () => awaitAsyncFunction(newsList, getStore()),
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            title: 'News'
        }
    },
    {
        path: '/news/:id',
        name: 'news_edit',
        component: () => awaitAsyncFunction(newsForm, getStore()),
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            title: (to) => `News Eintrag: ${to.params.id}`
        }
    }
];

export default routes;
