/**
 * Represents the zeus.apollon_fk_conf entity - the Foerderkategorie config
 * structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import { Config } from './apollon_conf_fk/Config';
import dayjs from 'dayjs';

export class ApollonConfFk extends BaseModel {
    defaults() {
        return {
            id: null,
            kanton_id: null,
            foerderkategorie_id: null,
            fk_gruppe_id: null,
            reihenfolge: null,
            bezeichnung_apollon: null,
            einreichung_ab: null,
            einreichung_bis: null,
            abschluss_ab: null,
            abschluss_bis: null,
            hilfetext: null,
            foerderbedingungen_html: null,
            config: null,

            kanton_kuerzel: null,
            foerderkategorie: null,
            foerderkategorie_aktiv: null,
            foerderkategorie_gruppe: null,
            foerderkategorie_gruppe_hilfetext: null,
            gesuchsvorlage_id: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            einreichung_ab: (val) => (val ? dayjs(val) : null),
            einreichung_bis: (val) => (val ? dayjs(val) : null),
            abschluss_ab: (val) => (val ? dayjs(val) : null),
            abschluss_bis: (val) => (val ? dayjs(val) : null),

            config: (val) => new Config(val)
        };
    }

    entityName() {
        return 'apollonConfFk';
    }
}
