<template>
    <div v-if="news">
        <div v-for="n in news.models" :key="n.id + '_static'">
            <v-card-text v-if="n.statische_anzeige">
                <h2>{{ n.titel }}</h2>
                <div v-html="n.inhalt"></div>
            </v-card-text>
        </div>
        <div v-for="n in news.models" :key="n.id">
            <v-card-text v-if="!n.statische_anzeige">
                <h2>{{ n.titel }}</h2>
                <h3>{{ formatDate(n.anzeige_von) }}</h3>
                <div v-html="n.inhalt"></div>
            </v-card-text>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
export default {
    data: function () {
        return {
            news: null,
            newsLoading: false
        };
    },
    mounted() {
        this.getNews();
    },

    methods: {
        ...mapActions('news', ['fetchNewsUnauth']),
        async getNews() {
            this.newsLoading = true;
            try {
                let data = await this.fetchNewsUnauth({ kanton: this.$store.state.customer });
                this.news = data;
            } catch (res) {
                // so what...
            } finally {
                this.newsLoading = false;
            }
        },
        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY');
        }
    }
};
</script>
<style lang="scss" scoped>
.news {
    h2 {
        font-size: 1.2em;
        color: #333;
    }
    h3 {
        font-size: 1em;
        color: #888;
    }
}
</style>
