<template>
    <v-skeleton-loader v-if="!gesuch" type="list-item-avatar, list-item-three-line@3, actions"></v-skeleton-loader>
    <v-container v-else fluid>
        <v-alert v-if="readOnly" type="warning" border="left" colored-border elevation="2">
            <span v-html="$t('document_edit_msg')"></span>
        </v-alert>
        <v-card v-if="gesuchBeilagenConf.length">
            <v-card-title>{{ config.beilagen.label || 'Hochzuladende Dokumente' }}</v-card-title>
            <v-card-subtitle
                v-if="config.beilagen && config.beilagen.infotext"
                v-html="config.beilagen.infotext.replace('\n', '<br />')"
            ></v-card-subtitle>
            <v-list dense class="pt-0">
                <template v-for="(item, i) in gesuchBeilagenConf">
                    <template v-if="item.data">
                        <!-- already uploaded: -->
                        <v-list-item :key="i" dense @click.stop="onAssignedBeilageClick(item.data)">
                            <v-list-item-avatar>
                                <v-icon>fas fa-paperclip</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="accept--text">{{ item.conf.name }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item.data.originalFilename }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="!readOnly && item.editable">
                                <v-btn icon @click.stop="onRemoveBtn(item.data)">
                                    <v-icon color="stop white--text" small>fas fa-trash</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-action v-else>
                                <v-icon color="action white--text" small>fas fa-download</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                    <template v-else>
                        <!-- not yet uploaded: -->
                        <v-list-item
                            :key="i"
                            dense
                            :disabled="readOnly"
                            @click.stop="onUnassignedBeilageClick(item.conf)"
                        >
                            <v-list-item-avatar>
                                <v-icon>fas fa-paperclip</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.conf.name }}
                                    <span v-if="item.conf.required" class="require-label required">{{
                                        $t('required')
                                    }}</span>
                                    <span v-else class="require-label optional">{{ $t('optional') }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item.conf.beschreibung }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action v-if="!readOnly">
                                <v-icon color="action white--text" small>fas fa-upload</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </template>
            </v-list>
        </v-card>

        <v-card v-if="zeusDokumente.length" class="mt-5">
            <v-card-title>{{ $t('docs_energiefachstelle') }}</v-card-title>
            <v-list dense class="pt-0">
                <template v-for="item in zeusDokumente">
                    <v-list-item :key="item.id" dense @click.stop="onZeusDokumentClick(item)">
                        <v-list-item-avatar>
                            <v-icon>fas fa-file-alt</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-html="item.beschreibung"></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.bearbeitet_am.isValid()">
                                {{ item.bearbeitet_am.format('DD.MM.YYYY') }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon color="action white--text" small>fas fa-download</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>

        <v-card v-if="divDokumenteConf.length" class="mt-5">
            <v-card-title>{{ config.div_dokumente.label || 'Dokument-Download' }}</v-card-title>
            <v-card-subtitle
                v-if="config.div_dokumente.infotext"
                v-html="config.div_dokumente.infotext.replace('\n', '<br />')"
            ></v-card-subtitle>
            <v-list dense class="pt-0">
                <template v-for="(item, i) in divDokumenteConf">
                    <v-list-item :key="i" dense @click.stop="onDivDokumentClick(item)">
                        <v-list-item-avatar>
                            <v-icon>fas fa-file-alt</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ item.beschreibung }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon color="action white--text" small>fas fa-download</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>

        <v-card-actions>
            <v-spacer />
            <v-btn class="mt-2" color="action" :loading="loading" @click.stop="$emit('next')">
                <v-icon left>fas fa-arrow-right</v-icon>
                {{ $t('next_step') }}
            </v-btn>
        </v-card-actions>
        <UploadDialog
            v-if="showUploadDlg"
            v-model="showUploadDlg"
            :max-width="600"
            :text="uploadDlgText"
            :title="uploadDlgTitle"
            :target="uploadDlgTarget"
            :query="uploadDlgQuery"
            @uploadsuccess="onFileUploadSuccess"
            @uploadfailed="onFileUploadFailed"
        />
        <ConfirmDlg
            v-model="showDelDlg"
            :title="$t('bestaetigung')"
            :text="$t('really_delete_attachment')"
            :loading="progress"
            :close-on-yes="false"
            @yes="onDelAttachmentConfirm"
        />
    </v-container>
</template>

<script>
import { Gesuch, PHASE } from 'js/models/Gesuch';
import UploadDialog from 'js/components/upload-dialog.vue';
import ConfirmDlg from 'js/components/confirm-dlg.vue';
import { buildUrl, instance as api } from 'js/lib/api';
import { errToStr } from 'js/lib/helpers.js';

export default {
    components: {
        UploadDialog,
        ConfirmDlg
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        gesuch: {
            type: Gesuch,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            showUploadDlg: false,
            uploadDlgText: '',
            uploadDlgTitle: '',
            uploadDlgTarget: null,
            uploadDlgQuery: null,
            showDelDlg: false,
            attachmentToDel: null,
            progress: false
        };
    },
    computed: {
        config() {
            return this.gesuch ? this.gesuch.config.config.dokumente : {};
        },
        // config for the docs the user must upload:
        /**
         * We create the following data structure to display the
         * configured (and later attached) beilagen:
         *
         * [
         *     // each conf.config.beilagen entry creates the strcuture:
         *     {
         *         conf: DokumentBeilage config,
         *         data: Beilage config, if already present
         *     },
         *     ...
         * ]
         */
        gesuchBeilagenConf() {
            let beilagen = [];
            if (this.config && this.config.beilagen && this.config.beilagen.docs) {
                this.config.beilagen.docs.forEach((conf) => {
                    if (this.gesuch.phase === PHASE.ABSCHLUSS) {
                        let data = this.getBeilageData(conf.ident);
                        let editable = !conf.sichtbarkeit || conf.sichtbarkeit === this.gesuch.phase;
                        if (data || editable) {
                            beilagen.push({
                                conf: conf,
                                data: data,
                                editable: editable
                            });
                        }
                    } else {
                        if (!conf.sichtbarkeit || conf.sichtbarkeit === this.gesuch.phase) {
                            beilagen.push({
                                conf: conf,
                                data: this.getBeilageData(conf.ident),
                                editable: true
                            });
                        }
                    }
                });
            }
            // Sort by order:
            beilagen.sort((b1, b2) => Number(b1.conf.order) - Number(b2.conf.order));
            return beilagen;
        },

        gesuchBeilageData() {
            return this.gesuch && this.gesuch.data.beilagen ? this.gesuch.data.beilagen || [] : [];
        },

        zeusDokumente() {
            return (this.gesuch && this.gesuch.zeus_dokumente) || [];
        },

        divDokumenteConf() {
            let docs = [];
            if (this.config && this.config.div_dokumente && this.config.div_dokumente.docs) {
                this.config.div_dokumente.docs.forEach((conf) => {
                    if (!conf.sichtbarkeit || conf.sichtbarkeit === this.gesuch.phase) {
                        docs.push(conf);
                    }
                });
            }
            // Sort by order:
            docs.sort((b1, b2) => Number(b1.order) - Number(b2.order));
            return docs;
        },

        phaseEinreichung() {
            if (this.gesuch) {
                return this.gesuch.phase === PHASE.EINREICHUNG;
            }
            return false;
        }
    },
    methods: {
        /**
         * Checks if the Gesuch data already contains
         * an uploaded document for the given beilage ident:
         *
         * @return Beilage data config if the Gesuch already has the Beilage identified by the given ident,
         *                 null if this is not the case.
         */
        getBeilageData(beilageIdent) {
            let beilagen = this.gesuchBeilageData;

            for (let i = 0; i < beilagen.length; i++) {
                let data = beilagen[i];
                if (beilageIdent === data.ident) {
                    return data;
                }
            }
            return null;
        },

        findBeilagenConfByIdent(ident) {
            for (let i = 0; i < this.gesuchBeilagenConf.length; i++) {
                if (this.gesuchBeilagenConf[i].conf.ident === ident) {
                    return this.gesuchBeilagenConf[i];
                }
            }
            return null;
        },

        onUnassignedBeilageClick(beilageConf) {
            this.uploadDlgTitle = beilageConf.name;
            this.uploadDlgText = this.$t('choose_upload_doc');
            this.uploadDlgTarget = buildUrl('/gesuch/uploadBeilage');
            this.uploadDlgQuery = {
                gesuch_id: this.gesuch.id,
                beilage_ident: beilageConf.ident
            };
            this.showUploadDlg = true;
        },

        onAssignedBeilageClick(beilageData) {
            let url = buildUrl('/gesuch/downloadBeilage', {
                ident: beilageData.ident,
                gesuch_id: this.gesuch.id
            });
            window.open(url, 'apollon-dl');
        },

        onFileUploadSuccess(beilageInfo) {
            this.showUploadDlg = false;
            beilageInfo = beilageInfo || {};
            let beilagenConf = this.findBeilagenConfByIdent(beilageInfo.ident);
            if (!beilagenConf) {
                return;
            }
            // Store the new Beilage info on gesuch.data.beilagen[],
            // make sure only one with the same ident exists:
            if (this.gesuch.data.beilagen) {
                let beilagen = this.gesuch.data.beilagen.filter((conf) => conf.ident !== beilageInfo.ident);
                beilagen.push(beilageInfo);
                this.gesuch.data.beilagen = beilagen;
            }

            // and also update the beilagenConf for the UI:
            beilagenConf.data = beilageInfo;

            // Finally, inform about the change:
            this.$emit('gesuchdatachanged', this.gesuch);
        },

        onFileUploadFailed() {
            this.showUploadDlg = false;
        },

        onRemoveBtn(beilageData) {
            this.attachmentToDel = beilageData;
            this.showDelDlg = true;
        },

        async onDelAttachmentConfirm() {
            if (this.attachmentToDel) {
                this.progress = true;
                try {
                    await api().get('/gesuch/deleteBeilage', {
                        params: {
                            gesuch_id: this.gesuch.id,
                            ident: this.attachmentToDel.ident
                        }
                    });
                    this.removeBeilageEntry(this.attachmentToDel);
                    // Finally, inform about the change:
                    this.$emit('gesuchdatachanged', this.gesuch);
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                } finally {
                    this.progress = false;
                    this.showDelDlg = false;
                    this.attachmentToDel = null;
                }
            }
        },

        removeBeilageEntry(beilageInfo) {
            this.gesuch.data.beilagen = this.gesuch.data.beilagen.filter((beilage) => beilage !== beilageInfo);
        },

        onDivDokumentClick(docInfo) {
            let url = buildUrl('/gesuch/downloadDivDokumentAttachment', {
                ident: docInfo.ident,
                gesuch_id: this.gesuch.id
            });
            window.open(url, 'apollon-dl');
        },

        onZeusDokumentClick(zeusDokument) {
            let url = buildUrl('/gesuch/downloadZeusDokument', {
                dokument_id: zeusDokument.id
            });
            window.open(url, 'apollon-dl');
        }
    }
};
</script>

<style lang="scss" scoped>
.require-label {
    font-size: 0.75em;
    &.required {
        color: red;
    }
    &.optional {
        color: blue;
    }
}
</style>
