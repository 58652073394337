<template>
    <v-container fluid>
        <v-alert v-if="projekt.infotext" type="info" :icon="false" border="left" colored-border elevation="2">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="projekt.infotext.replace('\n', '<br />')"> </span>
        </v-alert>
        <v-form no-gutters>
            <template v-for="gruppe in projekt.feldgruppen">
                <v-card
                    v-if="isComponentVisible(gruppe)"
                    :key="gruppe.label"
                    max-width="600px"
                    class="mb-2"
                    color="grey lighten-4"
                >
                    <v-card-title>
                        {{ gruppe.label }}
                        <v-tooltip v-if="gruppe.hilfetext" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="primary" dark class="ml-4" v-bind="attrs" v-on="on">
                                    fas fa-info-circle
                                </v-icon>
                            </template>
                            <span>{{ gruppe.hilfetext }}</span>
                        </v-tooltip>
                    </v-card-title>
                    <template v-for="feld in gruppe.felder">
                        <v-card-text v-if="isComponentVisible(feld)" :key="feld.feld_name">
                            <ProjektFeld
                                v-if="isComponentVisible(feld)"
                                v-model="gesuch.data.projektdaten[feld.feld_name]"
                                :read-only="readOnly"
                                :projektfeld="feld"
                                :phase="gesuch.phase"
                            ></ProjektFeld>
                        </v-card-text>
                    </template>
                </v-card>
            </template>
        </v-form>
        <v-card-actions>
            <v-spacer />
            <v-btn class="mt-2" color="action" :loading="loading" @click="$emit('next')">
                <v-icon left>fas fa-arrow-right</v-icon>
                {{ $t('next_step') }}
            </v-btn>
        </v-card-actions>
    </v-container>
</template>

<script>
import { formRules } from 'js/lib/helpers';
import ProjektFeld from 'js/components/projekt-feld-field.vue';
import { PHASE } from 'js/models/Gesuch';
import dayjs from 'dayjs';
export default {
    components: {
        ProjektFeld
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        gesuch: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            rules: formRules,
            projekt: null
        };
    },
    created() {
        this.projekt = this.gesuch.config.config.projekt;
    },
    methods: {
        isComponentVisible(comp) {
            let visible = true;
            if (this.gesuch.phase === PHASE.EINREICHUNG) {
                if (comp.einreichung_from) {
                    visible &= dayjs(comp.einreichung_from).isBefore(dayjs());
                }
                if (comp.einreichung_to) {
                    visible &= dayjs().isBefore(dayjs(comp.einreichung_to).add(1, 'day'));
                }
                visible &= comp.einreichung;
                return visible;
            } else if (this.gesuch.phase === PHASE.ABSCHLUSS) {
                if (comp.abschluss_from) {
                    visible &= dayjs(comp.abschluss_from).isBefore(dayjs());
                }
                if (comp.abschluss_to) {
                    visible &= dayjs().isBefore(dayjs(comp.abschluss_to).add(1, 'day'));
                }
                visible &= comp.abschluss;
                return visible;
            } else if (this.gesuch.phase === PHASE.FERTIG) {
                return comp.abschluss || comp.einreichung;
            }
            return false;
        }
    }
};
</script>
