<template>
    <div>
        <div class="tg-horizont">
            <v-container>
                <v-row justify="center" align="end">
                    <v-col cols="8">
                        <h1>{{ $t('welcome') }}</h1>
                    </v-col>
                    <v-col cols="4">
                        <v-img contain :src="TgLogo" height="60" />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="welcome-container">
            <v-row justify="center">
                <v-col cols="12">
                    <v-card class="trans-bg mx-auto" max-width="1180px">
                        <v-img :src="WelcomeTitleTg" />
                        <v-card-actions>
                            <v-btn color="action white--text" :to="{ name: 'login' }">{{ $t('login') }}</v-btn>
                            <router-link class="ml-3" :to="{ name: 'register' }">
                                noch kein Login? Registrieren Sie sich hier.
                            </router-link>
                        </v-card-actions>

                        <div class="d-flex flex-no-wrap justify-space-between">
                            <v-card-text>
                                <p>
                                    Wenn Sie ein Gesuch für einen Beitrag aus dem Energieförderprogramm stellen wollen,
                                    sind Sie hier genau richtig. Seit 2021 können Fördergesuche nur noch elektronisch
                                    über dieses Energieförderportal eingereicht werden.
                                    <strong>Eine Einreichung in Papierform ist nicht mehr möglich.</strong>
                                </p>
                                <p>
                                    Dank dem Energieförderportal haben Sie jederzeit Einblick in den aktuellen Stand
                                    Ihrer Gesuche. Bei der Gesuchseingabe stehen Ihnen Hilfestellungen und Erläuterungen
                                    zur Verfügung. Das Fördergesuch kann von der Eigentümerschaft, einem Unternehmen
                                    oder von einem Bevollmächtigten erstellt werden. Definitiv einreichen kann das
                                    Gesuch jedoch nur die Eigentümerschaft oder der Bevollmächtigte. Die Einreichung
                                    muss mit einem persönlichen Passwort bestätigt werden. Die Daten befinden sich auf
                                    einem sicheren Server der kantonalen Verwaltung des Kantons Thurgau und werden nur
                                    im Rahmen des Energieförderprogramms verwendet.
                                </p>

                                <p class="mb-0 mt-2">
                                    <strong>Bestehende Fördergesuche vor 2021:</strong>
                                </p>
                                <p>
                                    Die Ausführungsbestätigungen von Gesuchen, die vor 2021 eingereicht worden sind,
                                    können weiterhin in Papierform eingereicht werden. Eine Einreichung über das
                                    Energieförderportal ist jedoch ebenfalls möglich. Dabei ist das Vorgehen wir folgt:
                                    Schreiben Sie bitte eine E-Mail an
                                    <kp-link mailto href="energie@tg.ch?Subject=Projektabschluss%20F%C3%B6rdergesuch">
                                        energie@tg.ch
                                    </kp-link>
                                    mit dem Betreff "Projektabschluss Fördergesuch" gefolgt von der Gesuchsnummer. Geben
                                    Sie im Text Ihre vollständige Adresse an. Wir werden anschliessend das Gesuch für
                                    Sie freischalten, so dass Sie den Projektabschluss einreichen können.
                                </p>
                            </v-card-text>
                            <v-avatar class="ma-3" size="125" tile> </v-avatar>
                        </div>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="news">
                <v-col cols="12">
                    <v-card class="trans-bg mx-auto" max-width="1180px">
                        <v-card-title>
                            <v-icon left color="orange">fas fa-exclamation-triangle</v-icon> {{ $t('actual_news') }}
                        </v-card-title>
                        <v-card-text>
                            <news />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import link from 'js/components/link.vue';
import news from 'js/components/news.vue';
import { WelcomeTg, WelcomeTitleTg, TgLogo } from 'js/lib/images.js';

export default {
    components: {
        'kp-link': link,
        news: news
    },
    data: function () {
        return {
            WelcomeTg,
            WelcomeTitleTg,
            TgLogo
        };
    }
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 16pt;
}
.img-rights {
    font-size: 10pt;
    color: #333;
}
.news {
    h2 {
        font-size: 1.2em;
        color: #333;
    }
}
.tg-horizont {
    background-color: white;
    padding-bottom: 70px !important;
    box-shadow: inset 0 -30px 0 #4ab1eb, inset 0px -37px 0px #00a4e8, inset 0px -44px 0px #72a536,
        inset 0px -56px 0px #adc427, inset 0px -63px 0px #ffec00;
}
</style>
