<template>
    <div>
        <v-card-actions>
            <SearchField class="white--text" @executeSearch="executeSearch"></SearchField>
        </v-card-actions>
        <v-data-table
            class="row-pointer"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
            :headers="headers"
            :items="gesuche.models"
            :items-per-page="10"
            :options.sync="options"
            :server-items-length="total"
            item-key="id"
            :loading="gesuchLoading"
            :no-data-text="$t('no_records')"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, -1]
            }"
            @click:row="rowclicked"
        >
            <template v-slot:top>
                <div class="d-flex align-end flex-column">
                    <v-menu open-on-hover left :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="primary" v-bind="attrs" v-on="on">
                                <v-icon class="small-icon">fas fa-cog</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-subheader>Spalten anzeigen</v-subheader>
                            <v-list-item-group v-model="visibleHeaders" multiple>
                                <v-list-item v-for="header in headerList" :key="header.id">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ header.text }}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:[`item.kanton`]="{ item }">
                <div>
                    <img v-if="item.kanton === 'TG'" :src="tgImg" width="36px" class="ma-1" />
                    <img v-if="item.kanton === 'SH'" :src="shImg" width="36px" class="ma-1" />
                </div>
            </template>
            <template v-slot:[`item.gueltigkeit_zusage_in_tagen`]="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="item.isClosed()" class="pt-1"> fas fa-minus </v-icon>
                        <v-icon v-else-if="item.gueltigkeit_zusage_in_tagen === null" class="pt-1" v-on="on">
                            fas fa-minus
                        </v-icon>
                        <v-icon v-else-if="item.gueltigkeit_zusage_in_tagen < 0" color="red" class="pt-1" v-on="on">
                            fas fa-exclamation-circle
                        </v-icon>
                        <v-icon
                            v-else-if="
                                item.gueltigkeit_zusage_in_tagen <= $store.state.config.gueltigkeit_zusage_deadline
                            "
                            color="orange"
                            class="pt-1"
                            v-on="on"
                        >
                            fas fa-exclamation-circle
                        </v-icon>
                        <v-icon
                            v-else-if="
                                item.gueltigkeit_zusage_in_tagen > $store.state.config.gueltigkeit_zusage_deadline
                            "
                            color="green"
                            class="pt-1"
                            v-on="on"
                        >
                            fas fa-circle
                        </v-icon>
                    </template>
                    <span v-if="item.gueltigkeit_zusage_in_tagen === null">{{ $t('no_zusage') }}</span>
                    <span v-else-if="item.gueltigkeit_zusage_in_tagen > 0">{{
                        $t('zusage_deadline_in_days', {
                            days: item.gueltigkeit_zusage_in_tagen,
                            date: formatDate(item.gueltigkeit_zusage)
                        })
                    }}</span>
                    <span v-else-if="item.gueltigkeit_zusage_in_tagen < 0">{{
                        $t('zusage_deadline_expired_since', {
                            days: Math.abs(item.gueltigkeit_zusage_in_tagen),
                            date: formatDate(item.gueltigkeit_zusage)
                        })
                    }}</span>
                    <span v-else-if="item.gueltigkeit_zusage_in_tagen === 0">{{
                        $t('zusage_deadline_today', {
                            date: formatDate(item.gueltigkeit_zusage)
                        })
                    }}</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.nummer`]="{ item }">
                <div>
                    <div class="gesuch-title">
                        <v-icon v-if="item.isLocked(user)" color="red" x-small> fas fa-lock</v-icon>
                        {{ item.nummer }}
                    </div>
                    <div v-if="!visibleHeaders.includes(0)" class="gesuch-status">
                        <StatusLabel :status="item.status" />
                    </div>
                    <div class="gesuch-subtitle">
                        {{ item.foerderkategorie_apollon }}
                    </div>
                    <div
                        v-if="item.anzahl_liegenschaften > 0 && !visibleHeaders.includes(4)"
                        class="gesuch-anz-liegenschaften"
                    >
                        <v-icon x-small>far fa-building</v-icon> {{ item.anzahl_liegenschaften }}
                    </div>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div>
                    <div class="gesuch-title">
                        <span class="gesuch-status"><StatusLabel :status="item.status" /></span>
                    </div>
                </div>
            </template>
            <template v-slot:[`item.eigentuemer_display_name`]="{ item }">
                <div>
                    <div>
                        <strong>{{ item.eigentuemer_display_name }}</strong>
                    </div>
                    <div>{{ item.eigentuemer_adresse }}</div>
                    <div>
                        {{
                            item.eigentuemer_laendercode && item.eigentuemer_laendercode !== 'CH'
                                ? item.eigentuemer_laendercode + '-'
                                : ''
                        }}{{ item.eigentuemer_plz }} {{ item.eigentuemer_ort }}
                    </div>
                    <div>
                        <kp-link mailto :href="item.eigentuemer_email" />
                    </div>
                </div>
            </template>
            <template v-slot:[`item.unternehmer_display_name`]="{ item }">
                <div>
                    <div>
                        <strong>{{ item.unternehmer_display_name }}</strong>
                    </div>
                    <div>{{ item.unternehmer_adresse }}</div>
                    <div>
                        {{
                            item.unternehmer_laendercode && item.unternehmer_laendercode !== 'CH'
                                ? item.unternehmer_laendercode + '-'
                                : ''
                        }}{{ item.unternehmer_plz }} {{ item.unternehmer_ort }}
                    </div>
                    <div>
                        <kp-link mailto :href="item.unternehmer_email" />
                    </div>
                </div>
            </template>
            <template v-slot:[`item.bevollmaechtigter_display_name`]="{ item }">
                <div>
                    <div>
                        <strong>{{ item.bevollmaechtigter_display_name }}</strong>
                    </div>
                    <div>{{ item.bevollmaechtigter_adresse }}</div>
                    <div>
                        {{
                            item.bevollmaechtigter_laendercode && item.bevollmaechtigter_laendercode !== 'CH'
                                ? item.bevollmaechtigter_laendercode + '-'
                                : ''
                        }}{{ item.bevollmaechtigter_plz }} {{ item.bevollmaechtigter_ort }}
                    </div>
                    <div>
                        <kp-link mailto :href="item.bevollmaechtigter_email" />
                    </div>
                </div>
            </template>
            <template v-slot:[`item.anzahl_liegenschaften`]="{ item }">
                <div>
                    <div>{{ item.data.liegenschaften[0] ? item.data.liegenschaften[0].liegenschaft.adresse : '' }}</div>
                    <div>
                        {{ item.data.liegenschaften[0] ? item.data.liegenschaften[0].liegenschaft.ort_plz : '' }}
                        {{ item.data.liegenschaften[0] ? item.data.liegenschaften[0].liegenschaft.ort : '' }}
                    </div>
                    <div v-if="item.data.liegenschaften.length == 2">(+ {{ $t('one_other_object') }})</div>
                    <div v-if="item.data.liegenschaften.length > 2">
                        (+{{ item.data.liegenschaften.length - 1 + ' ' + $t('other_objects') }})
                    </div>
                </div>
            </template>

            <template v-slot:[`item.created`]="{ item }">
                <div>
                    <div v-if="item.zahlung_datum.isValid()">
                        {{ $t('payment') }}: {{ item.zahlung_datum.format('L') }}
                    </div>
                    <div v-if="item.projektabschluss_erstmals_eingereicht_datum.isValid()">
                        {{ $t('project_done') }}: {{ item.projektabschluss_erstmals_eingereicht_datum.format('L') }}
                    </div>
                    <div v-if="item.foerderzusage_datum.isValid()">
                        {{ $t('foerderzusage') }}: {{ item.foerderzusage_datum.format('L') }}
                    </div>
                    <div v-if="item.gesuch_erstmals_eingereicht_datum.isValid()">
                        {{ $t('submitted') }}: {{ item.gesuch_erstmals_eingereicht_datum.format('L') }}
                    </div>
                    <div>{{ $t('created') }}: {{ item.created.format('L') }}</div>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <span>
                    <v-btn
                        class="mr-1"
                        x-small
                        fab
                        rounded
                        color="accent"
                        @click.stop="$router.push({ name: 'gesuch', params: { nr: item.nummer } })"
                    >
                        <v-icon x-small>fas fa-pen</v-icon>
                    </v-btn>

                    <v-tooltip v-if="!item.isClosed()" left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="item && item.gueltigkeit_zusage_in_tagen"
                                class="mr-1"
                                x-small
                                fab
                                rounded
                                color="orange"
                                v-on="on"
                                @click.stop="extendCommitment(item)"
                            >
                                <v-icon x-small>far fa-calendar-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('extend_commitment') }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="!item.isClosed()" left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="allowDestroy(item)"
                                class="mr-1"
                                x-small
                                fab
                                rounded
                                color="error"
                                v-on="on"
                                @click.stop="destroyItem(item)"
                            >
                                <v-icon x-small>fas fa-trash</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('delete_gesuch') }}</span>
                    </v-tooltip>
                </span>
            </template>
        </v-data-table>
        <ConfirmDlg
            v-model="confirmDelDlg"
            :title="toDelete && toDelete.status === STATUS.NEW ? $t('bestaetigung') : $t('delete_gesuch_confirm_title')"
            :text="
                toDelete && toDelete.status === STATUS.NEW ? $t('really_delete_rec') : $t('delete_gesuch_confirm_txt')
            "
            @yes="confirmDestroy"
        />
        <ConfirmDlg
            v-model="extendCommitmentDlg"
            max-width="500"
            :title="$t('extend_commitment')"
            :text="
                $t('really_extend_commitment', {
                    date: formatDate(extedCommitmentItem.gueltigkeit_zusage),
                    days: extedCommitmentItem.gueltigkeit_zusage_in_tagen,
                    daytext:
                        Math.abs(extedCommitmentItem.gueltigkeit_zusage_in_tagen) === 1
                            ? this.$t('day')
                            : this.$t('days')
                })
            "
            @yes="confirmExtendCommitment"
        />

        <ConfirmDlg
            v-model="extendRequestedDlg"
            max-width="500"
            :title="$t('extend_requested')"
            :text="$t('extend_msg')"
            :yes-text="$t('close')"
            :show-no-button="false"
        />
    </div>
</template>

<script>
import SearchField from 'js/components/SearchField.vue';
import { saveToLocalStorage, getFromLocalStorage } from 'js/lib/helpers.js';
import { TgWappenSvg, ShWappenSvg } from 'js/lib/images';
import link from 'js/components/link.vue';
import { STATUS } from 'js/models/Gesuch';
import StatusLabel from 'js/components/gesuch-status-label.vue';
import { mapState, mapActions } from 'vuex';
import ConfirmDlg from 'js/components/confirm-dlg.vue';
import { errToStr } from 'js/lib/helpers.js';
import dayjs from 'dayjs';

export default {
    components: {
        SearchField,
        'kp-link': link,
        StatusLabel,
        ConfirmDlg
    },
    props: {
        hideDefaultHeader: {
            type: Boolean,
            default: false
        },
        hideDefaultFooter: {
            type: Boolean,
            default: false
        },
        paging: {
            type: Boolean,
            default: true
        },
        status: {
            type: [String, Array],
            default: 'all'
        }
    },
    data: function () {
        return {
            tgImg: TgWappenSvg,
            shImg: ShWappenSvg,
            searchString: '',
            gesuchLoading: true,
            confirmDelDlg: false,
            extendCommitmentDlg: false,
            extendRequestedDlg: false,
            extedCommitmentItem: {},
            toDelete: null,
            options: {
                page: this.paging === true ? 1 : null,
                itemsPerPage: this.paging === true ? 50 : null,
                sortBy: ['created'],
                sortDesc: [true],
                multiSort: false,
                mustSort: true
            },
            headers: [
                { value: 'kanton', width: '80px', align: 'center valign-top' },
                {
                    text: 'Gültigkeit Zusage',
                    value: 'gueltigkeit_zusage_in_tagen',
                    width: '70px',
                    align: 'center valign-top',
                    sortable: true
                },
                { text: 'Info', value: 'nummer', align: 'start valign-top', width: '300px' },
                { text: 'Eigentümer', value: 'eigentuemer_display_name', align: 'start valign-top', sortable: true },
                { text: 'Unternehmer', value: 'unternehmer_display_name', align: 'start valign-top', sortable: true },
                { text: 'Daten', value: 'created', align: 'start valign-top', sortable: true },
                { value: 'actions', align: 'left', width: '120px', sortable: false }
            ],
            gesuche: [],
            visibleHeaders: [],
            total: 0,
            headerList: [
                { id: 0, value: 'status', text: 'Status' },
                { id: 1, value: 'eigentuemer_display_name', text: 'Eigentümer' },
                { id: 2, value: 'unternehmer_display_name', text: 'Unternehmer' },
                { id: 3, value: 'bevollmaechtigter_display_name', text: 'Bevollmächtigter' },
                { id: 4, value: 'anzahl_liegenschaften', text: 'Liegenschaft' },
                { id: 5, value: 'created', text: 'Daten' }
            ],
            STATUS: STATUS
        };
    },
    computed: {
        ...mapState('benutzer', {
            user: 'record'
        }),
        params() {
            return {
                ...this.options,
                query: this.searchString
            };
        }
    },

    watch: {
        params: {
            handler(val) {
                if (Object.keys(val).length !== 0) {
                    this.getGesuche();
                }
                saveToLocalStorage('gesuchelist_sortBy', this.options.sortBy);
            },
            deep: true
        },
        visibleHeaders: {
            handler(values) {
                let newHeader = [
                    { value: 'kanton', width: '80px', align: 'center valign-top' },
                    {
                        text: 'Gültigkeit Zusage',
                        value: 'gueltigkeit_zusage_in_tagen',
                        width: '70px',
                        align: 'center valign-top',
                        sortable: true
                    },
                    { text: 'Gesuch', value: 'nummer', align: 'start valign-top', width: '300px' }
                ];
                if (values.includes(0)) {
                    newHeader.push({ text: 'Status', value: 'status', align: 'start valign-top' });
                }
                if (values.includes(1)) {
                    newHeader.push({
                        text: 'Eigentümer',
                        value: 'eigentuemer_display_name',
                        align: 'start valign-top',
                        sortable: true
                    });
                }
                if (values.includes(2)) {
                    newHeader.push({
                        text: 'Unternehmer',
                        value: 'unternehmer_display_name',
                        align: 'start valign-top',
                        sortable: true
                    });
                }
                if (values.includes(3)) {
                    newHeader.push({
                        text: 'Bevollmächtigter',
                        value: 'bevollmaechtigter_display_name',
                        align: 'start valign-top',
                        sortable: true
                    });
                }
                if (values.includes(4)) {
                    newHeader.push({
                        text: 'Liegenschaft',
                        value: 'anzahl_liegenschaften',
                        align: 'start valign-top',
                        sortable: false
                    });
                }
                if (values.includes(5)) {
                    newHeader.push({ text: 'Daten', value: 'created', align: 'start valign-top', sortable: true });
                }
                newHeader.push({ value: 'actions', align: 'left', width: '120px', sortable: false });
                this.headers = newHeader;
                saveToLocalStorage('gesuchelist_visibleheaders', this.visibleHeaders);
            }
        }
    },
    mounted() {
        this.visibleHeaders = getFromLocalStorage('gesuchelist_visibleheaders') || [1, 2, 4, 5];
        this.options.sortBy = getFromLocalStorage('gesuchelist_sortBy') || ['created'];
    },
    methods: {
        ...mapActions('gesuch', ['fetchGesuche', 'destroyGesuch', 'extensionRequest']),
        async getGesuche() {
            this.gesuchLoading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            try {
                this.params.status = this.status;
                let data = await this.fetchGesuche(this.params);
                this.gesuche = data;
                this.total = data.get('remoteTotal');
            } catch (e) {
                console.log(e);
            } finally {
                this.gesuchLoading = false;
            }
        },

        executeSearch(value) {
            this.searchString = value;
        },

        formatDate(value) {
            return dayjs(value).format('DD.MM.YYYY');
        },

        allowDestroy(gesuch) {
            if (
                (gesuch.status !== STATUS.READY_FOR_SUBMIT && gesuch.status !== STATUS.NEW) ||
                gesuch.isLocked(this.user) ||
                gesuch.isReadOnly()
            ) {
                return false;
            }
            return gesuch.ersteller_id === this.user.id;
        },

        destroyItem(item) {
            this.toDelete = item;
            this.confirmDelDlg = true;
        },
        async confirmDestroy() {
            if (this.toDelete) {
                try {
                    await this.destroyGesuch(this.toDelete);
                    this.toDelete = null;
                    this.total = this.total - 1;
                    this.$toast({ message: this.$t('delete_gesuch_success'), color: 'success' });
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                }
            }
        },
        rowclicked(item) {
            this.$router.push({ name: 'gesuch', params: { nr: item.nummer } });
        },
        extendCommitment(item) {
            this.extedCommitmentItem = item;
            this.extendCommitmentDlg = true;
        },
        async confirmExtendCommitment() {
            if (this.extedCommitmentItem) {
                try {
                    await this.extensionRequest({ gesuch_id: this.extedCommitmentItem.id });
                    this.extendRequestedDlg = true;
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                }
            }
        }
    }
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
.gesuch-title {
    font-weight: bold;
}

.gesuch-subtitle {
    font-style: italic;
}
.gesuch-status {
    white-space: nowrap;
    font-weight: normal;
}
</style>
