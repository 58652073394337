<template>
    <v-app id="auth-app">
        <Toast></Toast>
        <Welcome :loading="loading" :value="welcomeDlg" :msg="profileMsg" @close="welcomeDlg = false" />
        <HelpDialog :value="showHelp" @close="showHelp = false"></HelpDialog>
        <AppHeaderBarTG v-if="customer === 'tg'" app :teamrequests="teamRequests" @navbtn="drawer = !drawer">
            <v-spacer />
            <NeueMitteilungenBtn v-if="mitteilungen && mitteilungen.length > 0" @mitteilungclick="onMitteilungclick" />
            <UserMenu
                :benutzer="benutzer"
                @logout="logout"
                @profil="$router.currentRoute.path != '/adressen/profil' ? $router.push('/adressen/profil') : () => {}"
            />
            <v-btn text @click="showHelp = true">
                <span class="mr-2 d-none d-md-inline">
                    {{ $t('hilfe_kontakt') }}
                </span>
                <v-icon color="grey darken-1 d-md-inline">fas fa-question</v-icon>
            </v-btn>
        </AppHeaderBarTG>
        <AppHeaderBarSH v-if="customer === 'sh'" app :teamrequests="teamRequests" @navbtn="drawer = !drawer">
            <v-spacer />
            <NeueMitteilungenBtn v-if="mitteilungen && mitteilungen.length > 0" @mitteilungclick="onMitteilungclick" />
            <UserMenu
                :benutzer="benutzer"
                @logout="logout"
                @profil="$router.currentRoute.path != '/adressen/profil' ? $router.push('/adressen/profil') : () => {}"
            />
            <v-btn text @click="showHelp = true">
                <span class="mr-2 d-none d-md-inline">
                    {{ $t('hilfe_kontakt') }}
                </span>
                <v-icon color="grey darken-1 d-md-inline">fas fa-question</v-icon>
            </v-btn>
        </AppHeaderBarSH>
        <AppHeaderBar v-if="customer === 'default'" app :teamrequests="teamRequests" @navbtn="drawer = !drawer">
            <v-spacer />
            <NeueMitteilungenBtn v-if="mitteilungen && mitteilungen.length > 0" @mitteilungclick="onMitteilungclick" />
            <UserMenu
                :benutzer="benutzer"
                @logout="logout"
                @profil="$router.currentRoute.path != '/adressen/profil' ? $router.push('/adressen/profil') : () => {}"
            />
            <v-btn text @click="showHelp = true">
                <span class="mr-2 d-none d-md-inline">
                    {{ $t('hilfe_kontakt') }}
                </span>
                <v-icon color="grey darken-1 d-md-inline">fas fa-question</v-icon>
            </v-btn>
        </AppHeaderBar>

        <v-main :class="{ white: true, 'tg-padding': customer === 'tg' }">
            <v-fade-transition mode="out-in">
                <router-view />
            </v-fade-transition>
        </v-main>
        <KpFooter v-if="customer === 'default'" app :loading="loading" />
        <KpFooterTG v-if="customer === 'tg'" app :loading="loading" />
        <KpFooterSH v-if="customer === 'sh'" app :loading="loading" />
    </v-app>
</template>

<script>
import AppHeaderBar from 'js/components/default-customer/app-header-bar.vue';
import AppHeaderBarTG from 'js/components/tg/app-header-bar.vue';
import AppHeaderBarSH from 'js/components/sh/app-header-bar.vue';
import KpFooter from 'js/components/default-customer/app-footer.vue';
import KpFooterTG from 'js/components/tg/app-footer.vue';
import KpFooterSH from 'js/components/sh/app-footer.vue';
import UserMenu from 'js/components/user-menu-btn.vue';
import NeueMitteilungenBtn from 'js/components/mitteilung/neue-mitteilungen-btn.vue';
import Toast from 'js/components/Toast.vue';
import Welcome from 'js/components/welcome-dlg.vue';
import HelpDialog from 'js/components/help-dlg.vue';

// import MainNavDrawer from 'js/components/main-nav-drawer.vue';
import { mapActions, mapState } from 'vuex';
import { getFromLocalStorage } from 'js/lib/helpers.js';

export default {
    components: {
        AppHeaderBar,
        AppHeaderBarTG,
        AppHeaderBarSH,
        // MainNavDrawer,
        KpFooter,
        KpFooterTG,
        KpFooterSH,
        NeueMitteilungenBtn,
        UserMenu,
        Toast,
        Welcome,
        HelpDialog
    },
    data: function () {
        return {
            drawer: null,
            welcomeDlg: false,
            profileMsg: null,
            showHelp: false
        };
    },
    computed: {
        loading() {
            return this.$store.state.loading;
        },
        benutzer() {
            return this.$store.state.benutzer.record;
        },
        ...mapState('mitteilung', {
            mitteilungen: (state) => state.unread_mitteilungen.entries
        }),
        customer() {
            return this.$store.state.customer;
        },
        ...mapState('teams', {
            teamRequests: (state) => state.teamRequests
        })
    },
    mounted() {
        this.loadUnreadMitteilungen();
        let noMsg = getFromLocalStorage('noWelcomeDlg_' + this.benutzer.id);
        if (!noMsg || noMsg === 'false') {
            if (!this.$store.getters['benutzer/profileComplete']) {
                this.profileMsg = this.$t('profile_update_msg');
                this.welcomeDlg = true;
            }
        }
        this.loadTeamRequests();
    },
    methods: {
        ...mapActions('benutzer', ['logout']),
        ...mapActions('mitteilung', ['loadUnreadMitteilungen']),
        ...mapActions('teams', ['loadTeamRequests']),
        onMitteilungclick(mitteilung) {
            if (mitteilung && mitteilung.gesuch_nummer) {
                this.$router.push(`/gesuche/${mitteilung.gesuch_nummer}/nachrichten`).catch(() => {});
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.tg-padding {
    // padding-top: 0 !important;
}
</style>
