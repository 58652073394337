<template>
    <v-container fluid>
        <v-row dense align="center">
            <v-spacer />
            <v-col class="shrink">
                <v-btn
                    color="action white--text"
                    :disabled="loading || closed"
                    class="mb-5"
                    @click="openMitteilungForm"
                >
                    <v-icon left>fas fa-plus</v-icon>
                    Nachricht erstellen
                </v-btn>
            </v-col>
        </v-row>
        <v-expansion-panels v-model="activeCard">
            <Mitteilung
                v-for="mitteilung in mitteilungen.models"
                :key="mitteilung.id"
                :gesuch="gesuch"
                :mitteilung="mitteilung"
                @mitteilungdestroyed="onMsgDestroyed"
            />
        </v-expansion-panels>
        <MitteilungForm
            v-if="showMitteilungForm"
            v-model="showMitteilungForm"
            :gesuch="gesuch"
            :empfaengerlist="empfaengerlist"
            @close="closeMitteilungForm"
            @mitteilungSent="onMsgSent"
        ></MitteilungForm>
    </v-container>
</template>

<script>
import Mitteilung from 'js/components/mitteilung/mitteilung-item.vue';
import MitteilungForm from 'js/components/mitteilung/mitteilung-form.vue';
import { PHASE } from 'js/models/Gesuch';
import { mapState, mapActions } from 'vuex';
import { instance as getApi } from 'js/lib/api.js';

export default {
    components: {
        Mitteilung,
        MitteilungForm
    },
    props: {
        gesuch: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            mitteilungen: [],
            showMitteilungForm: false,
            params: {},
            msgLoading: false,
            empfaengerlist: [],
            activeCard: null,
            closed: false
        };
    },
    computed: {
        ...mapState('benutzer', {
            user: 'record'
        })
    },
    watch: {
        activeCard() {
            if (typeof this.activeCard === 'undefined') {
                return;
            }
            let msg = this.mitteilungen.models[this.activeCard];
            if (
                msg.empfaenger_apollon_ids &&
                msg.empfaenger_apollon_ids.indexOf(this.user.id) >= 0 &&
                (!msg.gelesen_apollon_ids || msg.gelesen_apollon_ids.indexOf(this.user.id) < 0)
            ) {
                this.setMessageRead(msg);
            }
        }
    },
    created() {
        this.closed = this.gesuch.phase === PHASE.FERTIG;
        this.loadMitteilungen();
        this.loadEmpfaenger();
    },
    methods: {
        ...mapActions('mitteilung', ['fetchMitteilungen', 'loadUnreadMitteilungen']),
        async loadMitteilungen() {
            this.loadUnreadMitteilungen();
            this.msgLoading = true;
            try {
                this.params.filter = [
                    {
                        property: 'gesuch_id',
                        value: this.gesuch.id
                    }
                ];
                let data = await this.fetchMitteilungen(this.params);
                this.mitteilungen = data;
                this.total = data.get('remoteTotal');
            } catch (e) {
                console.error(e);
            } finally {
                this.msgLoading = false;
            }
        },
        openMitteilungForm() {
            this.showMitteilungForm = true;
        },
        closeMitteilungForm() {
            this.showMitteilungForm = false;
        },
        async loadEmpfaenger() {
            let response = await getApi().post('/mitteilung/getEmpfaenger', {
                gesuchId: this.gesuch.id,
                filterOutUser: true
            });
            this.empfaengerlist = response.data.roles.map((val) => {
                return { text: this.getRolleBezeichnung(val), value: val };
            });
        },
        async setMessageRead(msg) {
            await getApi().post('/mitteilung/setMitteilungRead', {
                mitteilungId: msg.id
            });
            this.loadMitteilungen();
        },
        onMsgDestroyed() {
            this.activeCard = undefined;
            this.loadMitteilungen();
        },
        onMsgSent() {
            this.showMitteilungForm = false;
            this.loadMitteilungen();
        },
        getRolleBezeichnung(rolle) {
            if (rolle !== 'gesuchspruefer') {
                if (this.gesuch.config.config.kontakte[rolle] && this.gesuch.config.config.kontakte[rolle].label) {
                    return this.gesuch.config.config.kontakte[rolle].label;
                }
            }
            return this.$t('apollon_rolle_' + rolle);
        }
    }
};
</script>
<style lang="sass" scoped></style>
