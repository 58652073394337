<template>
    <v-skeleton-loader
        v-if="!adresse || loading"
        type="list-item-avatar, list-item-three-line@3, actions"
    ></v-skeleton-loader>
    <div v-else>
        <v-container fluid>
            <v-form
                ref="personCardForm"
                v-model="valid"
                autocomplete="new-password"
                @input="$emit('validchange', valid)"
                @submit.stop="onSubmit"
            >
                <v-card-title text-color="white">
                    <v-icon left>fas fa-user</v-icon>
                    {{ adresse.vorname }} {{ adresse.nachname }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <AnredeSelect
                                v-model="adresse.anrede"
                                dense
                                class="px-3"
                                :label="$t('anrede')"
                                @anredechanged="(v) => onAnredeChanged(v, 1)"
                            />
                        </v-col>
                    </v-row>
                    <v-row wrap>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.vorname"
                                dense
                                class="px-3"
                                :label="$t('vorname') + ' *'"
                                :rules="[rules.required, rules.maxLength(100)]"
                            ></kp-textfield>
                        </v-col>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.nachname"
                                dense
                                class="px-3"
                                :label="$t('name') + ' *'"
                                :rules="[rules.required, rules.maxLength(100)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <div v-if="!isProfil && !reducedContactData">
                        <v-row>
                            <v-col cols="12" md="6">
                                <AnredeSelect
                                    v-model="adresse.anrede2"
                                    dense
                                    class="px-3"
                                    :label="$t('anrede') + ' (' + $t('2te_person') + ')'"
                                    @anredechanged="(v) => onAnredeChanged(v, 2)"
                                />
                            </v-col>
                        </v-row>
                        <v-row wrap>
                            <v-col cols="12" md="6">
                                <kp-textfield
                                    v-model="adresse.vorname2"
                                    dense
                                    class="px-3"
                                    :label="$t('vorname') + ' (' + $t('2te_person') + ')'"
                                    :rules="[rules.maxLength(100)]"
                                ></kp-textfield>
                            </v-col>
                            <v-col cols="12" md="6">
                                <kp-textfield
                                    v-model="adresse.nachname2"
                                    dense
                                    class="px-3"
                                    :label="$t('name') + ' (' + $t('2te_person') + ')'"
                                    :rules="[rules.maxLength(100)]"
                                ></kp-textfield>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-if="!reducedContactData" wrap>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.adresszusatz"
                                dense
                                class="px-3"
                                :label="$t('adresszusatz')"
                                :rules="[rules.maxLength(100)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <v-row v-if="!reducedContactData" wrap>
                        <v-col cols="8">
                            <kp-textfield
                                v-model="adresse.strasse"
                                dense
                                class="px-3"
                                :label="$t('strasse')"
                                :rules="[rules.maxLength(100)]"
                                @blur="() => (adresse.strasse = modStreet(adresse.strasse))"
                            ></kp-textfield>
                        </v-col>
                        <v-col cols="4">
                            <kp-textfield
                                v-model="adresse.strasse_nr"
                                dense
                                class="px-3"
                                :label="$t('nr')"
                                :rules="[rules.maxLength(20)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <v-row v-if="!reducedContactData" wrap>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.postfach"
                                dense
                                class="px-3"
                                :label="$t('postfach')"
                                :rules="[rules.maxLength(20)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <v-row v-if="!reducedContactData" wrap align="center">
                        <v-col cols="12" md="8">
                            <PlzOrtSelect
                                v-model="adresse.ortObj"
                                dense
                                class="px-3"
                                :plz="adresse.plz"
                                :ort="adresse.ort"
                                :rules="[rules.maxLength(100)]"
                                @plzselect="onPlzSelect"
                                @ortselect="onOrtSelect"
                            ></PlzOrtSelect>
                        </v-col>
                        <v-col cols="12" md="4">
                            <LandSelect
                                v-model="adresse.land_id"
                                dense
                                class="px-3"
                                :label="$t('land')"
                                @landchange="onLandChange"
                            />
                        </v-col>
                    </v-row>
                    <v-row wrap>
                        <v-col cols="12" md="6">
                            <TelefonField
                                v-model="adresse.tel_1"
                                dense
                                class="px-3"
                                :label="$t('telefon') + ' 1'"
                                prepend-icon="fas fa-phone fa-xs"
                                :rules="[rules.maxLength(30)]"
                            ></TelefonField>
                        </v-col>
                        <v-col cols="12" md="6">
                            <TelefonField
                                v-model="adresse.tel_2"
                                dense
                                class="px-3"
                                :label="$t('telefon') + ' 2'"
                                prepend-icon="fas fa-phone fa-xs"
                                :rules="[rules.maxLength(30)]"
                            ></TelefonField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.email"
                                dense
                                class="px-3"
                                :label="$t('email')"
                                prepend-icon="fas fa-at fa-xs"
                                :disabled="isProfil"
                                :rules="[rules.email, rules.maxLength(255)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <div style="padding: 10px; 5px; 5px; 5px;">
                        {{ $t('pflichtfelder_markiert') }}
                        {{ $t('pflicht_tel_email') }}
                    </div>
                </v-card-text>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import { formRules, completeStreetString } from 'js/lib/helpers.js';
import AnredeSelect from 'js/components/anrede-select.vue';
import TelefonField from 'js/components/telefon-field.vue';
import PlzOrtSelect from 'js/components/plz-ort-select.vue';
import LandSelect from 'js/components/land-select.vue';
import { Adresse } from 'js/models/Adresse';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        AnredeSelect,
        LandSelect,
        TelefonField,
        PlzOrtSelect,
        KpTextfield
    },
    props: {
        adresse: {
            type: Adresse,
            default: null
        },
        isProfil: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: true
        },
        isNew: {
            type: Boolean,
            default: false
        },
        reducedContactData: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            rules: formRules,
            modStreet: completeStreetString,
            valid: false
        };
    },

    watch: {
        value(val, oldVal) {
            if (val && !oldVal && this.isNew && this.$refs.personCardForm) {
                this.$refs.personCardForm.resetValidation();
            }
        }
    },

    methods: {
        onPlzSelect(value) {
            this.adresse.plz = value;
        },

        onOrtSelect(value) {
            this.adresse.ort = value;
        },

        onLandChange(land) {
            this.adresse.laendercode = land.laendercode;
            this.adresse.land = land.name;
        },

        onAnredeChanged(value, index) {
            this.adresse['anrede' + (index > 1 ? index : '')] = value;
        }
    }
};
</script>
