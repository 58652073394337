/**
 * Routes: /teams/ and below
 *
 * Will me merged into the router's 'route' array
 */
import { getStore } from 'js/store';
import { awaitAsyncFunction } from 'js/lib/helpers';

const teamsList = () => import(/* webpackChunkName: "teams" */ 'js/views/auth/teams/TeamsList.vue');
const teamForm = () => import(/* webpackChunkName: "teams" */ 'js/views/auth/teams/TeamForm.vue');

const routes = [
    {
        path: '/teams',
        name: 'teams',
        component: () => awaitAsyncFunction(teamsList, getStore()),
        meta: {
            requiresAuth: true,
            title: 'Teams'
        }
    },
    {
        path: '/teams/:id',
        name: 'team_edit',
        component: () => awaitAsyncFunction(teamForm, getStore()),
        meta: {
            requiresAuth: true,
            title: (to) => `Team: ${to.params.id}`
        }
    }
];

export default routes;
