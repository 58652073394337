<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
                <span class="mr-2 d-none d-md-inline">{{ $t('mein_profil') }}</span>
                <v-icon color="grey darken-1">fas fa-user</v-icon>
            </v-btn>
        </template>

        <v-list dense>
            <v-list-item class="d-flex d-md-none">
                <v-list-item-title>{{ benutzer.email }}</v-list-item-title>
            </v-list-item>
            <v-divider class="d-block d-md-none" />
            <v-list-item @click="$emit('profil')">
                <v-list-item-icon>
                    <v-icon>fas fa-user</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('change_my_data') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'change_pw' })">
                <v-list-item-icon>
                    <v-icon>fas fa-key</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('change_my_password') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'change_email' })">
                <v-list-item-icon>
                    <v-icon>fas fa-at</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('change_my_email') }}</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item @click="$emit('logout')">
                <v-list-item-icon>
                    <v-icon>fas fa-sign-out-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    components: {},
    props: ['benutzer'],
    data: function () {
        return {};
    },
    computed: {},
    methods: {}
};
</script>
