/**
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';

export class Kanton extends BaseModel {
    defaults() {
        return {
            id: null,
            kuerzel: null,
            kurz: null,
            lang: null,
            icon: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'kanton';
    }
}
