<template>
    <div v-if="adresse">
        <v-container fluid>
            <v-form
                ref="organisationCardForm"
                v-model="valid"
                autocomplete="new-password"
                @input="$emit('validchange', valid)"
                @submit.stop="onSubmit"
            >
                <v-card-title text-color="white">
                    <v-icon left>fas fa-building</v-icon>
                    {{ adresse.organisation_name }}
                </v-card-title>
                <v-card-text>
                    <v-row wrap>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.organisation_name"
                                dense
                                class="px-3"
                                :label="$t('name') + ' *'"
                                :rules="[rules.required, rules.maxLength(100)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <v-row wrap>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.adresszusatz"
                                dense
                                class="px-3"
                                :label="$t('adresszusatz')"
                                :rules="[rules.maxLength(100)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <v-row wrap>
                        <v-col cols="8">
                            <kp-textfield
                                v-model="adresse.strasse"
                                dense
                                class="px-3"
                                :label="$t('strasse')"
                                :rules="[rules.maxLength(100)]"
                                @blur="() => (adresse.strasse = modStreet(adresse.strasse))"
                            ></kp-textfield>
                        </v-col>
                        <v-col cols="4">
                            <kp-textfield
                                v-model="adresse.strasse_nr"
                                dense
                                class="px-3"
                                :label="$t('nr')"
                                :rules="[rules.maxLength(20)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <v-row wrap>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.postfach"
                                dense
                                class="px-3"
                                :label="$t('postfach')"
                                :rules="[rules.maxLength(20)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <v-row wrap align="center">
                        <v-col cols="12" md="8">
                            <PlzOrtSelect
                                dense
                                class="px-3"
                                :plz="adresse.plz"
                                :ort="adresse.ort"
                                :rules="[rules.maxLength(100)]"
                                @plzselect="onPlzSelect"
                                @ortselect="onOrtSelect"
                            ></PlzOrtSelect>
                        </v-col>
                        <v-col cols="12" md="4">
                            <LandSelect
                                v-model="adresse.land_id"
                                dense
                                class="px-3"
                                :label="$t('land')"
                                @landchange="onLandChange"
                            />
                        </v-col>
                    </v-row>
                    <v-row wrap>
                        <v-col cols="12" md="6">
                            <TelefonField
                                v-model="adresse.organisation_tel_1"
                                dense
                                class="px-3"
                                :label="$t('telefon') + ' 1'"
                                prepend-icon="fas fa-phone fa-xs"
                                :rules="[rules.maxLength(30)]"
                            ></TelefonField>
                        </v-col>
                        <v-col cols="12" md="6">
                            <TelefonField
                                v-model="adresse.organisation_tel_2"
                                dense
                                class="px-3"
                                :label="$t('telefon') + ' 2'"
                                prepend-icon="fas fa-phone fa-xs"
                                :rules="[rules.maxLength(30)]"
                            ></TelefonField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <kp-textfield
                                v-model="adresse.organisation_email"
                                dense
                                class="px-3"
                                :label="$t('email')"
                                prepend-icon="fas fa-at fa-xs"
                                :disabled="isProfil"
                                :rules="[rules.email, rules.maxLength(255)]"
                            ></kp-textfield>
                        </v-col>
                    </v-row>
                    <v-expansion-panels v-if="gesuchContext">
                        <v-expansion-panel elevation="3" class="grey lighten-2 mb-2">
                            <v-expansion-panel-header v-slot="{ open }">
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title v-if="open || !adresse.vorname">
                                            <v-icon left>fas fa-user</v-icon>
                                            {{ $t('contact') }}
                                        </v-card-title>
                                        <v-card-title v-else>
                                            <v-icon left>fas fa-user</v-icon>
                                            {{ $t('contact') }}: {{ adresse.vorname }} {{ adresse.nachname }}
                                        </v-card-title>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete
                                            v-model="contact"
                                            :loading="contactLoading"
                                            :items="contactCollection ? contactCollection.models : []"
                                            :search-input.sync="contactSearch"
                                            cache-items
                                            class="px-3"
                                            item-text="vorname_name"
                                            item-value="id"
                                            :label="$t('search')"
                                            clearable
                                            return-object
                                            clear-icon="fas fa-times"
                                            @input="contactSelected"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <AnredeSelect
                                            v-model="adresse.anrede"
                                            dense
                                            class="px-3"
                                            :label="$t('anrede')"
                                            @anredechanged="onAnredeChanged"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row wrap>
                                    <v-col cols="12" md="6">
                                        <kp-textfield
                                            v-model="adresse.vorname"
                                            dense
                                            class="px-3"
                                            :label="$t('vorname')"
                                            :rules="[rules.maxLength(100)]"
                                        ></kp-textfield>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <kp-textfield
                                            v-model="adresse.nachname"
                                            dense
                                            class="px-3"
                                            :label="$t('name')"
                                            :rules="[rules.maxLength(100)]"
                                        ></kp-textfield>
                                    </v-col>
                                </v-row>
                                <v-row wrap>
                                    <v-col cols="12" md="6">
                                        <TelefonField
                                            v-model="adresse.tel_1"
                                            dense
                                            class="px-3"
                                            :label="$t('telefon') + ' 1'"
                                            prepend-icon="fas fa-phone fa-xs"
                                            :rules="[rules.maxLength(30)]"
                                        ></TelefonField>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <TelefonField
                                            v-model="adresse.tel_2"
                                            dense
                                            class="px-3"
                                            :label="$t('telefon') + ' 2'"
                                            prepend-icon="fas fa-phone fa-xs"
                                            :rules="[rules.maxLength(30)]"
                                        ></TelefonField>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <kp-textfield
                                            v-model="adresse.email"
                                            dense
                                            class="px-3"
                                            prepend-icon="fas fa-at fa-xs"
                                            :label="$t('email')"
                                            :rules="[rules.email, rules.maxLength(255)]"
                                        ></kp-textfield>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-card elevation="3" :color="customer === 'tg' ? '#40a5364a' : '#ffdc003b'">
                        <div style="font-weight: bold;padding: 10px; 5px; 5px; 5px;">
                            {{ $t('nur_wenn_unternehmer_einreicht') }}
                        </div>
                        <v-row>
                            <v-col cols="12" md="6">
                                <div class="d-flex">
                                    <kp-textfield
                                        v-model="adresse.uid"
                                        dense
                                        class="px-3"
                                        :label="$t('uid')"
                                        :rules="[rules.uid]"
                                    ></kp-textfield>
                                    <FieldInfoIcon :infotext="$t('uid_help')" left />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <EigentuemerschaftSelect
                                    v-model="adresse.eigentuemerschaft"
                                    dense
                                    class="px-3"
                                    :label="$t('art') + ' *'"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                    <div style="padding: 10px; 5px; 5px; 5px;">
                        {{ $t('pflichtfelder_markiert') }}
                        {{ $t('pflicht_tel_email') }}
                    </div>
                </v-card-text>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import { formRules, completeStreetString } from 'js/lib/helpers.js';
import TelefonField from 'js/components/telefon-field.vue';
import PlzOrtSelect from 'js/components/plz-ort-select.vue';
import LandSelect from 'js/components/land-select.vue';
import { mapActions } from 'vuex';
import AnredeSelect from 'js/components/anrede-select.vue';
import FieldInfoIcon from 'js/components/field-info-icon.vue';
import { Adresse, AdresseCollection } from 'js/models/Adresse';
import EigentuemerschaftSelect from 'js/components/eigentuemerschaft-select.vue';
import KpTextfield from 'js/components/kp-textfield.vue';
import { debounce } from 'js/lib/helpers.js';

export default {
    components: {
        FieldInfoIcon,
        LandSelect,
        TelefonField,
        PlzOrtSelect,
        EigentuemerschaftSelect,
        KpTextfield,
        AnredeSelect
    },
    props: {
        adresse: {
            type: Adresse,
            default: null
        },
        isProfil: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: true
        },
        isNew: {
            type: Boolean,
            default: false
        },
        gesuchContext: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            rules: formRules,
            modStreet: completeStreetString,
            valid: false,
            contact: null,
            contactLoading: false,
            contactCollection: null,
            contactSearch: null
        };
    },

    computed: {
        customer() {
            return this.$store.state.customer;
        }
    },

    watch: {
        value(val, oldVal) {
            if (val && !oldVal && this.isNew && this.$refs.organisationCardForm) {
                this.$refs.organisationCardForm.resetValidation();
            }
        },
        contactSearch: debounce(async function (val) {
            if (!val || val.length < 3) {
                return;
            }
            if (!this.contactCollection) {
                this.contactCollection = new AdresseCollection(null, {});
            } else {
                this.contactCollection = this.contactCollection.filter((adr) => `${adr.vorname}` === val);
            }
            if (!this.contactCollection.models.length) {
                let list = await this.fetchAdressen({ query: val, gesuchId: -1 }); // gesuchId: -1 -> ugly hack to get my own address
                this.contactCollection = list;
            }
        })
    },

    methods: {
        ...mapActions('adressen', ['fetchAdressen']),
        onPlzSelect(value) {
            this.adresse.plz = value;
        },

        onOrtSelect(value) {
            this.adresse.ort = value;
        },

        onLandChange(land) {
            this.adresse.laendercode = land.laendercode;
            this.adresse.land = land.name;
        },

        onAnredeChanged(value, index) {
            this.adresse['anrede' + (index > 1 ? index : '')] = value;
        },
        contactSelected(contact) {
            if (contact) {
                this.adresse.anrede = contact.anrede;
                this.adresse.vorname = contact.vorname;
                this.adresse.nachname = contact.nachname;
                this.adresse.tel_1 = contact.tel_1;
                this.adresse.tel_2 = contact.tel_2;
                this.adresse.email = contact.email;
            }
        }
    }
};
</script>
