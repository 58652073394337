import Vue from 'vue';
import VueRouter from 'vue-router';
import { getStore } from 'js/store/index.js';

import guestRoutes from './guest_routes';
import authRoutes from './auth_routes';
import gesucheRoutes from './gesuche';
import adressRoutes from './adressen';
import newsRoutes from './news';
import liegenschaftenRoutes from './liegenschaften';
import teamsRoutes from './teams';

Vue.use(VueRouter);

/**
 * Dynamically loaded Junks:
 */

const routes = [
    ...guestRoutes,
    ...authRoutes,
    ...gesucheRoutes,
    ...adressRoutes,
    ...liegenschaftenRoutes,
    ...teamsRoutes,
    ...newsRoutes
];

const router = new VueRouter({
    routes // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
    // Auth requiring routes need an auth user:
    if (to.matched.some((rec) => rec.meta.requiresAuth)) {
        if (!getStore().getters.isAuthenticated) {
            if (to.name === 'home') {
                return next({
                    name: 'welcome'
                });
            }
            return next({
                name: 'login',
                query: {
                    redirect: to.path
                }
            });
        } else {
            // Check if the route needs admin privileges
            if (to.matched.some((rec) => rec.meta.requiresAdmin) && !getStore().getters.isAdmin) {
                return next({
                    name: 'home'
                });
            }
            return next();
        }
    }

    // guest routes can only be accessed when not auth:
    if (to.matched.some((rec) => rec.meta.guest)) {
        if (getStore().getters.isAuthenticated) {
            return next({
                name: 'home'
            });
        } else {
            return next();
        }
    }

    // If you come this far, the route can be displayed in either case:
    return next();
});

router.afterEach((to, from) => {
    // see https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609 why we use
    // nextTick here:
    Vue.nextTick(() => {
        // Update the page's title, based on the Route's meta data, if applicable:
        let title = (to.meta && to.meta.title) || null;
        if (title instanceof Function) {
            title = title(to, from);
        }
        document.title = (title || '') + (title ? ' - Energieförderprogramm' : 'Energieförderprogramm');
    });
});

export default router;
