/**
 * Store module: benutzer
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import auth from 'js/lib/auth.js';

import { Benutzer } from 'js/models/Benutzer';
import { Adresse } from 'js/models/Adresse';

export default {
    namespaced: true,
    state: {
        error: null,
        record: null
    },
    mutations: {
        reset(state) {
            state.record = null;
        },
        setBenutzer(state, benutzer) {
            state.record = Benutzer.fromData(benutzer);
        },
        setError(state, error) {
            state.error = error;
        },
        updateProfilAdresse(state, adresse) {
            if (adresse instanceof Adresse && state.record.profilAdresse.id === adresse.id) {
                state.record.profilAdresse = adresse;
            }
        }
    },
    getters: {
        profileComplete(state) {
            let profile = state.record.profilAdresse;
            if (!profile.typ) {
                return false;
            } else if (profile.typ === 'person') {
                return profile.nachname && profile.vorname;
            }
        }
    },
    actions: {
        /**
         * Executes a login request. Returns the user
         * object when successful, or sets an error if not.
         *
         * Stores the user in the state. (benutzer/record)
         */
        async loginTry({ commit }, { username, password }) {
            commit('loading', true, { root: true });
            commit('setError', null);
            let benutzer = null;
            try {
                let loginResponse = await auth.login(username, password);
                benutzer = Benutzer.fromData(loginResponse.user);
                commit('setBenutzer', benutzer);
                if (loginResponse.redirectUrl) {
                    commit('setRedirectUrl', loginResponse.redirectUrl, { root: true });
                }
            } catch (err) {
                commit('setError', String(err.message || err));
            }
            commit('loading', false, { root: true });
            return benutzer;
        },

        /**
         * Logs out the user and sets the user state to null.
         */
        async logout({ commit }) {
            commit('loading', true, { root: true });
            try {
                await auth.logout();
                commit('setBenutzer', null);
                document.location.reload();
            } catch (err) {
                commit('setError', String(err.message || err));
            }
            commit('loading', false, { root: true });
        },

        async resetPassword({ commit }, { username }) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await auth.resetPassword(username);
            } catch (err) {
                res = err;
            }
            commit('loading', false, { root: true });
            return res;
        },

        async verifyUser({ commit }, password) {
            commit('loading', true, { root: true });
            let res = await auth.verifyUser(password);
            commit('loading', false, { root: true });
            return res;
        }
    }
};
