<template>
    <v-dialog :value="value" persistent :max-width="600" @click:outside="$emit('input', false)">
        <v-card>
            <slot name="title">
                <v-card-title class="headline">{{ $t('welcome') }}</v-card-title>
            </slot>
            <slot>
                <v-card-text>{{ msg }}</v-card-text>
            </slot>
            <slot name="actions">
                <v-card-actions>
                    <v-checkbox v-model="dontshowanymore" class="" :label="$t('dont_show_anymore')"></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-btn color="decline" text :loading="loading" @click="$emit('close')">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="action white--text" :disabled="loading" @click="onProfile">
                        {{ $t('edit_profile') }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
import { saveToLocalStorage } from 'js/lib/helpers.js';
export default {
    props: {
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        msg: { type: String, default: null }
    },
    data() {
        return {
            dontshowanymore: false
        };
    },
    computed: {
        benutzer() {
            return this.$store.state.benutzer.record;
        }
    },
    watch: {
        dontshowanymore: {
            handler(val) {
                this.save(val);
            }
        }
    },
    methods: {
        onProfile() {
            this.$router.push('adressen/profil');
            this.$emit('close');
        },
        save(value) {
            saveToLocalStorage('noWelcomeDlg_' + this.benutzer.id, value);
        }
    }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}
</style>
