<template>
    <v-dialog :value="value" max-width="1000" @click:outside="$emit('input', false)">
        <v-window v-model="step">
            <!-- Select Person/Organisation -->
            <v-window-item :value="1">
                <v-card>
                    <slot name="title">
                        <v-card-title class="headline">{{ config.label || 'LABEL' }} {{ $t('assign') }}</v-card-title>
                    </slot>
                    <slot>
                        <v-card-text>
                            <v-card tile min-height="180px" :loading="loading">
                                <v-card-text>
                                    <div v-if="newAdress">
                                        <v-card-title class="pb-0">
                                            <div v-if="newAdress.typ === 'organisation'">
                                                {{ newAdress.organisation_name }}
                                            </div>
                                            <div v-else>
                                                {{ newAdress.anrede }} {{ newAdress.display_name }}
                                                {{ newAdress.vorname }} <br />{{ newAdress.anrede2 }}
                                                {{ newAdress.nachname2 }}
                                                {{ newAdress.vorname2 }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <div v-if="newContactAdress">
                                                {{ newContactAdress.display_name }} {{ newContactAdress.vorname }}
                                            </div>

                                            <div v-if="newAdress.typ === 'organisation'">
                                                {{ newAdress.anrede }} {{ newAdress.nachname }} {{ newAdress.vorname }}
                                            </div>
                                            <div v-if="newAdress.adresszusatz">{{ newAdress.adresszusatz }}</div>
                                            <div v-if="newAdress.adresse">{{ newAdress.adresse }}</div>
                                            <div v-if="newAdress.postfach">{{ newAdress.postfach }}</div>
                                            <div v-if="newAdress.plz_ort">
                                                {{
                                                    newAdress.laendercode && newAdress.laendercode !== 'CH'
                                                        ? newAdress.laendercode + '-'
                                                        : ''
                                                }}{{ newAdress.plz_ort }}
                                            </div>
                                            <div v-if="newAdress.tel_1">
                                                <v-icon x-small class="pr-1">fas fa-phone</v-icon>
                                                <kp-link tel :href="newAdress.tel_1">
                                                    {{ formattedPhoneNumber(newAdress.tel_1) }}
                                                </kp-link>
                                                {{ newAdress.tel_2 ? '/' : '' }}
                                                <kp-link v-if="newAdress.tel_2" tel :href="newAdress.tel_2">
                                                    {{ formattedPhoneNumber(newAdress.tel_2) }}
                                                </kp-link>
                                            </div>
                                            <div v-else-if="newAdress.organisation_tel_1">
                                                <v-icon x-small class="pr-1">fas fa-phone</v-icon>
                                                <kp-link tel :href="newAdress.organisation_tel_1">
                                                    {{ formattedPhoneNumber(newAdress.organisation_tel_1) }}
                                                </kp-link>
                                                {{ newAdress.organisation_tel_2 ? '/' : '' }}
                                                <kp-link
                                                    v-if="newAdress.organisation_tel_2"
                                                    tel
                                                    :href="newAdress.organisation_tel_2"
                                                >
                                                    {{ formattedPhoneNumber(newAdress.organisation_tel_2) }}
                                                </kp-link>
                                            </div>
                                            <div v-if="newAdress.email">
                                                <v-icon x-small class="pr-1">fas fa-at</v-icon>
                                                <kp-link mailto :href="newAdress.email" />
                                            </div>
                                            <div v-if="newAdress.organisation_email">
                                                <v-icon x-small class="pr-1">fas fa-at</v-icon>
                                                <kp-link mailto :href="newAdress.organisation_email" />
                                            </div>
                                        </v-card-text>
                                    </div>
                                    <div v-else>
                                        <v-card-subtitle>{{ $t('address_selection') }}</v-card-subtitle>
                                    </div>
                                </v-card-text>
                            </v-card>
                            <AdressenGrid
                                :edit="false"
                                :dense="true"
                                :selectable="true"
                                :height="300"
                                :addbtn="$t('new_address')"
                                :headerwidths="{
                                    typ: '5%',
                                    name: '18%',
                                    vorname: '18%',
                                    adresse: '25%',
                                    land: '0%',
                                    plz: '14%',
                                    ort: '18%',
                                    action: '0%'
                                }"
                                :gesuch-id="getGesuchId()"
                                :selection="newAdress"
                                :typfilter="identFilter"
                                @selection="selectionChanged"
                                @new-address="$emit('new-address')"
                            ></AdressenGrid>
                        </v-card-text>
                    </slot>
                    <slot name="actions">
                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn text color="decline" :disabled="loading" @click="cancel">
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                                v-if="newAdress && newAdress.typ === typPerson"
                                color="action"
                                :loading="loading"
                                :disabled="!newAdress"
                                @click="assign"
                            >
                                <v-icon left>fas fa-check</v-icon>
                                {{ $t('assign') }}
                            </v-btn>
                            <v-btn v-else color="action" :loading="loading" :disabled="!newAdress" @click="next">
                                <div>{{ $t('next') }}</div>
                            </v-btn>
                        </v-card-actions>
                    </slot>
                </v-card>
            </v-window-item>

            <!-- If an Organisation is selected in the first place, select a contact Person now -->
            <v-window-item :value="2">
                <v-card>
                    <slot name="title">
                        <v-card-title class="headline">
                            {{ $t('add_contact', { org: newAdress ? newAdress.organisation_name : '' }) }}
                        </v-card-title>
                    </slot>
                    <slot>
                        <v-card-text>
                            <v-card tile min-height="180px" :loading="loading">
                                <v-card-text>
                                    <div v-if="newAdress">
                                        <v-card-title class="pb-0">
                                            <div v-if="newAdress.typ === 'organisation'">
                                                {{ newAdress.organisation_name }}
                                            </div>
                                            <div v-else>
                                                {{ newAdress.anrede }} {{ newAdress.display_name }}
                                                {{ newAdress.vorname }} <br />{{ newAdress.anrede2 }}
                                                {{ newAdress.nachname2 }}
                                                {{ newAdress.vorname2 }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <div v-if="newContactAdress">
                                                {{ newContactAdress.display_name }} {{ newContactAdress.vorname }}
                                            </div>

                                            <div v-if="newAdress.typ === 'organisation'">
                                                {{ newAdress.anrede }} {{ newAdress.nachname }} {{ newAdress.vorname }}
                                            </div>
                                            <div v-if="newAdress.adresszusatz">{{ newAdress.adresszusatz }}</div>
                                            <div v-if="newAdress.adresse">{{ newAdress.adresse }}</div>
                                            <div v-if="newAdress.postfach">{{ newAdress.postfach }}</div>
                                            <div v-if="newAdress.plz_ort">
                                                {{
                                                    newAdress.laendercode && newAdress.laendercode !== 'CH'
                                                        ? newAdress.laendercode + '-'
                                                        : ''
                                                }}{{ newAdress.plz_ort }}
                                            </div>
                                            <div v-if="newAdress.tel_1">
                                                <v-icon x-small class="pr-1">fas fa-phone</v-icon>
                                                <kp-link tel :href="newAdress.tel_1">
                                                    {{ formattedPhoneNumber(newAdress.tel_1) }}
                                                </kp-link>
                                                {{ newAdress.tel_2 ? '/' : '' }}
                                                <kp-link v-if="newAdress.tel_2" tel :href="newAdress.tel_2">
                                                    {{ formattedPhoneNumber(newAdress.tel_2) }}
                                                </kp-link>
                                            </div>
                                            <div v-else-if="newAdress.organisation_tel_1">
                                                <v-icon x-small class="pr-1">fas fa-phone</v-icon>
                                                <kp-link tel :href="newAdress.organisation_tel_1">
                                                    {{ formattedPhoneNumber(newAdress.organisation_tel_1) }}
                                                </kp-link>
                                                {{ newAdress.organisation_tel_2 ? '/' : '' }}
                                                <kp-link
                                                    v-if="newAdress.organisation_tel_2"
                                                    tel
                                                    :href="newAdress.organisation_tel_2"
                                                >
                                                    {{ formattedPhoneNumber(newAdress.organisation_tel_2) }}
                                                </kp-link>
                                            </div>
                                            <div v-if="newAdress.email">
                                                <v-icon x-small class="pr-1">fas fa-at</v-icon>
                                                <kp-link mailto :href="newAdress.email" />
                                            </div>
                                            <div v-if="newContactAdress && newContactAdress.email">
                                                <v-icon x-small class="pr-1">fas fa-at</v-icon>
                                                <kp-link mailto :href="newContactAdress.email" />
                                            </div>
                                        </v-card-text>
                                    </div>
                                    <div v-else>
                                        <v-card-subtitle>{{ $t('address_selection') }}</v-card-subtitle>
                                    </div>
                                </v-card-text>
                            </v-card>
                            <v-checkbox
                                v-if="!forceContact"
                                v-model="showOrgGrid"
                                :label="$t('contact_for_org', { org: newAdress ? newAdress.organisation_name : '' })"
                            ></v-checkbox>
                            <AdressenGrid
                                v-if="forceContact || showOrgGrid"
                                :edit="false"
                                :dense="true"
                                :selectable="true"
                                :height="300"
                                :addbtn="$t('add_contact')"
                                :headerwidths="{
                                    typ: '5%',
                                    name: '18%',
                                    vorname: '18%',
                                    adresse: '25%',
                                    land: '0%',
                                    plz: '14%',
                                    ort: '18%',
                                    action: '0%'
                                }"
                                :gesuch-id="getGesuchId()"
                                :selection="newContactAdress"
                                :typfilter="typPerson"
                                @selection="contactSelectionChanged"
                                @new-address="$emit('new-contact')"
                            ></AdressenGrid>
                        </v-card-text>
                    </slot>
                    <slot name="actions">
                        <v-card-actions>
                            <v-btn text color="decline" :disabled="loading" @click="onBackButton">
                                {{ $t('back') }}
                            </v-btn>
                            <v-spacer></v-spacer>

                            <v-btn text color="decline" :disabled="loading" @click="cancel">
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn
                                color="action"
                                :loading="loading"
                                :disabled="(forceContact && !newContactAdress) || (showOrgGrid && !newContactAdress)"
                                @click="assign"
                            >
                                <v-icon left>fas fa-check</v-icon>
                                {{ $t('assign') }}
                            </v-btn>
                        </v-card-actions>
                    </slot>
                </v-card>
            </v-window-item>
        </v-window>
    </v-dialog>
</template>

<script>
import { Gesuch } from 'js/models/Gesuch';
import { Adresse } from 'js/models/Adresse';
import { formatTelefonNumber } from 'js/lib/helpers.js';
import AdressenGrid from 'js/components/adressen-grid.vue';
import link from 'js/components/link.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        AdressenGrid,
        'kp-link': link
    },
    props: {
        value: Boolean, // v-model (true: show, hide: false)
        loading: { type: Boolean, default: false },
        ident: { type: String, default: null },
        gesuch: { type: Gesuch, default: null },
        adresse: { type: Adresse, default: null }
    },
    data: function () {
        return {
            newAdress: null,
            newContactAdress: null,
            step: 1,
            typPerson: Adresse.TYP_PERSON,
            showOrgGrid: false
        };
    },
    computed: {
        config: function () {
            return this.gesuch.config.config.kontakte[this.ident] || {};
        },
        adressData: function () {
            return this.gesuch.data.adressen[this.ident] || {};
        },
        identFilter: function () {
            switch (this.ident) {
                case 'unternehmer':
                    return Adresse.TYP_ORGANISATION;
                case 'kontakt':
                    return Adresse.TYP_PERSON;
                default:
                    return null;
            }
        },
        forceContact: function () {
            return this.ident === 'unternehmer' || this.ident === 'bevollmaechtigter';
        }
    },
    watch: {
        adresse: {
            handler(adr) {
                this.newAdress = adr;
                if (adr.typ === 'organisation') {
                    this.step = 2;
                    this.$emit('oganisation-selected', this.newAdress);
                }
            },
            deep: true
        },
        showOrgGrid: {
            handler(val) {
                if (!val) {
                    this.contactSelectionChanged(null);
                }
            }
        }
    },
    mounted() {
        this.newAdress = this.adresse;
    },
    methods: {
        ...mapActions('adressen', ['loadAdresse']),
        async assign() {
            this.$emit('assign', this.newAdress, this.newContactAdress);
        },
        selectionChanged(adr) {
            this.newAdress = adr;
        },
        contactSelectionChanged(adr) {
            this.newContactAdress = adr;
        },
        cancel() {
            this.newAdress = this.adresse;
            this.$emit('close');
        },
        getGesuchId() {
            return this.gesuch.id;
        },
        onBackButton() {
            this.step = 1;
            this.newContactAdress = null;
        },
        formattedPhoneNumber(value) {
            return formatTelefonNumber(value);
        },
        next() {
            this.step = 2;
            this.$emit('oganisation-selected', this.newAdress);
        }
    }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}
</style>
