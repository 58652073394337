<template>
    <v-alert
        v-if="banner"
        class="ma-1"
        :type="banner.type || 'success'"
        :icon="banner.icon || false"
        outlined
        :color="banner.color || 'primary'"
        dismissible
        dense
        text
    >
        <strong>Ihre nächste Aktion: </strong>{{ banner.text }}
    </v-alert>
</template>

<script>
import { Gesuch, STATUS } from 'js/models/Gesuch';

const STATUS_MAP = {
    [STATUS.NEW]: {
        color: 'action',
        type: 'success',
        icon: false,
        text: 'Gesuch ausfüllen → Gesuch einreichen (zwingend vor Umsetzung)'
    },
    [STATUS.READY_FOR_SUBMIT]: {
        color: 'action',
        type: 'success',
        icon: false,
        text: 'Gesuch einreichen'
    },
    [STATUS.AB_READY_FOR_SUBMIT]: {
        color: 'action',
        type: 'success',
        icon: false,
        text: 'Projektabschluss einreichen'
    },
    [STATUS.APPROVED]: {
        color: 'action',
        type: 'success',
        icon: false,
        text: 'Kontoinformation ergänzen → fehlende Angaben ergänzen → alle Angaben überprüfen → Projektabschluss einreichen'
    },
    [STATUS.INCOMPLETE]: {
        color: 'action',
        type: 'success',
        icon: false,
        text: 'fehlende Angaben ergänzen → Gesuch erneut einreichen'
    },
    [STATUS.AB_INCOMPLETE]: {
        color: 'action',
        type: 'success',
        icon: false,
        text: 'fehlende Angaben ergänzen → Projektabschluss erneut einreichen'
    }
};

export default {
    props: {
        gesuch: {
            type: Gesuch,
            required: true
        }
    },
    data: function () {
        return {};
    },
    computed: {
        banner() {
            return this.mapStatus(this.gesuch.status);
        }
    },

    methods: {
        mapStatus(status) {
            return STATUS_MAP[status] || null;
        }
    }
};
</script>
