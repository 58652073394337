/**
 * Represents a zeus.apollon_fk_conf.conf.projekt.feldgruppen value object - the Foerderkategorie config
 * json structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model } from 'vue-mc';
import { ProjektFeld } from './ProjektFeld';
import dayjs from 'dayjs';

export class ProjektFeldGruppe extends Model {
    defaults() {
        return {
            label: null,
            einreichung: null,
            einreichung_from: null,
            einreichung_to: null,
            abschluss: null,
            abschluss_from: null,
            abschluss_to: null,
            hilfetext: null,
            felder: []
        };
    }

    mutations() {
        return {
            felder: (val) => (val ? val.map((feld) => new ProjektFeld(feld)) : []),
            einreichung_from: (date) => (date ? dayjs(date) : null),
            einreichung_to: (date) => (date ? dayjs(date) : null),
            abschluss_from: (date) => (date ? dayjs(date) : null),
            abschluss_to: (date) => (date ? dayjs(date) : null)
        };
    }
}
