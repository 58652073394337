/**
 * Store module: gesuch
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { instance as api } from 'js/lib/api';
import { Gesuch, GesuchCollection } from 'js/models/Gesuch';

export default {
    namespaced: true,
    state: {
        actGesuch: null, // The actual opened gesuch (/gesuch/:id)
        gesuche: [],
        loading: false
    },
    mutations: {
        setGesuch(state, gesuch) {
            state.actGesuch = gesuch;
        },
        addGesuch(state, gesuch) {
            state.gesuche.push(gesuch);
        },
        setGesuche(state, gesuchArr) {
            state.gesuche = gesuchArr;
        }
    },

    actions: {
        /**
         * Creates a new Gesuch. The creator is the actual user.
         *
         * @param {Object} store The vuex store
         * @param {Object} gesuchConfig To create a gesuch, the following information is needed:
         *                      - rolle: The ident of the role the user taks in this Gesuch
         *                      - apollon_conf_fk_id: The id of the ApollonConfFk record to use
         */
        async createGesuch({ commit }, { rolle, org_id, apollon_conf_fk_id }) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                res = await api().post('/gesuch/create', {
                    rolle,
                    org_id,
                    apollon_conf_fk_id
                });
                res = new Gesuch(res.data.gesuch);
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        async loadGesuch({ commit }, gesuchNr) {
            commit('loading', true, { root: true });
            commit('setGesuch', null);
            let gesuch = null;
            try {
                let res = await api().get('/gesuch/load', {
                    params: {
                        gesuch_nummer: gesuchNr,
                        for_writing: true
                    }
                });
                gesuch = new Gesuch(res.data.gesuch);
                gesuch.config = res.data.config;
                gesuch.zeus_dokumente = res.data.zeus_dokumente;
                commit('setGesuch', gesuch);
            } finally {
                commit('loading', false, { root: true });
            }
            return gesuch;
        },

        async fetchGesuche({ commit }, options = {}) {
            let list = new GesuchCollection();

            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        /**
         * Update gesuch data
         *
         * @param {gesuch} gesuch A Gesuch entity record
         */
        async storeGesuch({ commit, state }, gesuch) {
            commit('loading', true, { root: true });
            try {
                let res = await gesuch.save();
                if (res.getData().data) {
                    state.actGesuch.data = res.getData().data;
                }
            } catch (e) {
                if (e?.response?.data?.data) {
                    state.actGesuch.data = e.response.data.data;
                }
                throw e;
            } finally {
                commit('loading', false, { root: true });
            }
        },

        async commitGesuch({ commit }, options = {}) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                res = await api().post('/gesuch/commit', {
                    gesuch_id: options.gesuch_id,
                    inform_types: options.inform_types
                });
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        async verifyGesuch({ commit }, gesuchId) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                res = await api().post('/gesuch/verify', {
                    gesuch_id: gesuchId
                });
                res = res.data;
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        async getDelegates({ commit }, gesuchId) {
            let res = await api().post('/gesuch/getDelegates', {
                gesuch_id: gesuchId
            });
            return res.data;
        },

        async delegateGesuch({ commit }, options = {}) {
            let res = null;
            commit('loading', true, { root: true });
            try {
                res = await api().post('/gesuch/delegate', {
                    gesuch_id: options.gesuch_id,
                    adresse_type: options.adresse_type,
                    inform_types: options.inform_types
                });
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         * Delete gesuch record
         *
         * @param {Object} store The vuex store
         * @param {Gesuch} gesuch A gesuch entity record
         */
        async destroyGesuch({ commit }, gesuch) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await gesuch.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         * request an extension of the commitment
         *
         * @param {Object} store The vuex store
         * @param {Gesuch} gesuch A gesuch entity record
         */
        async extensionRequest({ commit }, options = {}) {
            let res = null;
            try {
                res = await api().post('/gesuch/extensionRequest', {
                    gesuch_id: options.gesuch_id
                });
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         * get Förder... as PDF
         *
         * @param {Object} store The vuex store
         * @param {Gesuch} gesuch A gesuch entity record
         */
        async getFoerderDok({ commit }, options = {}) {
            let res = null;
            try {
                if (options.type === 'foerderbedingungen') {
                    res = await api().post('/gesuch/getFoerderbedingungen', {
                        gesuch_id: options.gesuch_id
                    });
                } else if (options.type === 'foerdersaetze') {
                    res = await api().post('/gesuch/getFoerdersaetze', {
                        gesuch_id: options.gesuch_id
                    });
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        }
    }
};
