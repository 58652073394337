/**
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';

export class Ort extends BaseModel {
    defaults() {
        return {
            id: null,
            ort: null,
            ort_kurz: null,
            plz: null,
            ordnungsnr_post: null,
            plz_typ: null,
            kanton_id: null,
            land_id: null,

            //view fields
            kanton_kuerzel: null,
            kanton: null,
            land: null,
            gemeinde_bfs: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    get plz_ort() {
        return `${this.plz} ${this.ort}`;
    }

    get plz_ort_gemeinde() {
        return `${this.plz} ${this.ort} (${this.gemeinde_bfs})`;
    }

    entityName() {
        return 'ort';
    }
}
