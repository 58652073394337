/**
 * Represents the zeus.apollon_fk_conf.conf.objekte[...].rahmen value object - the Foerderkategorie config
 * json structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model } from 'vue-mc';
import { ProjektFeld } from './ProjektFeld';

export class ObjektRahmen extends Model {
    defaults() {
        return {
            rahmen_ident: null,
            label: null,
            zeus_label: null,
            einreichung: null,
            einreichung_from: null,
            einreichung_to: null,
            abschluss: null,
            abschluss_from: null,
            abschluss_to: null,
            hilfetext: null,
            felder: {}
        };
    }

    mutations() {
        return {
            felder: (val) => {
                if (val) {
                    for (const key in val) {
                        val[key] = new ProjektFeld(val[key]);
                    }
                }
                return val;
            }
        };
    }
}
