<template>
    <div>
        <v-toolbar flat color="primary white--text" class="colored-toolbar">
            <v-toolbar-title>
                <v-icon left color="white">fas fa-balance-scale fa-fw</v-icon>
                Gesuch erstellen
            </v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-stepper v-model="step">
                <v-stepper-header>
                    <v-stepper-step step="1" :complete="step > 1">{{ $t('choose_kanton') }}</v-stepper-step>
                    <v-stepper-step step="2" :complete="step > 2">{{ $t('choose_fk') }}</v-stepper-step>
                    <v-stepper-step step="3" :complete="step > 3">{{ $t('choose_role') }}</v-stepper-step>
                    <v-stepper-step v-if="rolle && rolle !== 'kontakt'" step="4" :complete="step > 4">
                        {{ $t('choose_org') }}
                    </v-stepper-step>
                    <v-stepper-step :step="rolle && rolle !== 'kontakt' ? 5 : 4">{{ $t('confirm') }}</v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content :step="1">
                        <StepKanton v-model="kanton" @next="step = 2" />
                    </v-stepper-content>
                    <v-stepper-content :step="2">
                        <StepFK v-model="apollonConfFk" :kanton="kanton" @next="step = 3" @back="step = 1" />
                    </v-stepper-content>
                    <v-stepper-content :step="3">
                        <StepRolle v-model="rolle" :apollon-conf-fk="apollonConfFk" @next="step = 4" @back="step = 2" />
                    </v-stepper-content>
                    <v-stepper-content v-if="rolle && rolle !== 'kontakt'" :step="4">
                        <StepOrganisation v-model="organisation" :rolle="rolle" @next="step = 5" @back="step = 3" />
                    </v-stepper-content>
                    <v-stepper-content :step="rolle && rolle !== 'kontakt' ? 5 : 4">
                        <StepBestaetigung
                            :kanton="kanton"
                            :apollon-conf-fk="apollonConfFk"
                            :rolle="rolle"
                            :organisation="organisation"
                            @create="onCreateGesuchBtn"
                            @back="step = rolle && rolle !== 'kontakt' ? 4 : 3"
                        />
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-container>
        <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate />
        </v-overlay>
        <ProfileDialog v-model="showProfileDialog" @close="closeProfileDialog" @cancel="cancelProfileDialog" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { errToStr } from 'js/lib/helpers.js';
import StepKanton from './CreateStepKanton.vue';
import StepFK from './CreateStepFoerderkategorie.vue';
import StepRolle from './CreateStepRolle.vue';
import StepOrganisation from './CreateStepOrganisation.vue';
import StepBestaetigung from './CreateStepBestaetigung.vue';
import ProfileDialog from 'js/components/complete-profile-first-dlg.vue';

export default {
    components: {
        StepKanton,
        StepFK,
        StepRolle,
        StepBestaetigung,
        StepOrganisation,
        ProfileDialog
    },
    data: function () {
        return {
            step: 1,
            kanton: null,
            apollonConfFk: null,
            loading: false,
            rolle: null,
            organisation: null,
            showProfileDialog: false
        };
    },
    computed: {},
    mounted() {
        if (!this.$store.getters['benutzer/profileComplete']) {
            this.showProfileDialog = true;
        }
    },
    methods: {
        ...mapActions('gesuch', ['createGesuch']),
        async onCreateGesuchBtn() {
            this.loading = true;
            try {
                let gesuch = await this.createGesuch({
                    rolle: this.rolle,
                    org_id: this.organisation ? this.organisation.id : null,
                    apollon_conf_fk_id: this.apollonConfFk ? this.apollonConfFk.id : null
                });
                this.loading = false;
                this.$toast({ message: this.$t('gesuch_erstellt', { nr: gesuch.nummer }) });
                this.$router.push({ name: 'gesuch', params: { nr: gesuch.nummer } });
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        cancelProfileDialog() {
            this.showProfileDialog = false;
            this.$router.go(-1);
        },
        closeProfileDialog() {
            this.showProfileDialog = false;
        }
    }
};
</script>
