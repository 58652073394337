/**
 * Store module: news
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { News, NewsCollection } from 'js/models/News';
import { instance as api } from 'js/lib/api';

export default {
    namespaced: true,
    state: {
        loading: false,
        loadingCounter: 0
    },
    mutations: {
        loading(state, loading) {
            if (loading) {
                state.loadingCounter++;
            } else {
                state.loadingCounter = Math.max(0, state.loadingCounter - 1);
            }
            state.loading = state.loadingCounter > 0;
        }
    },
    actions: {
        /**
         *
         * @param {Object} store The vuex store
         * @param {Object} options Future options like query params, paging etc.
         */
        async fetchNews({ commit }, options = {}) {
            let list = new NewsCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        /**
         * Update news data
         *
         * @param {News} news A News entity record
         */
        async storeNews({ commit }, news) {
            commit('loading', true, { root: true });
            try {
                await news.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        /**
         * Loads / creates a News entity.
         *
         * If id is 'new', a new one will be created, otherwise loaded from the backend.
         *
         * @param {Object} store The vuex store
         * @param {Number|String} id The id to load, or 'new'
         */
        async loadNewsEntry({ commit }, options) {
            let news = null;
            try {
                commit('loading', true, { root: true });
                if (!options.id || options.id === 'new') {
                    return new News();
                } else {
                    news = new News({ id: options.id });
                }
                await news.fetch();
            } finally {
                commit('loading', false, { root: true });
            }
            return news;
        },

        /**
         * Delete news record
         *
         * @param {News} news An News entity record
         */
        async destroyNews({ commit }, news) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await news.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        async fetchNewsUnauth({ commit }, options) {
            let list = new NewsCollection();
            try {
                commit('loading', true, { root: true });
                let res = await api().post('/news/getNews', {
                    kanton: options.kanton
                });
                list.add(res.data.records);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        }
    }
};
