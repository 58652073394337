/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';

export class Foerderkategorie extends BaseModel {
    defaults() {
        return {
            id: null,
            aktiv: null,
            foerderkategorie_gruppe_id: null,
            reihenfolge: null,
            foerderkategorie: null,
            foerderkategorie_kurz: null,
            foerderkategorie_gruppe: null,
            bemerkung: null,
            gesuchsvorlage_id: null,
            kuerzel_gesuchsnummer: null,
            gesuchsvorlage: null,
            hilfetext: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'foerderkategorie';
    }
}
