<template>
    <v-select
        v-bind="{ ...$props, ...$attrs }"
        autocomplete="new-password"
        :value="value"
        :readonly="readOnly"
        :items="gemeindeCollection ? gemeindeCollection.models : []"
        :loading="gemeindeIsLoading"
        item-text="gemeinde"
        item-value="gemeinde_id"
        :placeholder="$t('ort_waehlen')"
        :clearable="!readOnly"
        clear-icon="fas fa-times"
        @input="onSelect"
    >
    </v-select>
</template>

<script>
import { OrtGemeinde } from 'js/models/OrtGemeinde';
import { LookupCollection } from 'js/models/BaseCollection';

/**
 * Gemeinde-Select by Ort:
 *
 * loads Gemeinde from /lookup/ortGemeinde,
 * filtered by a specific ort-id (property)
 */
export default {
    props: {
        value: {
            type: Number,
            default: null
        },
        // Ort ID must be set, else no Gemeinden are shown
        ortId: {
            type: Number,
            default: null
        },
        readOnly: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            gemeindeCollection: null,
            gemeindeIsLoading: false
        };
    },
    watch: {
        ortId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadGemeinden({ ort_id: newVal || -1 });
            }
        }
    },
    created() {
        if (this.ortId) {
            this.loadGemeinden({ ort_id: this.ortId });
        } else if (this.value) {
            this.loadGemeinden({ gemeinde_id: this.value });
        }
    },

    methods: {
        onSelect(value) {
            this.$emit('input', value);
        },

        async loadGemeinden(query) {
            this.gemeindeIsLoading = true;
            if (!this.gemeindeCollection) {
                this.gemeindeCollection = new LookupCollection(null, { model: OrtGemeinde });
            }
            await this.gemeindeCollection.query(query);
            if (this.gemeindeCollection.models.length === 1) {
                this.$emit('input', this.gemeindeCollection.first().gemeinde_id);
            }
            this.gemeindeIsLoading = false;
        }
    }
};
</script>
