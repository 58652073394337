<template>
    <v-container fluid>
        <v-alert v-if="kontakteConfig.infotext" type="info" :icon="false" border="left" colored-border elevation="2">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="kontakteConfig.infotext.replace('\n', '<br />')"> </span>
        </v-alert>
        <v-row>
            <v-col v-if="isVisible(gesuch, kontakteConfig.eigentuemer)" cols="12" sm="6" md="4" xl="3">
                <GesuchAdresseCard
                    :gesuch="gesuch"
                    :read-only="readOnly"
                    kontakt-ident="eigentuemer"
                    :edit="$route.params.edit_type === 'eigentuemer'"
                    @gesuchdatachanged="onGesuchDataChanged"
                />
            </v-col>

            <v-col v-if="isVisible(gesuch, kontakteConfig.unternehmer)" cols="12" sm="6" md="4" xl="3">
                <GesuchAdresseCard
                    :gesuch="gesuch"
                    :read-only="readOnly"
                    kontakt-ident="unternehmer"
                    :edit="$route.params.edit_type === 'unternehmer'"
                    @gesuchdatachanged="onGesuchDataChanged"
                />
            </v-col>
            <v-col v-if="isVisible(gesuch, kontakteConfig.kontakt)" cols="12" sm="6" md="4" xl="3">
                <GesuchAdresseCard
                    :gesuch="gesuch"
                    :read-only="readOnly"
                    kontakt-ident="kontakt"
                    :edit="$route.params.edit_type === 'kontakt'"
                    @gesuchdatachanged="onGesuchDataChanged"
                />
            </v-col>
            <v-col v-if="isVisible(gesuch, kontakteConfig.bevollmaechtigter)" cols="12" sm="6" md="4" xl="3">
                <GesuchAdresseCard
                    :gesuch="gesuch"
                    :read-only="readOnly"
                    kontakt-ident="bevollmaechtigter"
                    :edit="$route.params.edit_type === 'bevollmaechtigter'"
                    @gesuchdatachanged="onGesuchDataChanged"
                />
            </v-col>
        </v-row>
        <v-card-actions>
            <v-spacer />
            <v-btn class="mt-2" color="action" :loading="loading" @click="$emit('next')">
                <v-icon left>fas fa-arrow-right</v-icon>
                {{ $t('next_step') }}
            </v-btn>
        </v-card-actions>
    </v-container>
</template>

<script>
/**
 * This component shows the Gesuch-Adressen. It depends on Gesuch.config.kontakte,
 * which contains the display configuration for this view.
 */
import GesuchAdresseCard from 'js/components/gesuch-adresse-card.vue';
import { SICHTBARKEIT } from 'js/models/apollon_conf_fk/KontaktConfig';
import { Gesuch, PHASE } from 'js/models/Gesuch';

export default {
    components: {
        GesuchAdresseCard
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        gesuch: {
            type: Gesuch,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {};
    },
    computed: {
        kontakteConfig: function () {
            return this.gesuch.config.config.kontakte || {};
        }
    },
    methods: {
        isVisible(gesuch, kontaktConfig) {
            kontaktConfig = kontaktConfig || {};
            if (gesuch.phase === PHASE.EINREICHUNG) {
                return (
                    kontaktConfig.sichtbarkeit === SICHTBARKEIT.EINREICHUNG ||
                    kontaktConfig.sichtbarkeit === SICHTBARKEIT.IMMER
                );
            } else if (gesuch.phase === PHASE.ABSCHLUSS) {
                return (
                    kontaktConfig.sichtbarkeit === SICHTBARKEIT.ABSCHLUSS ||
                    kontaktConfig.sichtbarkeit === SICHTBARKEIT.IMMER
                );
            } else if (gesuch.phase === PHASE.FERTIG) {
                return (
                    kontaktConfig.sichtbarkeit === SICHTBARKEIT.EINREICHUNG ||
                    kontaktConfig.sichtbarkeit === SICHTBARKEIT.ABSCHLUSS ||
                    kontaktConfig.sichtbarkeit === SICHTBARKEIT.IMMER
                );
            }
            return false;
        },

        onGesuchDataChanged(gesuch) {
            // just inform the parent container about the change:
            this.$emit('gesuchdatachanged', gesuch);
        }
    }
};
</script>

<style scoped></style>
