<template>
    <v-container class="welcome-container">
        <v-row justify="center">
            <v-col cols="12">
                <v-card class="trans-bg mx-auto" max-width="960px">
                    <v-img
                        :src="WelcomeTitle"
                        class="white--text align-start"
                        gradient="to bottom, rgb(55 71 165 / 50%) 0%, rgb(132 141 199 / 0%) 30%, rgba(255,255,255,0) 100%"
                    >
                        <v-card-title class="pa-0 mt-3">
                            <img class="ml-2 mr-1" :src="tgWappen" width="36" alt="Wappen Thurgau" />
                            <img class="mr-3" :src="shWappen" width="36" alt="Wappen Thurgau" />
                            <span class="text-h4" style="text-shadow: 0 1px 3px black;">{{ $t('welcome') }}</span>
                        </v-card-title>
                    </v-img>

                    <v-card-actions>
                        <v-btn color="action white--text" :to="{ name: 'login' }">{{ $t('login') }}</v-btn>
                        <router-link class="ml-3" :to="{ name: 'register' }">
                            noch kein Login? Registrieren Sie sich hier.
                        </router-link>
                    </v-card-actions>

                    <div class="d-flex flex-no-wrap justify-space-between">
                        <v-card-text>
                            <p>
                                Wenn Sie ein Gesuch für einen Beitrag aus dem Energieförderprogramm stellen wollen, sind
                                Sie hier genau richtig. Seit diesem Jahr können Fördergesuche nur noch elektronisch über
                                dieses Energieförderportal eingereicht werden.
                                <strong>Eine Einreichung in Papierform ist nicht mehr möglich.</strong>
                            </p>
                            <p>
                                Mit dem Energieförderportal kann die Bearbeitungszeit verkürzt werden, es stehen Ihnen
                                punktuelle Erläuterungen zur Verfügung und Sie haben jederzeit Einblick in den aktuellen
                                Stand Ihres Gesuches.
                            </p>
                            <p>
                                Das Fördergesuch kann von der Eigentümerschaft, einem Unternehmen oder von einem
                                Bevollmächtigten erstellt werden. Definitiv einreichen kann das Gesuch jedoch nur die
                                Eigentümerschaft oder der Bevollmächtigte. Die Einreichung muss mit einem persönlichen
                                Passwort bestätigt werden. Die Daten befinden sich auf einem sicheren Server der
                                Verwaltung des Kantons Thurgau und werden nur im Rahmen des Energieförderprogramms
                                verwendet.
                            </p>

                            <p class="mb-0 mt-2">
                                <strong>Bestehende Fördergesuche vor 2021:</strong>
                            </p>
                            <p>
                                Die Ausführungsbestätigungen von Gesuchen, die vor 2021 eingereicht worden sind, können
                                weiterhin in Papierform eingereicht werden. Eine Einreichung über das
                                Energieförderportal ist jedoch ebenfalls möglich. Dabei ist das Vorgehen wie folgt:
                                Senden Sie bitte eine E-Mail an
                                <kp-link mailto href="energie@tg.ch?Subject=Projektabschluss%20F%C3%B6rdergesuch">
                                    energie@tg.ch
                                </kp-link>
                                (Kanton TG) bzw.
                                <kp-link
                                    mailto
                                    href="energiefachstelle@sh.ch?Subject=Projektabschluss%20F%C3%B6rdergesuch"
                                >
                                    energiefachstelle@sh.ch
                                </kp-link>
                                (Kanton SH) mit dem Betreff "Projektabschluss Fördergesuch" gefolgt von der
                                Gesuchsnummer. Geben Sie im Text Ihre vollständige Adresse an. Wir werden anschliessend
                                das Gesuch für Sie freischalten, so dass Sie den Projektabschluss einreichen können.
                            </p>
                        </v-card-text>
                        <v-avatar class="ma-3" size="125" tile> </v-avatar>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center" class="news">
            <v-col cols="12">
                <v-card class="trans-bg mx-auto" max-width="960px">
                    <v-card-title>
                        <v-icon left color="orange">fas fa-exclamation-triangle</v-icon> {{ $t('actual_news') }}
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import link from 'js/components/link.vue';
import { WelcomeSh, WelcomeTg, WelcomeTitle, TgWappenSvg, ShWappenSvg } from 'js/lib/images.js';

export default {
    components: {
        'kp-link': link
    },
    data: function () {
        return {
            WelcomeSh,
            WelcomeTg,
            WelcomeTitle,
            tgWappen: TgWappenSvg,
            shWappen: ShWappenSvg
        };
    }
};
</script>

<style lang="scss" scoped>
.img-rights {
    font-size: 10pt;
    color: #333;
}
.news {
    h2 {
        font-size: 1.1em;
        color: #333;
    }
}
</style>
