<template>
    <v-card tile height="260px" :loading="loading">
        <div v-if="adresse">
            <v-card-title v-if="adresse">
                <div v-if="adresse.typ === 'organisation'">{{ adresse.organisation_name }}</div>
                <div v-else>
                    {{ adresse.anrede }} {{ adresse.display_name }} {{ adresse.vorname }} <br />{{ adresse.anrede2 }}
                    {{ adresse.nachname2 }} {{ adresse.vorname2 }}
                </div>
                <v-spacer />
                <v-btn icon x-small color="primary" :disabled="!canEdit" @click.stop="editAddress(adresse)">
                    <v-icon x-small>fas fa-pen</v-icon>
                </v-btn>
                <v-btn icon x-small color="primary" :disabled="!canReassign" @click.stop="replaceAdress">
                    <v-icon x-small>fas fa-search</v-icon>
                </v-btn>
                <v-btn icon x-small color="stop" :disabled="!canRemove" @click.stop="confirmDelDlg = true">
                    <v-icon x-small>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>{{ config.label || 'LABEL' }}</v-card-subtitle>
            <v-card-text v-if="adresse">
                <div v-if="adresse.adresszusatz">{{ adresse.adresszusatz }}</div>
                <div v-if="adresse.typ === 'organisation'">
                    {{ adresse.anrede }} {{ adresse.nachname }} {{ adresse.vorname }}
                </div>
                <div v-if="adresse.adresse">{{ adresse.adresse }}</div>
                <div v-if="adresse.postfach">{{ adresse.postfach }}</div>
                <div v-if="adresse.plz_ort">
                    {{ adresse.laendercode && adresse.laendercode !== 'CH' ? adresse.laendercode + '-' : ''
                    }}{{ adresse.plz_ort }}
                </div>
                <div v-if="adresse.tel_1">
                    <v-icon x-small class="pr-1">fas fa-phone</v-icon>
                    <kp-link tel :href="adresse.tel_1">
                        {{ formattedPhoneNumber(adresse.tel_1) }}
                    </kp-link>
                    {{ adresse.tel_2 ? '/' : '' }}
                    <kp-link v-if="adresse.tel_2" tel :href="adresse.tel_2">
                        {{ formattedPhoneNumber(adresse.tel_2) }}
                    </kp-link>
                </div>
                <div v-else-if="adresse.organisation_tel_1">
                    <v-icon x-small class="pr-1">fas fa-phone</v-icon>
                    <kp-link tel :href="adresse.organisation_tel_1">
                        {{ formattedPhoneNumber(adresse.organisation_tel_1) }}
                    </kp-link>
                    {{ adresse.organisation_tel_2 ? '/' : '' }}
                    <kp-link v-if="adresse.organisation_tel_2" tel :href="adresse.organisation_tel_2">
                        {{ formattedPhoneNumber(adresse.organisation_tel_2) }}
                    </kp-link>
                </div>
                <div v-if="adresse.email && adresse.email !== ''">
                    <v-icon x-small class="pr-1">fas fa-at</v-icon><kp-link mailto :href="adresse.email" />
                </div>
                <div v-else-if="adresse.organisation_email">
                    <v-icon x-small class="pr-1">fas fa-at</v-icon><kp-link mailto :href="adresse.organisation_email" />
                </div>
            </v-card-text>
        </div>
        <div v-else>
            <v-card-subtitle>{{ config.label || 'LABEL' }}</v-card-subtitle>
            <v-card-text>
                <v-btn color="action" :disabled="loading || readOnly" @click="openAdressDlg">
                    <v-icon left>fas fa-plus</v-icon>
                    {{ config.label || 'LABEL' }} {{ $t('assign') }}
                </v-btn>
                <div v-if="config.hilfetext" class="d-flex mt-4">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div class="grey--text" v-html="config.hilfetext" />
                </div>
            </v-card-text>
        </div>
        <AddressDlg
            v-if="showAddressDlg"
            v-model="showAddressDlg"
            :loading="loading"
            :ident="kontaktIdent"
            :gesuch="gesuch"
            :adresse="unsavedAddress"
            @close="closeAdressDlg"
            @assign="assignAdresses"
            @new-address="onNewAddress"
            @new-contact="onNewContact"
            @oganisation-selected="onOrgSelected"
        />
        <v-dialog v-if="showAddAddressDlg" v-model="showAddAddressDlg" max-width="1000">
            <v-card>
                <AdresseForm
                    :new-address="newAddress"
                    :gesuch-address="gesuchAdresse"
                    :avoid-type-edit="avoidAlterAdressType"
                    :avoid-benutzer-id-edit="!canReassign"
                    @close="onNewAddressClose"
                />
            </v-card>
        </v-dialog>
        <v-dialog v-if="showAddContactDlg" v-model="showAddContactDlg" max-width="1000">
            <v-card>
                <AdresseForm
                    :new-address="newContact"
                    avoid-type-edit
                    reduced-contact-data
                    @close="onNewContactClose"
                />
            </v-card>
        </v-dialog>
        <ConfirmDlg
            v-model="confirmDelDlg"
            :title="$t('bestaetigung')"
            :text="$t('really_remove_address')"
            @yes="confirmDestroy"
        />
    </v-card>
</template>

<script>
/**
 * Card for displaying / selecting an Address on the Gesuch
 */
import { Gesuch } from 'js/models/Gesuch';
import { formatTelefonNumber } from 'js/lib/helpers.js';
import { Adresse } from 'js/models/Adresse';
import link from 'js/components/link.vue';
import { mapState } from 'vuex';
import ConfirmDlg from 'js/components/confirm-dlg.vue';
import AdresseForm from 'js/views/auth/adressen/AdressenForm.vue';
import AddressDlg from 'js/components/gesuch-adresse-select-dlg.vue';
export default {
    components: {
        'kp-link': link,
        AddressDlg,
        AdresseForm,
        ConfirmDlg
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        gesuch: {
            type: Gesuch,
            required: true
        },
        kontaktIdent: {
            type: String,
            required: true
        },
        edit: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            orgAdresse: null,
            showAddressDlg: false,
            showAddAddressDlg: false,
            showAddContactDlg: false,
            confirmDelDlg: false,
            newAddress: null,
            newContact: null,
            gesuchAdresse: null,
            adresse: null,
            unsavedAddress: null,
            linkedBenutzerId: null
        };
    },
    computed: {
        config: function () {
            return this.gesuch.config.config.kontakte[this.kontaktIdent] || {};
        },
        gesuchData: function () {
            return this.gesuch.data;
        },
        addressData: function () {
            if (this.gesuchData) {
                return this.gesuchData.adressen[this.kontaktIdent] || {};
            }
            return {};
        },
        ...mapState('benutzer', {
            benutzer: 'record'
        }),

        /**
         * Address can be re-assigned (remove/add) if (AND):
         * - the Gesuch is NOT read-only
         */
        canReassign() {
            if (this.readOnly) {
                return false;
            }
            if (this.addressData.adresse && this.addressData.adresse.benutzer_id === this.benutzer.id) {
                let occurences = this.getGesuchAdressenBenutzerIds().filter((item) => item === this.benutzer.id);
                return occurences.length > 1;
            }
            return true;
        },

        /**
         * Address can be removed only if (AND):
         * - the Gesuch is NOT read-only
         */
        canRemove() {
            return this.canReassign;
        },

        canEdit() {
            return !this.readOnly;
        },

        avoidAlterAdressType() {
            return this.kontaktIdent === 'unternehmer' || this.kontaktIdent === 'kontakt';
        }
    },
    watch: {
        addressData(val) {
            this.adresse = val.adresse ? new Adresse(val.adresse) : null;
            this.linkedBenutzerId = val.adresse ? val.adresse.benutzer_id : null;
        }
    },

    created() {
        this.adresse = this.addressData.adresse ? new Adresse(this.addressData.adresse) : null;
        this.linkedBenutzerId = this.addressData.adresse ? this.addressData.adresse.benutzer_id : null;
        // If on mounting the edit flag is set to true, we open the address for editing:
        // then we open the requested address for editing:
        if (this.edit === true && this.adresse) {
            this.editAddress(this.adresse);
        }
    },
    methods: {
        openAdressDlg() {
            this.unsavedAddress = this.adresse;
            this.showAddressDlg = true;
        },
        closeAdressDlg() {
            this.showAddressDlg = false;
        },
        async assignAdresses(address, contactAddress) {
            if (address) {
                this.gesuch.data.adressen[this.kontaktIdent].adresse = address;
                if (address.typ === Adresse.TYP_ORGANISATION && contactAddress) {
                    this.gesuch.data.adressen[this.kontaktIdent].adresse.anrede = contactAddress.anrede;
                    this.gesuch.data.adressen[this.kontaktIdent].adresse.vorname = contactAddress.vorname;
                    this.gesuch.data.adressen[this.kontaktIdent].adresse.nachname = contactAddress.nachname;
                    this.gesuch.data.adressen[this.kontaktIdent].adresse.email = contactAddress.email;
                    this.gesuch.data.adressen[this.kontaktIdent].adresse.tel_1 = contactAddress.tel_1;
                    this.gesuch.data.adressen[this.kontaktIdent].adresse.tel_2 = contactAddress.tel_2;
                    this.gesuch.data.adressen[this.kontaktIdent].adresse.benutzer_id = contactAddress.benutzer_id;
                }
                this.adresse = this.addressData.adresse;
            }
            this.$emit('gesuchdatachanged', this.gesuch);
            this.closeAdressDlg();
        },
        onNewAddress() {
            this.newAddress = new Adresse();
            if (this.kontaktIdent === 'unternehmer') {
                this.newAddress.typ = 'organisation';
            } else if (this.kontaktIdent === 'kontakt') {
                this.newAddress.typ = 'person';
            }
            this.gesuchAdresse = null;
            this.showAddAddressDlg = true;
        },
        onNewContact() {
            this.newContact = new Adresse();
            this.newContact.typ = 'person';
            this.showAddContactDlg = true;
        },
        onNewAddressClose(address) {
            this.showAddAddressDlg = false;
            if (address) {
                if (this.newAddress && this.newAddress.typ === 'organisation') {
                    this.unsavedAddress = address;
                } else {
                    this.assignAdresses(address);
                }
            }
        },
        onNewContactClose(contact) {
            this.showAddContactDlg = false;
            if (contact) {
                this.assignAdresses(this.orgAdresse, contact);
            }
        },
        replaceAdress() {
            this.openAdressDlg();
        },
        editAddress(adresse) {
            this.gesuchAdresse = adresse;
            this.newAddress = null;
            this.showAddAddressDlg = true;
        },
        async confirmDestroy() {
            this.gesuch.data.adressen[this.kontaktIdent].adresse = null;
            this.$emit('gesuchdatachanged', this.gesuch);
            this.adresse = null;
            this.confirmDelDlg = false;
            this.loading = false;
        },
        formattedPhoneNumber(value) {
            return formatTelefonNumber(value);
        },

        getGesuchAdressenBenutzerIds() {
            let res = [];
            if (this.gesuch.data.adressen.eigentuemer.adresse) {
                res.push(this.gesuch.data.adressen.eigentuemer.adresse.benutzer_id);
            }
            if (this.gesuch.data.adressen.unternehmer.adresse) {
                res.push(this.gesuch.data.adressen.unternehmer.adresse.benutzer_id);
            }
            if (this.gesuch.data.adressen.kontakt.adresse) {
                res.push(this.gesuch.data.adressen.kontakt.adresse.benutzer_id);
            }
            if (this.gesuch.data.adressen.bevollmaechtigter.adresse) {
                res.push(this.gesuch.data.adressen.bevollmaechtigter.adresse.benutzer_id);
            }
            return res;
        },
        onOrgSelected(org) {
            this.orgAdresse = org;
        }
    }
};
</script>
