<template>
    <v-container fluid>
        <v-card class="mb-2">
            <v-card-title>{{ $t('gesuch_summary') }}</v-card-title>
            <v-card-text>
                <v-btn :disabled="loading" @click="downloadpdf">
                    <v-icon left>fas fa-download</v-icon>
                    {{ $t('download_pdf') }}
                </v-btn>
            </v-card-text>
        </v-card>
        <v-card v-if="!readOnly" class="mb-2">
            <v-card-title>
                {{ gesuch.phase === PHASE.EINREICHUNG ? $t('check_gesuch') : $t('check_abschluss') }}
            </v-card-title>
            <v-card-text>
                <v-btn
                    v-if="!checkSuccess"
                    color="action white--text"
                    :loading="checkLoading"
                    class="mb-2"
                    @click="checkGesuch"
                >
                    <v-icon left>fas fa-tasks</v-icon>
                    {{ gesuch.phase === PHASE.EINREICHUNG ? $t('check_gesuch') : $t('check_abschluss') }}
                </v-btn>
                <template v-if="checkErrors.length === 0 && checkSuccess && foerderbedingungenValid">
                    <v-alert type="success" border="left" colored-border elevation="2">
                        {{
                            gesuch.phase === PHASE.EINREICHUNG
                                ? $t('gesuch_complete_msg')
                                : $t('abschluss_complete_msg')
                        }}
                    </v-alert>
                </template>
                <template v-if="!foerderbedingungenValid && gesuch.status === STATUS.NEW">
                    <v-alert type="error" border="left" colored-border elevation="2">
                        <div v-html="$t('foerderbedingungen_changed')"></div>
                    </v-alert>
                </template>
                <template v-if="Object.keys(checkErrors).length > 0">
                    <v-alert type="error" border="left" colored-border elevation="2">
                        {{ $t('existing_errors_msg') }}:
                        <v-list v-for="(error, key) in checkErrors" :key="key" dense>
                            <v-subheader>
                                {{ error.errorGroup }}
                                <!-- Add navigation buttons, depending on the error group -->
                                <v-btn
                                    v-if="error.liegenschaft_id"
                                    small
                                    icon
                                    :loading="verifyLoading || loading || saving"
                                    :to="{
                                        name: 'gesuch-step',
                                        params: {
                                            nr: gesuch.nummer,
                                            step: 'liegenschaften',
                                            edit_id: error.liegenschaft_id
                                        }
                                    }"
                                >
                                    <v-icon small color="info">fas fa-arrow-circle-right</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="error.errorGroup === 'Liegenschaft'"
                                    small
                                    icon
                                    :loading="verifyLoading || loading || saving"
                                    :to="{
                                        name: 'gesuch-step',
                                        params: {
                                            nr: gesuch.nummer,
                                            step: 'liegenschaften'
                                        }
                                    }"
                                >
                                    <v-icon small color="info">fas fa-arrow-circle-right</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="error.errorGroup === 'Projekt'"
                                    small
                                    icon
                                    :loading="verifyLoading || loading || saving"
                                    :to="{
                                        name: 'gesuch-step',
                                        params: {
                                            nr: gesuch.nummer,
                                            step: 'projekt'
                                        }
                                    }"
                                >
                                    <v-icon small color="info">fas fa-arrow-circle-right</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="error.errorGroup === 'Dokumente'"
                                    small
                                    icon
                                    :loading="verifyLoading || loading || saving"
                                    :to="{
                                        name: 'gesuch-step',
                                        params: {
                                            nr: gesuch.nummer,
                                            step: 'dokumente'
                                        }
                                    }"
                                >
                                    <v-icon small color="info">fas fa-arrow-circle-right</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="error.errorGroup === 'Kontoinformation'"
                                    small
                                    icon
                                    :loading="verifyLoading || loading || saving"
                                    :to="{
                                        name: 'gesuch-step',
                                        params: {
                                            nr: gesuch.nummer,
                                            step: 'kontoinformation'
                                        }
                                    }"
                                >
                                    <v-icon small color="info">fas fa-arrow-circle-right</v-icon>
                                </v-btn>
                            </v-subheader>
                            <v-list-item v-for="(e, i) in error.errors" :key="i">
                                {{ e.text }}
                                <v-btn
                                    v-if="e.type === 'missing_address'"
                                    small
                                    icon
                                    :loading="verifyLoading || loading || saving"
                                    :to="{ name: 'gesuch', params: { nr: gesuch.nummer } }"
                                >
                                    <v-icon small color="info">fas fa-arrow-circle-right</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="e.type === 'missing_address_field'"
                                    small
                                    icon
                                    :loading="verifyLoading || loading || saving"
                                    :to="{
                                        name: 'gesuch',
                                        params: { nr: gesuch.nummer, edit_type: e.context }
                                    }"
                                >
                                    <v-icon small color="info">fas fa-arrow-circle-right</v-icon>
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-alert>
                </template>
            </v-card-text>
        </v-card>
        <v-card v-if="showEinreichung && !readOnly && (isOwner || isRepresentative)" class="mb-2">
            <v-form ref="commitform" v-model="formvalid" lazy-validation>
                <v-card-title>
                    {{ gesuch.phase === PHASE.EINREICHUNG ? $t('commit_gesuch') : $t('commit_abschluss') }}
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="!checkSuccess" type="error" border="left" colored-border>
                        {{ gesuch.phase === PHASE.EINREICHUNG ? $t('gesuch_commit_msg') : $t('abschluss_commit_msg') }}
                    </v-alert>
                    <template v-if="checkSuccess">
                        <v-alert type="warning" border="left" colored-border>
                            {{ $t('no_edit_after_commit_msg') }}
                        </v-alert>
                        <p>
                            <strong>
                                {{
                                    gesuch.phase === PHASE.EINREICHUNG
                                        ? $t('gesuch_password_confirm_msg')
                                        : $t('abschluss_password_confirm_msg')
                                }}
                            </strong>
                        </p>
                        <kp-textfield
                            v-model="password"
                            :error-messages="passwordError"
                            type="password"
                            :label="$t('pw')"
                            @focus="passwordError = null"
                        />
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-checkbox
                        v-model="acceptFoerderbedingungen"
                        :label="$t('foerderbedingungen_accepted')"
                        :rules="[(v) => !!v || $t('accept_foerderbedingungen')]"
                        required
                    ></v-checkbox>
                    <v-spacer />
                    <v-btn
                        color="action white--text"
                        :disabled="!checkSuccess"
                        :loading="verifyLoading || loading || saving"
                        @click="checkPassword"
                    >
                        <v-icon left>fas fa-envelope</v-icon>
                        {{ gesuch.phase === PHASE.EINREICHUNG ? $t('commit_gesuch') : $t('commit_abschluss') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <v-card v-if="showEinreichung && !readOnly">
            <v-card-title v-if="isOwner">{{ $t('delegate_gesuch_for_edit') }}</v-card-title>
            <v-card-title v-else>{{ $t('delegate_gesuch_for_commit') }}</v-card-title>
            <v-card-subtitle>
                <div v-if="isOwner">
                    {{ $t('delegate_explanation') }}
                </div>
                <div>
                    {{ $t('delegate_email_warning') }}
                </div>
            </v-card-subtitle>
            <v-card-text>
                <v-alert v-if="!checkSuccess" type="warning" border="left" colored-border>
                    {{ $t('gesuch_check_msg') }}
                </v-alert>
                <div class="d-flex">
                    <v-icon class="mr-2">far fa-hand-point-right</v-icon>
                    <v-select
                        v-model="selectedAddress"
                        style="max-width: 400px;"
                        :items="delegates"
                        item-text="text"
                        item-value="value"
                        :label="$t('delegate_to')"
                        clearable
                        clear-icon="fas fa-times"
                        @change="onDelegateAddressSelected"
                    >
                    </v-select>
                </div>
                <div v-if="checkSuccess">
                    {{ $t('inform_people') }}
                    <template v-for="(d, key) in delegates">
                        <v-checkbox
                            :key="key"
                            v-model="informAddresses"
                            dense
                            :readonly="delegateAddress ? d.value === delegateAddress : false"
                            :label="d.text"
                            :value="d.value"
                        ></v-checkbox>
                    </template>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="action white--text"
                    :disabled="!selectedAddress"
                    :loading="verifyLoading || loading || delegating"
                    @click="checkDelegate"
                >
                    <v-icon left>fas fa-envelope</v-icon>
                    {{ $t('gesuch_pass') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <ConfirmDlg
            v-model="confirmDelegateDlg"
            :title="$t('bestaetigung')"
            :text="$t('delegate_gesuch_msg')"
            :yes-text="$t('next')"
            :no-text="$t('cancel')"
            @yes="delegate"
        />
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { errToStr, formRules } from 'js/lib/helpers.js';
import ConfirmDlg from 'js/components/confirm-dlg.vue';
import { buildUrl } from 'js/lib/api';
import { PHASE, STATUS } from 'js/models/Gesuch.js';
import { Adresse } from 'js/models/Adresse.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        ConfirmDlg,
        KpTextfield
    },
    props: {
        gesuch: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            checkLoading: false,
            checkErrors: [],
            checkSuccess: false,
            password: null,
            passwordError: null,
            verifyLoading: false,
            selectedAddress: null,
            delegates: [],
            confirmDelegateDlg: false,
            eigentuemerAdresse: null,
            bevollmaechtigterAdresse: null,
            showEinreichung: false,
            informAddresses: [],
            informAddressesCommit: [],
            delegateAddress: null,
            acceptFoerderbedingungen: false,
            formRules,
            formvalid: false,
            saving: false,
            delegating: false,
            PHASE: PHASE,
            STATUS: STATUS,
            foerderbedingungenValid: true
        };
    },
    computed: {
        ...mapState({
            benutzer: 'benutzer'
        }),
        isOwner() {
            let res = false;
            if (this.eigentuemerAdresse) {
                res = this.benutzer.record.id === this.eigentuemerAdresse.benutzer_id;
                if (Array.isArray(this.benutzer.record.team_mitglieder)) {
                    res = res || this.benutzer.record.team_mitglieder.includes(this.eigentuemerAdresse.benutzer_id);
                }
            }
            return res;
        },
        isRepresentative() {
            let res = false;
            if (this.bevollmaechtigterAdresse) {
                res = this.benutzer.record.id === this.bevollmaechtigterAdresse.benutzer_id;
                if (Array.isArray(this.benutzer.record.team_mitglieder)) {
                    res =
                        res || this.benutzer.record.team_mitglieder.includes(this.bevollmaechtigterAdresse.benutzer_id);
                }
            }
            return res;
        }
    },
    created() {
        this.getDelegateAdresses();
        this.getEigentuemerAdresse();
        this.getBevollmaechtigterAdresse();
        this.showEinreichung = true;
    },
    methods: {
        ...mapActions('benutzer', ['verifyUser']),
        ...mapActions('gesuch', ['verifyGesuch', 'commitGesuch', 'getDelegates', 'delegateGesuch']),
        async checkGesuch() {
            this.checkErrors = [];
            this.checkSuccess = false;
            let me = this;
            this.checkLoading = true;
            try {
                let result = await this.verifyGesuch(this.gesuch.id);
                this.foerderbedingungenValid = result.foerderbedingungen;
                if (result.error && Object.keys(result.error).length > 0) {
                    this.checkErrors = result.error;
                } else {
                    me.checkSuccess = true;
                }
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            } finally {
                me.checkLoading = false;
            }
        },

        async getDelegateAdresses() {
            if (!this.isOwner) {
                const delegates = await this.getDelegates(this.gesuch.id);
                Object.keys(delegates.adressen).forEach((d) => {
                    let adr = Adresse.fromData(delegates.adressen[d].data);
                    let name = '';
                    if (adr.typ === 'organisation') {
                        name += adr.organisation_name;
                        if (adr.nachname || adr.vorname) {
                            name += ', ' + (adr.nachname || '') + ' ' + (adr.vorname || '');
                        }
                    } else {
                        name = (adr.nachname || '') + ' ' + (adr.vorname || '');
                    }
                    this.delegates.push({
                        text: delegates.adressen[d].label + ': ' + name,
                        value: {
                            id: adr.id,
                            type: d
                        }
                    });
                    this.informAddressesCommit.push({
                        id: adr.id,
                        type: d
                    });
                });
            }
        },

        async checkPassword() {
            if (this.$refs.commitform.validate()) {
                this.verifyLoading = true;
                this.passwordError = null;
                let verified = await this.verifyUser(this.password);
                if (verified) {
                    this.abschluss();
                } else {
                    this.passwordError = this.$t('wrong_password');
                }
                this.verifyLoading = false;
            }
        },

        async abschluss() {
            try {
                this.saving = true;
                let result = await this.commitGesuch({
                    gesuch_id: this.gesuch.id,
                    inform_types: this.informAddressesCommit
                });
                if (result.error && Object.keys(result.error).length > 0) {
                    this.$toast({ message: this.$t('gesuch_check_failed'), color: 'error' });
                    this.checkErrors = result.errors;
                } else {
                    this.$toast({ message: this.$t('succuessful_commit'), color: 'success', timeout: 10000 });
                    this.$emit('next');
                }
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            } finally {
                this.saving = false;
            }
        },

        checkDelegate() {
            if (this.selectedAddress.type !== 'eigentuemer' && !this.isOwner) {
                this.confirmDelegateDlg = true;
            } else {
                this.delegate();
            }
        },

        async delegate() {
            try {
                this.delegating = true;
                let result = await this.delegateGesuch({
                    gesuch_id: this.gesuch.id,
                    adresse_type: this.selectedAddress.type,
                    inform_types: this.informAddresses
                });
                if (result.error && Object.keys(result.error).length > 0) {
                    this.$toast({ message: this.$t('gesuch_check_failed'), color: 'error' });
                    this.checkErrors = result.errors;
                } else {
                    this.$toast({ message: this.$t('succuessful_delegation'), color: 'success' });
                    this.$emit('next');
                }
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            } finally {
                this.delegating = false;
            }
        },

        getEigentuemerAdresse() {
            this.eigentuemerAdresse = this.gesuch.data.adressen.eigentuemer.adresse
                ? this.gesuch.data.adressen.eigentuemer.adresse
                : null;
        },

        async getBevollmaechtigterAdresse() {
            this.bevollmaechtigterAdresse = this.gesuch.data.adressen.bevollmaechtigter.adresse
                ? this.gesuch.data.adressen.bevollmaechtigter.adresse
                : null;
        },

        onDelegateAddressSelected(item) {
            this.delegateAddress = item;
            this.informAddresses.push(item);
        },

        downloadpdf() {
            let url = buildUrl('/gesuch/downloadSummary', {
                gesuch_id: this.gesuch.id
            });
            window.open(url, 'apollon-dl');
        }
    }
};
</script>

<style scoped></style>
