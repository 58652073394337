/**
 * Routes: /gesuche/ and below
 *
 * Will me merged into the router's 'route' array
 */
import GesuchOverviewPanel from 'js/views/auth/gesuch/Overview.vue';
import GesuchCreatePanel from 'js/views/auth/gesuch/Create.vue';
import GesuchPanel from 'js/views/auth/gesuch/GesuchPanel.vue';

const routes = [
    {
        path: '/gesuche',
        name: 'gesuche',
        component: GesuchOverviewPanel,
        meta: {
            requiresAuth: true,
            title: 'Gesuche'
        }
    },
    {
        path: '/gesuche/erstellen',
        name: 'gesuch_erstellen',
        component: GesuchCreatePanel,
        meta: {
            requiresAuth: true,
            title: 'Gesuch erstellen'
        }
    },
    {
        path: '/gesuche/:nr',
        name: 'gesuch',
        component: GesuchPanel,
        meta: {
            requiresAuth: true,
            title: (to) => to.params.nr
        }
    },
    {
        path: '/gesuche/:nr/:step',
        component: GesuchPanel,
        meta: {
            requiresAuth: true,
            title: (to) => to.params.nr
        },
        children: [
            {
                name: 'gesuch-step',
                path: '',
                meta: {
                    title: (to) => to.params.nr
                }
            }
        ]
    }
];

export default routes;
