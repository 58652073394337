<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs, value }">
            <v-btn
                v-bind="attrs"
                rounded
                color="green lighten-3 white--text"
                v-on="on"
                @click="!value && loadUnreadMitteilungen()"
            >
                <v-avatar size="24" left class="green darken-5">
                    {{ mitteilungen.length || 0 }}
                </v-avatar>
                <span class="ml-2 d-none d-md-inline">{{
                    mitteilungen.length === 1 ? $t('new_message') : $t('new_messages')
                }}</span>
            </v-btn>
        </template>
        <v-list two-line subheader color="grey lighten-4 elevation-2">
            <v-list-item
                v-for="mitteilung in mitteilungen"
                :key="mitteilung.id"
                @click="$emit('mitteilungclick', mitteilung)"
            >
                <v-list-item-avatar>
                    <v-icon>far fa-envelope</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        {{ formatCreated(mitteilung) }} | {{ mitteilung.gesuch_nummer }}
                    </v-list-item-subtitle>
                    <v-list-item-title>{{ mitteilung.subject }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapActions } from 'vuex';

export default {
    components: {},
    data: function () {
        return {};
    },
    computed: {
        ...mapState('benutzer', {
            user: 'record'
        }),
        ...mapState('mitteilung', {
            mitteilungen: (state) => {
                return state.unread_mitteilungen.entries;
            }
        })
    },
    methods: {
        ...mapActions('mitteilung', ['fetchMitteilungen', 'loadUnreadMitteilungen']),
        formatCreated(msg) {
            return dayjs(msg.created).format('DD.MM.YYYY HH:mm');
        }
    }
};
</script>
