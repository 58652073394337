<template>
    <div>
        <v-toolbar flat color="primary white--text" class="colored-toolbar">
            <v-toolbar-title>
                <v-icon left color="white">fas fa-balance-scale fa-fw</v-icon>
                {{ $t('gesuche') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn rounded class="hidden-xs-only" color="subtleHint" @click="checkProfile">
                <v-icon left class="small-icon">fas fa-plus fa-xs</v-icon>
                {{ $t('create_gesuch') }}
            </v-btn>
            <template v-slot:extension>
                <v-tabs v-model="currentItem" background-color="primary" dark style="border-top: 2px solid white;">
                    <v-tab key="offen">
                        <v-icon left>fas fa-lock-open</v-icon>
                        {{ $t('open_gesuche') }}
                    </v-tab>
                    <v-tab key="geschlossen">
                        <v-icon left>fas fa-lock</v-icon>
                        {{ $t('closed_gesuche') }}
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>
        <v-tabs-items v-model="currentItem">
            <v-tab-item key="open">
                <v-container fluid><GesucheList status="open" /></v-container>
            </v-tab-item>
            <v-tab-item key="closed">
                <v-container fluid><GesucheList status="closed" /></v-container>
            </v-tab-item>
        </v-tabs-items>
        <ProfileDialog v-model="showProfileDialog" @close="closeProfileDialog" @cancel="closeProfileDialog" />
    </div>
</template>

<script>
import GesucheList from 'js/views/auth/gesuch/GesucheList.vue';
import ProfileDialog from 'js/components/complete-profile-first-dlg.vue';

export default {
    components: {
        GesucheList,
        ProfileDialog
    },
    data: function () {
        return {
            currentItem: 0,
            showProfileDialog: false
        };
    },
    computed: {},
    methods: {
        checkProfile() {
            if (this.$store.getters['benutzer/profileComplete']) {
                this.$router.push({ name: 'gesuch_erstellen' });
            } else {
                this.showProfileDialog = true;
            }
        },
        closeProfileDialog() {
            this.showProfileDialog = false;
        }
    }
};
</script>

<style scoped></style>
