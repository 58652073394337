<template>
    <v-container fluid>
        <v-row v-if="editing">
            <v-col>
                <GesuchLiegenschaftForm
                    :gesuch="gesuch"
                    :liegenschaft="editLiegenschaft"
                    :read-only="readOnly"
                    @close="onCloseLiegenschaftForm"
                ></GesuchLiegenschaftForm>
            </v-col>
        </v-row>
        <template v-else>
            <v-alert
                v-if="liegenschaftenConfig.infotext"
                type="info"
                :icon="false"
                border="left"
                colored-border
                elevation="2"
            >
                <v-row dense align="center">
                    <v-col>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="liegenschaftenConfig.infotext.replace('\n', '<br />')"> </span>
                    </v-col>
                    <v-col v-if="!readOnly" class="shrink">
                        <v-btn color="action" :disabled="loading" @click="openLiegenschaftSelect">
                            <v-icon left>fas fa-plus</v-icon>
                            {{ $t('assign_object') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
            <v-card-title v-else>
                <v-spacer />
                <v-btn v-if="!readOnly" color="accent" :disabled="loading" @click="openLiegenschaftSelect">
                    <v-icon left>fas fa-plus</v-icon>
                    {{ $t('assign_object') }}
                </v-btn>
            </v-card-title>
            <v-row>
                <v-col
                    v-for="liegenschaft in liegenschaften"
                    :key="liegenschaft.liegenschaft.id"
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                >
                    <LiegenschaftCard
                        :gesuch="gesuch"
                        :liegenschaft="liegenschaft"
                        :read-only="readOnly"
                        @edit-liegenschaft-zusatzdaten="editLiegenschaftZusatzdaten"
                        @delete-liegenschaft="deleteLiegenschaft"
                    ></LiegenschaftCard>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-spacer />
                <v-btn class="mt-2" color="action" :loading="loading" @click="$emit('next')">
                    <v-icon left>fas fa-arrow-right</v-icon>
                    {{ $t('next_step') }}
                </v-btn>
            </v-card-actions>
        </template>

        <LiegenschaftSelectDialog
            v-if="showLiegenschaftDialog"
            v-model="showLiegenschaftDialog"
            :loading="loading"
            :gesuch="gesuch"
            @close="closeLiegenschaftSelect"
            @assign="assignLiegenschaft"
            @new-liegenschaft="onNewLiegenschaft"
        ></LiegenschaftSelectDialog>
        <ConfirmDlg
            v-model="confirmDelDlg"
            :title="$t('bestaetigung')"
            :text="$t('really_remove_obj')"
            @yes="confirmDestroy"
            @no="toDeleteId = null"
        />
    </v-container>
</template>

<script>
import { Liegenschaft } from 'js/models/Liegenschaft';
import { Gesuch } from 'js/models/Gesuch';
import LiegenschaftCard from 'js/components/liegenschaft/gesuch-card.vue';
import LiegenschaftSelectDialog from 'js/components/liegenschaft/dialog/select.vue';
import GesuchLiegenschaftForm from 'js/components/liegenschaft/gesuch-liegenschaft-form.vue';
import ConfirmDlg from 'js/components/confirm-dlg.vue';

export default {
    components: {
        LiegenschaftCard,
        LiegenschaftSelectDialog,
        GesuchLiegenschaftForm,
        ConfirmDlg
    },
    props: {
        gesuch: {
            type: Gesuch,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            showLiegenschaftDialog: false,
            showAddLiegenschaftDlg: false,
            confirmDelDlg: false,
            editing: false,
            toDeleteId: null,
            editLiegenschaft: null,
            liegenschaften: []
        };
    },
    computed: {
        liegenschaftenConfig: function () {
            return this.gesuch.config.config.objekte || {};
        },
        edit_id() {
            // is set from a route if a certain liegenschaft need to be edited when the route opens:
            return this.$route.params.edit_id || null;
        }
    },

    watch: {
        gesuch: {
            handler(g) {
                this.liegenschaften = g.data.liegenschaften;
            },
            deep: true
        },
        editing: {
            handler(e) {
                this.$emit('editingmode', e);
            }
        }
    },

    mounted() {
        // If we get an edit_id from the route params (see computed props above),
        // then we open the Liegenschaft for editing:
        if (this.edit_id) {
            let liegenschaft = this.liegenschaften.find((l) => l.liegenschaft.id === this.edit_id);
            if (liegenschaft) {
                this.editLiegenschaftZusatzdaten(liegenschaft);
            }
        }
        if (this.gesuch) {
            this.liegenschaften = this.gesuch.data.liegenschaften;
        }
    },
    methods: {
        assignLiegenschaft(liegenschaft) {
            if (!this.liegenschaften) {
                this.gesuch.data.liegenschaften = [];
            }
            if (this.liegenschaften.some((e) => e.liegenschaft.id === liegenschaft.id)) {
                this.$toast({ message: this.$t('duplicat_object_error'), color: 'info' });
                return;
            }
            let liegenschaftEntry = {
                liegenschaft: liegenschaft,
                liegenschaft_zusatzdaten: null
            };
            this.gesuch.data.liegenschaften.push(liegenschaftEntry);
            this.$emit('gesuchdatachanged');
            this.editLiegenschaftZusatzdaten(liegenschaftEntry);
            this.closeLiegenschaftSelect();
        },
        openLiegenschaftSelect() {
            this.showLiegenschaftDialog = true;
        },
        closeLiegenschaftSelect() {
            this.showLiegenschaftDialog = false;
        },
        editLiegenschaftZusatzdaten(liegenschaft) {
            if (liegenschaft && liegenschaft.liegenschaft instanceof Liegenschaft) {
                this.editLiegenschaft = liegenschaft;
            } else {
                this.editLiegenschaft = {
                    liegenschaft: new Liegenschaft(liegenschaft.liegenschaft),
                    liegenschaft_zusatzdaten: liegenschaft.liegenschaft_zusatzdaten
                };
            }
            this.editing = true;
        },
        onNewLiegenschaft() {
            this.showLiegenschaftDialog = false;
            this.editing = true;
            this.editLiegenschaft = { liegenschaft: new Liegenschaft(), liegenschaft_zusatzdaten: null };
        },
        async confirmDestroy() {
            if (!this.liegenschaften) {
                this.gesuch.data.liegenschaften = [];
            }
            var index = this.liegenschaften.findIndex((data) => data.liegenschaft.id === this.toDeleteId);
            this.gesuch.data.liegenschaften.splice(index, 1);
            this.toDeleteId = null;
            this.$emit('gesuchdatachanged');
        },
        deleteLiegenschaft(liegenschaftId) {
            this.toDeleteId = liegenschaftId;
            this.confirmDelDlg = true;
        },
        onCloseLiegenschaftForm() {
            this.editing = false;
            this.$emit('gesuchdatachanged');
        }
    }
};
</script>
