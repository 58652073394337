<template>
    <v-card>
        <v-card-title>{{ $t('choose_kanton') }}</v-card-title>
        <v-card-subtitle>In welchem Kanton steht Ihr Projekt? Bitte wählen Sie:</v-card-subtitle>
        <v-card-text>
            <KantonRadio v-model="kanton" @input="emitValue" />
        </v-card-text>
        <v-card-actions>
            <v-btn color="action" :disabled="!kanton" @click="$emit('next')">
                {{ $t('next') }}
                <v-icon right>fas fa-arrow-right</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import KantonRadio from 'js/components/kanton-radio.vue';

export default {
    components: {
        KantonRadio
    },
    data: function () {
        return {
            kanton: null
        };
    },
    mounted() {
        let kanton = this.$store.getters['kanton/getKantonByKuerzel'](this.$store.state.customer);
        if (kanton) {
            this.emitValue(kanton);
        }
    },
    methods: {
        emitValue(value) {
            this.kanton = value;
            this.$emit('input', value);
        }
    }
};
</script>
