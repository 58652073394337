<template>
    <div>
        <v-toolbar class="colored-toolbar" color="primary white--text" dense>
            <v-toolbar-title>
                {{ $t('welcome_word') }},
                {{
                    benutzer.profilAdresse.vorname
                        ? benutzer.profilAdresse.vorname + ' ' + benutzer.profilAdresse.nachname
                        : benutzer.email
                }}
                <span if="benutzer.profilAdresse.vorname" class="user-email">
                    <kp-link class="user-email white--text" mailto :href="benutzer.email" />
                </span>
            </v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="4">
                    <v-card :to="{ name: 'gesuche' }" hover>
                        <v-card-title>
                            <v-avatar>
                                <v-icon>fas fa-balance-scale</v-icon>
                            </v-avatar>
                            {{ $t('gesuche') }}
                            <v-spacer />
                            <v-chip v-if="gesucheCount > 0" color="info">
                                <v-avatar left class="info darken-5">
                                    {{ gesucheCount }}
                                </v-avatar>
                                {{ $t('open') }}
                                <span v-if="toSubmitCount > 0" style="margin-left: 1em;">
                                    ({{ $t('not_submitted') }}: {{ toSubmitCount }})
                                </span>
                            </v-chip>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card to="/gesuche/erstellen" hover color="action">
                        <v-card-title>
                            <v-avatar>
                                <v-icon :color="customer === 'tg' ? 'yellow darken-3' : '#333'">fas fa-star</v-icon>
                            </v-avatar>
                            {{ $t('create_gesuch') }}
                        </v-card-title>
                    </v-card>
                </v-col>
                <!-- <v-col cols="12" md="4">
                    <v-card :to="{ name: 'adressen' }" hover>
                        <v-card-title>
                            <v-avatar>
                                <v-icon>fas fa-address-book</v-icon>
                            </v-avatar>
                            {{ $t('adressen') }}
                        </v-card-title>
                    </v-card>
                </v-col> -->
                <!-- <v-col cols="12" md="4">
                    <v-card :to="{ name: 'liegenschaften' }" hover>
                        <v-card-title>
                            <v-avatar>
                                <v-icon>fas fa-building</v-icon>
                            </v-avatar>
                            {{ $t('liegenschaften') }}
                        </v-card-title>
                    </v-card>
                </v-col> -->
                <!-- Teams will be implemented later -->
                <!-- <v-col cols="12" md="4">
                    <v-card :to="{ name: 'teams' }" hover>
                        <v-card-title>
                            <v-avatar>
                                <v-icon>fas fa-users</v-icon>
                            </v-avatar>
                            {{ $t('teams') }}
                        </v-card-title>
                    </v-card>
                </v-col> -->
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-card>
                        <v-card-title>
                            <span>{{ $t('anleitungen') }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        color="primary"
                                        fab
                                        outlined
                                        x-small
                                        :href="$store.state.webroot + '/docs/Anleitung_TG_SH_Energiefoerderportal.pdf'"
                                        target="_blank"
                                        v-on="on"
                                        @click="onDownloadClick('Anleitung_TG_SH_Energiefoerderportal.pdf')"
                                    >
                                        <v-icon>fas fa-book-open</v-icon>
                                    </v-btn>
                                </template>
                                <span>Anleitung Förderportal für TG und SH</span>
                            </v-tooltip>
                            <v-tooltip v-if="customer === 'tg'" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        class="ml-1"
                                        color="primary"
                                        fab
                                        outlined
                                        x-small
                                        :href="$store.state.webroot + '/docs/Ablaufschema_Apollon_TG.pdf'"
                                        target="_blank"
                                        @click="onDownloadClick('Ablaufschema_Apollon_TG.pdf')"
                                        v-on="on"
                                    >
                                        <img :src="tgWappen" width="18" alt="Wappen Thurgau" />
                                    </v-btn>
                                </template>
                                <span>Ablauf Fördergesuch TG</span>
                            </v-tooltip>
                            <v-tooltip v-if="customer === 'sh'" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        class="ml-1"
                                        color="primary"
                                        fab
                                        outlined
                                        x-small
                                        :href="$store.state.webroot + '/docs/Ablaufschema_Apollon_SH.pdf'"
                                        target="_blank"
                                        @click="onDownloadClick('Ablaufschema_Apollon_SH.pdf')"
                                        v-on="on"
                                    >
                                        <img :src="shWappen" width="18" alt="Wappen Thurgau" />
                                    </v-btn>
                                </template>
                                <span>Ablauf Fördergesuch SH</span>
                            </v-tooltip>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card>
                        <v-card-title>
                            {{ $t('vollmacht') }}
                        </v-card-title>
                        <v-card-text>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        color="primary"
                                        fab
                                        outlined
                                        x-small
                                        class="mr-1"
                                        :href="$store.state.webroot + '/docs/Vorlage_Vollmacht.docx'"
                                        target="_blank"
                                        @click="onDownloadClick('Vorlage_Vollmacht.docx')"
                                        v-on="on"
                                    >
                                        <v-icon>far fa-file-word</v-icon>
                                    </v-btn>
                                </template>
                                <span>Vollmacht-Vorlage als Word</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        color="primary"
                                        fab
                                        outlined
                                        x-small
                                        :href="$store.state.webroot + '/docs/Vorlage_Vollmacht.pdf'"
                                        target="_blank"
                                        @click="onDownloadClick('Vorlage_Vollmacht.pdf')"
                                        v-on="on"
                                    >
                                        <v-icon>far fa-file-pdf</v-icon>
                                    </v-btn>
                                </template>
                                <span>Vollmacht-Vorlage als PDF</span>
                            </v-tooltip>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            <v-avatar>
                                <v-icon color="info text--white">fas fa-info-circle</v-icon>
                            </v-avatar>
                            {{ $t('allgemeine_infos') }}
                        </v-card-title>
                        <v-card-text class="black--text">
                            <ul>
                                <li>
                                    Das Fördergesuch muss <strong>vor Bau- bzw. Installationsbeginn</strong> eingereicht
                                    werden. Nach der Einreichung des Fördergesuchs kann mit der Realisierung des
                                    Vorhabens begonnen werden, dies jedoch auf eigenes Risiko.
                                </li>
                                <li v-if="customer === 'tg'">
                                    Eine Förderzusage ist zwei Jahre gültig (Minergie-Neubauten, Wärmenetzprojekte: drei
                                    Jahre; GEAK mit Beratungsbericht, Machbarkeitsstudien, Energieanalysen: ein Jahr).
                                    Sie kann um ein Jahr verlängert werden. Beachten Sie die Funktion
                                    <em>Verlängerung beantragen</em>
                                    <img :src="$store.state.webroot + '/static/btn_verlaengerung.png'" />
                                </li>
                                <li v-else>
                                    Eine Förderzusage ist zwei Jahre gültig (Minergie-Neubauten, Wärmenetzprojekte: drei
                                    Jahre; GEAK mit Beratungsbericht, Machbarkeitsstudien, Energieanalysen,
                                    Umstiegsprämie Kauf Elektrofahrzeug: ein Jahr). Sie kann um ein Jahr verlängert
                                    werden. Beachten Sie die Funktion
                                    <em>Verlängerung beantragen</em>
                                    <img :src="$store.state.webroot + '/static/btn_verlaengerung.png'" />
                                </li>
                                <li>
                                    Eine Doppelförderung mit der Stiftung Klimaschutz und CO₂-Kompensation KliK oder mit
                                    Energie Zukunft Schweiz ist nicht zulässig. Unternehmensstandorte, die von der
                                    CO₂-Abgabe befreit sind, sind nicht förderberechtigt.
                                </li>
                                <li>
                                    Änderungen an den Fördersätzen und Förderbedingungen bleiben vorbehalten. Es gelten
                                    jeweils die aktuellen Fördersätze und Förderbedingungen zum Zeitpunkt der
                                    Gesuchseinreichung.
                                </li>
                                <li>
                                    Falls das Gesuch nicht von der Eigentümerschaft, sondern von einem Bevollmächtigten
                                    eingereicht wird, ist eine Vollmacht notwendig. Eine Vorlage finden Sie auf dieser
                                    Seite.
                                </li>
                                <li>
                                    Alle Informationen zu einem Gesuch werden von den beteiligten Organisationen und
                                    Fachleuten absolut vertraulich behandelt.
                                </li>
                                <li>
                                    Die Bearbeitungsstelle kann nicht für Schäden haftbar gemacht werden, welche bei der
                                    Planung, der Erstellung und dem Betrieb des geförderten Projekts entstehen können
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <admin-tools v-if="benutzer.rolle === 'admin'" />
        </v-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { instance as api } from 'js/lib/api';
import link from 'js/components/link.vue';
import AdminTools from 'js/components/admin-tools.vue';
import { TgWappenSvg, ShWappenSvg } from 'js/lib/images.js';

export default {
    components: {
        'kp-link': link,
        'admin-tools': AdminTools
    },
    data: function () {
        return {
            gesucheCount: 0,
            toSubmitCount: 0,
            tgWappen: TgWappenSvg,
            shWappen: ShWappenSvg
        };
    },
    computed: {
        ...mapState('benutzer', {
            benutzer: 'record'
        }),
        ...mapState(['customer'])
    },
    mounted() {
        this.loadGesucheCount();
    },
    methods: {
        async loadGesucheCount() {
            let res = await api().get('/gesuch/count', {
                params: {
                    status: 'open'
                }
            });
            if (res && res.data.count) {
                this.gesucheCount = res.data.count;
            } else {
                this.gesucheCount = 0;
            }

            res = await api().get('/gesuch/count', {
                params: {
                    status: 'notCommited'
                }
            });
            if (res && res.data.count) {
                this.toSubmitCount = res.data.count;
            } else {
                this.toSubmitCount = 0;
            }
        },
        onDownloadClick(filename) {
            api().get('/downloadcount/add', {
                params: {
                    filename: filename
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.user-email {
    font-size: 0.85em;
}
</style>
