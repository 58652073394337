<template>
    <div class="d-flex">
        <kp-textfield
            v-if="projektfeld.db_type === 'string'"
            dense
            :value="value"
            type="text"
            :label="projektfeld.label"
            :required="projektfeld.required"
            :readonly="readOnly"
            @input="valueChanged"
        />
        <v-textarea
            v-if="projektfeld.db_type === 'text'"
            dense
            :value="value"
            :label="projektfeld.label"
            :required="projektfeld.required"
            :readonly="readOnly"
            @input="valueChanged"
        />
        <v-switch
            v-if="projektfeld.db_type === 'boolean'"
            dense
            :value="value"
            :label="projektfeld.label"
            :readonly="readOnly"
            @input="valueChanged"
        ></v-switch>
        <kp-numberfield
            v-if="projektfeld.db_type === 'integer' && !projektfeld.ist_link && !projektfeld.ist_werteliste"
            dense
            :value="value"
            :fieldname="projektfeld.feld_name"
            :label="projektfeld.label"
            :rules="[
                rules.between(projektfeld.value_from, projektfeld.value_to, projektfeld.warnmeldung),
                rules.isInt()
            ]"
            :required="projektfeld.required"
            :readonly="readOnly"
            @input="valueChanged"
        />
        <v-autocomplete
            v-if="
                projektfeld.db_type === 'integer' &&
                projektfeld.ist_werteliste &&
                (!hideOnEmpty || wertelisteItems.length > 0)
            "
            dense
            :clearable="!readOnly"
            clear-icon="fas fa-times"
            :value="value"
            :loading="loading"
            item-text="value"
            item-value="id"
            :items="wertelisteItems"
            :label="projektfeld.label"
            :required="projektfeld.required"
            :readonly="readOnly"
            :return-object="returnmodel"
            @input="valueChanged"
        ></v-autocomplete>
        <v-autocomplete
            v-if="projektfeld.db_type === 'integer' && projektfeld.ist_link && (!hideOnEmpty || linkItems.length > 0)"
            dense
            :clearable="!readOnly"
            clear-icon="fas fa-times"
            :value="value"
            :loading="loading"
            item-text="value"
            item-value="id"
            :items="linkItems"
            :label="projektfeld.label"
            :required="projektfeld.required"
            :readonly="readOnly"
            :return-object="returnmodel"
            @input="valueChanged"
        ></v-autocomplete>
        <kp-numberfield
            v-if="projektfeld.db_type === 'float'"
            dense
            :value="value"
            :label="projektfeld.label"
            :rules="[rules.between(projektfeld.value_from, projektfeld.value_to, projektfeld.warnmeldung)]"
            :required="projektfeld.required"
            :fieldname="projektfeld.feld_name"
            :readonly="readOnly"
            @input="valueChanged"
        />
        <v-select
            v-if="projektfeld.db_type === 'ja_nein_teilweise'"
            :label="projektfeld.label"
            dense
            :clearable="!readOnly"
            clear-icon="fas fa-times"
            :required="projektfeld.required"
            :value="value"
            :readonly="readOnly"
            :items="['ja', 'nein', 'teilweise']"
            @input="valueChanged"
        >
        </v-select>
        <v-select
            v-if="projektfeld.db_type === 'ja_nein_unknown'"
            :label="projektfeld.label"
            dense
            :clearable="!readOnly"
            clear-icon="fas fa-times"
            :required="projektfeld.required"
            :value="value"
            :items="['ja', 'nein', 'unbekannt']"
            :readonly="readOnly"
            @input="valueChanged"
        >
        </v-select>
        <v-menu
            v-if="projektfeld.db_type === 'date'"
            v-model="datemenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    dense
                    :clearable="!readOnly"
                    clear-icon="fas fa-times"
                    :value="formatDate(value)"
                    :label="projektfeld.label"
                    :required="projektfeld.required"
                    prepend-icon="fas fa-calendar"
                    readonly
                    v-bind="attrs"
                    @input="valueChanged"
                    v-on="readOnly ? null : on"
                ></v-text-field>
            </template>
            <v-date-picker
                :value="value"
                :min="projektfeld.value_from"
                :max="projektfeld.value_to"
                first-day-of-week="1"
                @input="valueChanged"
            ></v-date-picker>
        </v-menu>
        <field-info-icon v-if="showInfoIcon" :infotext="projektfeld.hilfetext" left />
    </div>
</template>

<script>
import { ProjektFeld } from 'js/models/apollon_conf_fk/ProjektFeld';
import { formRules } from 'js/lib/helpers';
import { mapActions } from 'vuex';
import FieldInfoIcon from 'js/components/field-info-icon.vue';
import dayjs from 'dayjs';
import KpTextfield from 'js/components/kp-textfield.vue';
import KpNumberfield from 'js/components/kp-numberfield.vue';

export default {
    components: {
        'field-info-icon': FieldInfoIcon,
        KpTextfield,
        KpNumberfield
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        projektfeld: {
            type: ProjektFeld,
            default: null
        },
        filter: {
            type: Array,
            default: null
        },
        returnmodel: {
            type: Boolean,
            default: false
        },
        /**
         * hideOnEmpty: only for select fields: if true, the component will hide itself if its
         * item list is empty.
         */
        hideOnEmpty: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number, Object],
            default: null
        }
    },
    data() {
        return {
            rules: formRules,
            datemenu: false,
            wertelisteItems: [],
            linkItems: [],
            fieldModel: null,
            dayjs: null
        };
    },
    computed: {
        showInfoIcon() {
            if (this.projektfeld.ist_werteliste) {
                return !this.hideOnEmpty || this.wertelisteItems.length > 0;
            }
            if (this.projektfeld.ist_link) {
                return !this.hideOnEmpty || this.linkItems.length > 0;
            }
            return true;
        }
    },
    watch: {
        fieldModel(newVal) {
            this.$emit('input', newVal);
        },
        value(newVal) {
            this.fieldModel = newVal;
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadSelectFieldItems(newVal);
            }
        }
    },
    created() {
        this.loadSelectFieldItems(this.filter);
        this.fieldModel = this.value;
    },
    methods: {
        ...mapActions('lookup', ['lookupValue']),
        async loadSelectFieldItems(filter) {
            filter = filter || [];
            if (this.projektfeld.ist_werteliste) {
                this.loading = true;
                try {
                    let wertelisteCollection = await this.lookupValue({
                        apiCancelToken: this.projektfeld.feld_name,
                        table: this.projektfeld.werteliste_tabelle,
                        filter: JSON.stringify(filter),
                        sort: 'reihenfolge'
                    });
                    this.wertelisteItems = wertelisteCollection.models;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            } else if (this.projektfeld.ist_link) {
                this.loading = true;
                try {
                    if (this.projektfeld.linktabelle_produktkategorie_id) {
                        filter.push({
                            property: 'produkt_kategorie_id',
                            value: this.projektfeld.linktabelle_produktkategorie_id
                        });
                    }
                    let linkCollection = await this.lookupValue({
                        apiCancelToken: this.projektfeld.feld_name,
                        table: this.projektfeld.link_tabellenname,
                        filter: JSON.stringify(filter)
                    });
                    this.linkItems = linkCollection.models;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            }
        },
        formatDate(value) {
            return value ? dayjs(value).format('DD.MM.YYYY') : '';
        },
        valueChanged(value) {
            this.datemenu = false;
            if (this.returnmodel && typeof value === 'object' && value !== null && 'id' in value && 'value' in value) {
                this.$emit('input', { value: value.id, display: value.value });
            } else {
                this.$emit('input', value);
            }
        }
    }
};
</script>
