/**
 * Represents the zeus.apollon_fk_conf.conf.kontakte value object - the Foerderkategorie config
 * json structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model } from 'vue-mc';

export const SICHTBARKEIT = {
    IMMER: null,
    EINREICHUNG: 'einreichung',
    ABSCHLUSS: 'abschluss',
    NIE: 'nie'
};

export class KontaktConfig extends Model {
    defaults() {
        return {
            label: null,
            sichtbarkeit: null,
            hilfetext: null,
            hilfetext_kurz: null
        };
    }

    mutations() {
        return {
            sichtbarkeit: (val) => {
                if (val === SICHTBARKEIT.ABSCHLUSS || val === SICHTBARKEIT.EINREICHUNG || val === SICHTBARKEIT.NIE) {
                    return val;
                } else {
                    return null;
                }
            }
        };
    }
}
