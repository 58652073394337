<template>
    <v-text-field
        v-if="edit"
        v-bind="{ ...$props, ...$attrs }"
        :value="value"
        type="number"
        @input="(v) => $emit('input', v)"
        @blur="edit = false"
    />
    <v-text-field
        v-else
        v-bind="{ ...$props, ...$attrs }"
        :value="numValue"
        :rules="[checkRules]"
        @focus="edit = true"
    />
</template>

<script>
export default {
    props: {
        value: { type: [String, Number], default: null },
        rules: { type: Array, default: () => [] },
        fieldname: { type: String, default: null }
    },

    data() {
        return {
            edit: false,
            yearFields: ['baujahr', 'registrierungsjahr']
        };
    },

    computed: {
        numValue() {
            // check for year fields, these should not have thousands separators
            if (this.fieldname) {
                for (const yearField of this.yearFields) {
                    if (this.fieldname.includes(yearField)) {
                        return this.value;
                    }
                }
            }
            if (this.value) {
                return parseFloat(this.value).toLocaleString('de-ch');
            }
            return this.value;
        }
    },

    methods: {
        checkRules() {
            for (const rule of this.rules) {
                let res = rule(this.value);
                if (res !== true) {
                    return res;
                }
            }
            return true;
        }
    }
};
</script>
