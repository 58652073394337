<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="9">
                <v-form v-model="valid" autocomplete="on" @submit.prevent="resetPW">
                    <v-card class="trans-bg">
                        <v-card-title>{{ $t('reset_pw') }}</v-card-title>
                        <v-card-text>
                            <kp-textfield
                                v-model="username"
                                :label="$t('email')"
                                :placeholder="$t('zb') + ' name@domain.com'"
                                autocomplete="on"
                                :autofocus="true"
                                :rules="[rules.required, rules.email]"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn to="/" :disabled="loading">{{ $t('cancel') }}</v-btn>
                            <v-btn
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="!valid || loading || !!success"
                                @click.prevent="resetPW"
                            >
                                {{ $t('reset') }}
                            </v-btn>
                        </v-card-actions>

                        <v-card-text v-if="success">
                            <v-alert type="success">{{ success }}</v-alert>
                        </v-card-text>
                        <v-card-text v-if="error">
                            <v-alert type="error">{{ error }}</v-alert>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { formRules } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    data: function () {
        return {
            username: null,
            success: null,
            error: null,
            rules: formRules,
            valid: false
        };
    },
    computed: {
        loading: function () {
            return this.$store.state.loading;
        }
    },
    methods: {
        ...mapActions('benutzer', ['resetPassword']),

        async resetPW() {
            try {
                let res = await this.resetPassword({ username: this.username });
                if (res) {
                    this.error = res;
                } else {
                    this.success = this.$t('reset_confirm', { username: this.username });
                }
            } catch (e) {
                this.error = e;
            }
        }
    }
};
</script>
