/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';

export class Anrede extends BaseModel {
    defaults() {
        return {
            id: null,
            bezeichnung: null,
            briefanrede: null,

            aktiv: null,
            erstellt_am: null,
            bearbeitet_am: null,
            ersteller_id: null,
            bearbeiter_id: null,
            reihenfolge: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            ersteller_id: (id) => (Number(id) > 0 ? Number(id) : null),
            bearbeiter_id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'anrede';
    }
}
