<template>
    <v-card>
        <v-skeleton-loader
            v-if="initialLoading"
            type="list-item-avatar, list-item-three-line@3, actions"
        ></v-skeleton-loader>
        <div v-else>
            <v-toolbar color="primary white--text" class="colored-toolbar">
                <v-toolbar-title>
                    <v-icon left color="white">fas fa-building</v-icon>
                    {{ $t('liegenschaft') }}
                </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
                <v-card color="grey lighten-4" class="mb-5" max-width="950">
                    <v-form v-model="valid" autocomplete="new-password">
                        <v-card-title>
                            {{ zusatzdatenConfig.rahmen['objekt_felder'].label }}
                            <field-info-icon :infotext="zusatzdatenConfig.rahmen['objekt_felder'].hilfetext" left />
                        </v-card-title>
                        <LiegenschaftFormFields
                            :liegenschaft="liegenschaftEntry"
                            :rules="rules"
                            :loading="loading"
                            :read-only="readOnly"
                            :felder-config="zusatzdatenConfig.rahmen.objekt_felder || {}"
                            dense
                        ></LiegenschaftFormFields>
                    </v-form>
                </v-card>
                <v-form v-if="zusatzdatenEntry && zusatzdatenConfig.visible" no-gutters autocomplete="new-password">
                    <template v-for="(rahmen, key) in zusatzdatenConfig.rahmen">
                        <v-card
                            v-if="isComponentVisible(rahmen) && key !== 'objekt_felder'"
                            :key="key"
                            color="grey lighten-4"
                            max-width="600px"
                            class="mb-2"
                        >
                            <v-card-title>
                                {{ rahmen.label }}
                                <field-info-icon :infotext="rahmen.hilfetext" left />
                            </v-card-title>
                            <template v-for="feld in rahmen.felder">
                                <!--
                                            filter: The field 'Energietraeger' gets the selected Heizsystem-id as filter,
                                            which is captured via input event
                                        -->
                                <v-card-text v-if="isComponentVisible(feld)" :key="feld.feld_name">
                                    <ProjektFeld
                                        v-if="isComponentVisible(feld)"
                                        v-model="zusatzdatenEntry[feld.feld_name]"
                                        :returnmodel="true"
                                        :projektfeld="feld"
                                        :phase="gesuch.phase"
                                        :read-only="readOnly"
                                        :hide-on-empty="feld.feld_name === 'energietraeger_id'"
                                        :filter="feld.feld_name === 'energietraeger_id' ? heizsystemFilter : null"
                                        @input="feld.feld_name === 'heizungstyp_id' ? onHeizungstypFieldChange() : null"
                                    ></ProjektFeld>
                                </v-card-text>
                            </template>
                        </v-card>
                    </template>
                </v-form>
            </v-container>
        </div>
        <v-card-actions>
            <v-spacer />
            <v-btn class="hidden-sm-and-up" color="decline" text :disabled="loading" @click="closeForm">
                <v-icon>fas fa-arrow-left</v-icon>
            </v-btn>
            <v-btn class="hidden-xs-only" color="decline" :disabled="loading" text @click="closeForm">
                {{ $t('close') }}
            </v-btn>
            <v-btn
                v-if="!readOnly"
                class="hidden-xs-only"
                color="action white--text"
                type="submit"
                :disabled="loading || !valid"
                :loading="loading"
                @click="onSubmit"
            >
                <v-icon left>fas fa-save</v-icon>
                {{ $t('save') }}
            </v-btn>
            <v-btn
                v-if="!readOnly"
                class="hidden-sm-and-up"
                color="action white--text"
                type="submit"
                :disabled="loading || !valid"
                :loading="loading"
                @click="onSubmit"
            >
                <v-icon>fas fa-save</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { errToStr, formRules } from 'js/lib/helpers.js';
import { mapActions, mapState } from 'vuex';
import { Gesuch } from 'js/models/Gesuch';
import ProjektFeld from 'js/components/projekt-feld-field.vue';
import LiegenschaftFormFields from 'js/components/liegenschaft/form-fields.vue';
import FieldInfoIcon from 'js/components/field-info-icon.vue';
import { Liegenschaft } from 'js/models/Liegenschaft';
import dayjs from 'dayjs';

export default {
    components: {
        LiegenschaftFormFields,
        ProjektFeld,
        'field-info-icon': FieldInfoIcon
    },
    props: {
        gesuch: {
            type: Gesuch,
            required: true
        },
        liegenschaft: {
            type: Object,
            require: false,
            default: null
        },
        readOnly: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            loading: false,
            confirmDelDlg: false,
            rules: formRules,
            initialLoading: true,
            liegenschaftEntry: null,
            zusatzdatenEntry: null,
            heizsystemFilter: null,
            valid: false
        };
    },
    computed: {
        ...mapState('benutzer', {
            benutzer: 'record'
        }),
        zusatzdatenConfig() {
            return this.gesuch.config.config.objekte;
        }
    },
    created() {
        this.loadLiegenschaftData();
    },
    methods: {
        ...mapActions('liegenschaften', ['storeLiegenschaft']),
        loadLiegenschaftData() {
            this.loading = true;
            this.initialLoading = true;
            this.zusatzdatenEntry = { ...this.liegenschaft.liegenschaft_zusatzdaten };
            this.liegenschaftEntry =
                this.liegenschaft.liegenschaft instanceof Liegenschaft
                    ? this.liegenschaft.liegenschaft.clone()
                    : { ...this.liegenschaft.liegenschaft };
            this.initialLoading = false;
            this.loading = false;
        },
        closeForm() {
            this.$emit('close');
        },
        async onSubmit() {
            this.loading = true;
            if (!this.liegenschaftEntry.id) {
                try {
                    let liegenschaft = await this.storeLiegenschaft(this.liegenschaftEntry);
                    if (!this.gesuch.data.liegenschaften) {
                        this.gesuch.data.liegenschaften = [];
                    }
                    this.gesuch.data.liegenschaften.push({
                        liegenschaft: liegenschaft,
                        liegenschaft_zusatzdaten: this.zusatzdatenEntry
                    });
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                }
            } else {
                let liegenschaft = this.gesuch.data.liegenschaften.find(
                    (e) => e.liegenschaft.id === this.liegenschaftEntry.id
                );
                if (liegenschaft) {
                    liegenschaft.liegenschaft = this.liegenschaftEntry;
                    liegenschaft.liegenschaft_zusatzdaten = this.zusatzdatenEntry;
                }
            }
            this.loading = false;
            this.closeForm();
        },
        isComponentVisible(comp) {
            let visible = true;
            if (this.gesuch.phase === 'einreichung') {
                if (comp.einreichung_from) {
                    visible &= dayjs(comp.einreichung_from).isBefore(dayjs());
                }
                if (comp.einreichung_from) {
                    visible &= dayjs().isBefore(dayjs(comp.einreichung_to).add(1, 'day'));
                }
                visible &= comp.einreichung;
                return visible;
            }
            if (this.gesuch.phase === 'abschluss') {
                if (comp.abschluss_from) {
                    visible &= dayjs(comp.abschluss_from).isBefore(dayjs());
                }
                if (comp.abschluss_from) {
                    visible &= dayjs().isBefore(dayjs(comp.abschluss_to).add(1, 'day'));
                }
                visible &= comp.abschluss;
                return visible;
            }
            return false;
        },
        onHeizungstypFieldChange() {
            let value = this.zusatzdatenEntry.heizungstyp_id;
            this.heizsystemFilter = [{ property: 'heizungstyp_id', value: value ? value.value : null }];
        }
    }
};
</script>
