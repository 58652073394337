<template>
    <a :href="link" :target="target">
        <slot>{{ href }}</slot>
    </a>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
            default: ''
        },
        target: {
            type: String,
            default: ''
        },
        mailto: {
            type: Boolean,
            default: false
        },
        tel: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        link: function () {
            if (this.mailto) {
                return `mailto:${this.href}`;
            } else if (this.tel) {
                return `tel:${this.href}`;
            } else {
                return this.href;
            }
        }
    }
};
</script>
