/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model, Response } from 'vue-mc';
import { instance as apiInstance } from 'js/lib/api';

export default class BaseModel extends Model {
    static fromData(data) {
        if (data instanceof this) {
            return data;
        } else if (data) {
            return new this(data);
        } else {
            return null;
        }
    }

    /**
     * Override in child classes: This is the API endpoint's
     * entity name (e.g. like in /api/Benutzer/list --> Benutzer)
     *
     * @return {String} The API endpoint's Entity name
     */
    entityName() {
        return this.constructor.name;
    }

    routes() {
        // Inject base URL here: it seems that this does not
        // work with axios' baseURL Parameter, see createRequest below.
        let className = this.entityName();

        return {
            fetch: `/${className}/get?id={id}`,
            save: `/${className}/store`,
            delete: `/${className}/destroy?id={id}`
        };
    }

    options() {
        return {
            identifier: 'id',
            validateRecursively: false,
            methods: {
                fetch: 'GET',
                save: 'POST',
                update: 'POST',
                create: 'POST',
                delete: 'GET'
            }
        };
    }

    isDirty() {
        let props = this.changed();
        if (props) {
            for (let p of props) {
                if (this.get(p) !== this.saved(p)) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Override original method:
     *
     * Merge config from the pre-configured axios instance,
     * remove the basURL config (this does not work for some reason...)
     * and call the original method with the enriched config.
     */
    createRequest(config) {
        config.headers = Object.assign(apiInstance().defaults.headers, config.headers);
        return {
            send: () =>
                apiInstance()
                    .request(config)
                    .then((response) => new Response(response))
        };
    }
}
