<template>
    <v-app-bar :app="app" class="kp-header" :class="$store.state.config.env ? $store.state.config.env : ''">
        <img :src="shImg" height="45" class="mr-3" alt="Schaffhausen Wappen" />
        <div>
            <h1 class="title">
                {{ $t('g_efp') }}
            </h1>
            <div class="subtitle">
                {{ $t('k_sh') }}
            </div>
        </div>
    </v-app-bar>
</template>

<script>
import { TgWappenSvg, ShWappenSvg } from 'js/lib/images.js';

export default {
    props: {
        app: { type: Boolean, default: false },
        small: { type: Boolean, default: false }
    },
    data: function () {
        return {
            tgImg: TgWappenSvg,
            shImg: ShWappenSvg
        };
    }
};
</script>
