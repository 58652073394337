<template>
    <v-dialog :value="value" max-width="700" @click:outside="$emit('input', false)">
        <v-card>
            <slot name="title">
                <v-card-title class="headline">{{ $t('choose_correct_value') }}</v-card-title>
            </slot>
            <slot>
                <v-card-text>
                    <v-alert type="warning">
                        {{ $t('check_gwr_data') }}
                    </v-alert>
                    <v-row wrap>
                        <v-col cols="12" md="4" dense class="hidden-md-and-down">
                            {{ $t('field') }}
                        </v-col>
                        <v-col cols="12" md="8" dense> {{ $t('actual_value') }} / {{ $t('gwr_value') }} </v-col>
                    </v-row>
                    <v-row v-for="change in changes" :key="change.key" :value="change.value" wrap>
                        <v-col cols="12" md="4" dense>
                            {{ $t(change.key) }}
                        </v-col>
                        <v-col cols="12" md="8" dense>
                            <v-btn-toggle
                                v-model="toggle[change.key]"
                                class="gwrdata-toggle"
                                dense
                                mandatory
                                color="success"
                            >
                                <v-btn width="200">{{ change.value || '-' }}</v-btn>
                                <v-btn width="200">{{ change.gwrValue || '-' }}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </v-card-text>
            </slot>
            <slot name="actions">
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text color="decline" @click="$emit('input', false)">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="action" @click="applyGwrData">
                        <v-icon left>fas fa-check</v-icon>
                        {{ $t('ok') }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        changes: {
            type: Array,
            default: () => []
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            toggle: []
        };
    },
    watch: {
        changes(ch) {
            ch.forEach((c) => (this.toggle[c.key] = c.gwrValue ? 1 : 0));
        }
    },
    methods: {
        applyGwrData() {
            let result = [];
            this.changes.forEach((c) => {
                result.push({ key: c.key, value: this.toggle[c.key] === 0 ? c.value : c.gwrValue });
            });
            this.$emit('apply', result);
        }
    }
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
    .gwrdata-toggle {
        flex-direction: column;
    }
}
</style>

<style scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}
</style>
