<template>
    <v-container fluid>
        <v-card-actions>
            <SearchField class="white--text" @executeSearch="executeSearch"></SearchField>
            <v-btn v-if="addbtn" color="action white--text" justify-center rounded @click="$emit('new-address')">
                <v-icon left class="small-icon">fas fa-plus fa-xs</v-icon>
                {{ addbtn }}
            </v-btn>
        </v-card-actions>
        <v-data-table
            v-model="selectedItems"
            :class="{ 'border-thin': true, 'fixed-table': true, 'fixed-overflow-table': !dense, 'row-pointer': true }"
            :headers="headers"
            :calculate-widths="calculateWidth"
            :items="adressen.models"
            :options.sync="options"
            :server-items-length="total"
            :items-per-page="50"
            :loading="adrLoading"
            :dense="dense"
            :single-select="true"
            item-key="id"
            :show-select="selectable"
            :hide-default-footer="nofooter"
            :no-data-text="$t('no_records')"
            :height="height"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, -1]
            }"
            @click:row="onRowClicked"
            @item-selected="onItemSelected"
        >
            <template v-if="dense" v-slot:[`item.typ`]="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="item.typ === 'person'" x-small v-on="on">fas fa-user</v-icon>
                    </template>
                    <span>{{ $t('person') }}</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="item.typ === 'organisation'" x-small v-on="on">
                            fas fa-building
                        </v-icon>
                    </template>
                    <span>{{ $t('organisation') }}</span>
                </v-tooltip>
            </template>
            <template v-else v-slot:[`item.typ`]="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="item.typ === 'person'" v-on="on">fas fa-user</v-icon>
                    </template>
                    <span>{{ $t('person') }}</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="item.typ === 'organisation'" v-on="on">
                            fas fa-building
                        </v-icon>
                    </template>
                    <span>{{ $t('organisation') }}</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <span>
                    <v-btn class="mr-1" x-small fab rounded color="accent" @click.stop="showForm(item)">
                        <v-icon x-small>fas fa-pen</v-icon>
                    </v-btn>
                    <v-btn class="mr-1" x-small fab rounded color="error" @click.stop="destroyItem(item)">
                        <v-icon x-small>fas fa-trash</v-icon>
                    </v-btn>
                </span>
            </template>
            <template v-slot:[`item.display_name`]="{ item }">
                {{ item.display_name }}<br />{{ item.nachname2 }}
            </template>
            <template v-slot:[`item.vorname`]="{ item }">{{ item.vorname }}<br />{{ item.vorname2 }}</template>
            <template v-slot:[`item.plz`]="{ item }">
                <template v-if="item.laendercode === 'CH'">{{ item.plz }}</template>
                <template v-else>{{ item.laendercode ? item.laendercode + '-' : '' }}{{ item.plz }}</template>
            </template>
            <v-data-footer disable-items-per-page="true"></v-data-footer>
        </v-data-table>
        <ConfirmDlg
            v-model="confirmDelDlg"
            :title="$t('bestaetigung')"
            :text="$t('really_delete_rec')"
            @yes="confirmDestroy"
        />
    </v-container>
</template>

<script>
import SearchField from 'js/components/SearchField.vue';
import ConfirmDlg from 'js/components/confirm-dlg.vue';
import { errToStr } from 'js/lib/helpers.js';
import { Adresse } from 'js/models/Adresse';
import { mapActions } from 'vuex';

export default {
    components: {
        SearchField,
        ConfirmDlg
    },
    props: {
        addbtn: { type: String, default: null },
        edit: { type: Boolean, default: true },
        dense: { type: Boolean, default: false },
        nofooter: { type: Boolean, default: false },
        selectable: { type: Boolean, default: false },
        height: { type: Number, default: undefined },
        selection: { type: Adresse, default: null },
        reload: { type: Boolean, default: false },
        headerwidths: {
            type: Object,
            default: () => {
                return {
                    typ: '55px',
                    name: '15%',
                    vorname: '15%',
                    adresse: '30%',
                    plz: '10%',
                    ort: '20%',
                    action: '120px'
                };
            }
        },
        gesuchId: { type: Number, default: null },
        typfilter: { type: String, default: null }
    },
    data() {
        return {
            adressen: [],
            adrLoading: true,
            calculateWidth: true,
            searchString: '',
            total: 0,
            selectedItems: [],
            confirmDelDlg: false,
            toDelete: null,
            options: {
                page: 1,
                itemsPerPage: 50,
                sortBy: ['display_name'],
                sortDesc: [false],
                multiSort: false,
                mustSort: true
            }
        };
    },
    computed: {
        params() {
            return {
                ...this.options,
                query: this.searchString
            };
        },
        headers() {
            let res = [
                { value: 'typ', sortable: false, filterable: false, width: this.headerwidths.typ },
                {
                    text: this.headerwidths.name !== '0%' ? this.$t('name') : '',
                    value: 'display_name',
                    sortable: true,
                    filterable: true,
                    width: this.headerwidths.name
                },
                {
                    text: this.headerwidths.vorname !== '0%' ? this.$t('vorname') : '',
                    value: 'vorname',
                    sortable: true,
                    filterable: true,
                    width: this.headerwidths.vorname
                },
                {
                    text: this.headerwidths.adresse !== '0%' ? this.$t('adresse') : '',
                    value: 'adresse',
                    sortable: true,
                    filterable: true,
                    width: this.headerwidths.adresse
                },
                {
                    text: this.headerwidths.plz !== '0%' ? this.$t('plz') : '',
                    value: 'plz',
                    sortable: true,
                    filterable: true,
                    width: this.headerwidths.plz
                },
                {
                    text: this.headerwidths.ort !== '0%' ? this.$t('ort') : '',
                    value: '$.ort',
                    sortable: true,
                    filterable: true,
                    width: this.headerwidths.ort
                }
            ];
            if (this.edit) {
                res.push({ value: 'action', sortable: false, filterable: false, width: this.headerwidths.action });
            }
            return res;
        }
    },
    watch: {
        params: {
            handler(val) {
                if (Object.keys(val).length !== 0) {
                    this.getAdressen();
                }
            },
            deep: true
        },
        selection: {
            handler(val) {
                if (!Array.isArray(val)) {
                    val = [val];
                }
                this.selectedItems = val;
            }
        },
        reload: {
            handler(val) {
                if (val) {
                    this.getAdressen();
                    this.$emit('reload');
                }
            }
        }
    },
    methods: {
        ...mapActions('adressen', ['fetchAdressen', 'destroyAdresse']),
        async getAdressen() {
            this.adrLoading = true;
            // Clean vue-mc prefix on strings:
            this.params.sortBy = this.params.sortBy.map((item) => item.replace(/^\$\./, ''));
            this.params.gesuchId = this.gesuchId;
            if (this.typfilter) {
                this.params.typ = this.typfilter;
            }
            try {
                let data = await this.fetchAdressen(this.params);
                this.adressen = data;
                this.total = data.get('remoteTotal');
            } catch (e) {
                console.log(e);
            } finally {
                this.adrLoading = false;
            }
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop;
        },
        executeSearch(value) {
            this.searchString = value;
        },
        showForm(item) {
            this.$router.push({ name: 'adresse', params: { id: item.id || 'new' } });
        },
        onRowClicked(row, item) {
            if (this.selectable) {
                item.select(!item.isSelected);
                this.selectedItems = item.isSelected ? [] : [row];
                this.emitSelection();
            } else {
                this.showForm(row);
            }
        },
        onItemSelected(evt) {
            this.selectedItems = evt.value ? [evt.item] : [];
            this.emitSelection();
        },
        emitSelection() {
            if (this.selectedItems.length === 1) {
                this.$emit('selection', this.selectedItems[0]);
            } else {
                this.$emit('selection', null);
            }
        },
        destroyItem(item) {
            this.toDelete = item;
            this.confirmDelDlg = true;
        },
        async confirmDestroy() {
            try {
                await this.destroyAdresse(this.toDelete);
                this.toDelete = null;
                this.total = this.total - 1;
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        }
    }
};
</script>

<style scoped>
.fixed-table >>> table {
    table-layout: fixed;
    width: 100%;
}
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
@media screen and (min-width: 600px) {
    .fixed-table >>> table td {
        text-overflow: ellipsis;
    }
    .fixed-overflow-table >>> table td {
        overflow: hidden;
    }
}
@media screen and (max-width: 600px) {
    .fixed-table >>> table th {
        width: 100%;
    }
}
</style>
