/**
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';

export class OrtGemeinde extends BaseModel {
    defaults() {
        return {
            id: null,

            //view fields
            gemeinde: null,
            gemeinde_bfsnr: null,
            ort: null,
            ort_kurz: null,
            ort_plz: null,
            ort_onrp: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    entityName() {
        return 'OrtGemeinde';
    }
}
