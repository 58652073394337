<template>
    <kp-textfield
        v-model="searchString"
        label="Suchen..."
        single-line
        hide-details
        clearable
        clear-icon="fas fa-times"
        class="small-icon"
        @click:append="executeSearch"
        @keydown="onSearchKey"
        @click:clear="clearSearch"
    >
        <template v-slot:prepend-inner>
            <v-icon size="17px" class="mt-1 mr-2">fas fa-search</v-icon>
        </template>
    </kp-textfield>
</template>

<script>
import { debounce } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    data() {
        return {
            searchString: ''
        };
    },
    watch: {
        searchString: {
            handler() {
                this.executeSearch();
            }
        }
    },
    methods: {
        executeSearch: debounce(function () {
            this.$emit('executeSearch', this.searchString);
        }),
        onSearchKey(event) {
            if (event.key === 'Enter') {
                this.executeSearch();
            }
        },
        clearSearch() {
            this.searchString = '';
            this.executeSearch();
        }
    }
};
</script>

<style></style>
