<template>
    <v-dialog :value="value" :max-width="maxWidth" @click:outside="$emit('input', false)">
        <v-card>
            <slot name="title">
                <v-card-title class="headline">{{ p_title }}</v-card-title>
            </slot>
            <slot>
                <v-card-text>{{ p_text }}</v-card-text>
            </slot>
            <slot name="actions">
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn v-if="showNoButton" color="decline" text :disabled="loading" @click="onNo">
                        {{ p_noText }}
                    </v-btn>

                    <v-btn color="action" :loading="loading" :disabled="disableYes" @click="onYes">
                        <v-icon left>fas fa-check</v-icon>
                        {{ p_yesText }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
/**
 * Confirm-Dialog
 *
 * Shows a dialog with a title, text, and a cancel / confirm button.
 *
 * You can override the title, content and action section by providing
 * own slots (title, default, actions).
 *
 * Emit events:
 * - 'no': when user clicks on cancel
 * - 'yes': when user clicks on ok
 */
export default {
    props: {
        value: Boolean, // v-model (true: show, hide: false)
        title: { type: String, default: null },
        text: { type: String, default: null },
        yesText: { type: String, default: null },
        noText: { type: String, default: null },
        loading: { type: Boolean, default: false },
        closeOnYes: { type: Boolean, default: true },
        closeOnNo: { type: Boolean, default: true },
        disableYes: { type: Boolean, default: false },
        showNoButton: { type: Boolean, default: true },
        maxWidth: {
            type: [String, Number],
            default: 300
        }
    },
    data: function () {
        return {};
    },
    computed: {
        p_title() {
            return this.title || this.$t('bestaetigt');
        },
        p_text() {
            return this.text || '<message>';
        },
        p_yesText() {
            return this.yesText || this.$t('yes');
        },
        p_noText() {
            return this.noText || this.$t('no');
        }
    },
    methods: {
        onNo() {
            this.$emit('no');
            if (this.closeOnNo) {
                this.$emit('input', false);
            }
        },
        onYes() {
            this.$emit('yes');
            if (this.closeOnYes) {
                this.$emit('input', false);
            }
        }
    }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}
</style>
