<template>
    <v-card>
        <v-card-title>{{ $t('choose_fk') }}</v-card-title>
        <v-card-subtitle>Die Förderkategorie bestimmt die Parameter für Ihr Projekt. Wählen Sie:</v-card-subtitle>
        <v-card-text>
            <FkGruppeList :kanton-kuerzel="kanton ? kanton.kuerzel : null" @input="emitValue" />
        </v-card-text>
        <v-card-actions>
            <v-btn color="action lighten-1 white--text" @click="$emit('back')">
                <v-icon left>fas fa-arrow-left</v-icon>
                {{ $t('back') }}
            </v-btn>
            <v-btn color="action lighten-1 white--text" :disabled="!apollonConfFk" @click="$emit('next')">
                {{ $t('next') }}
                <v-icon right>fas fa-arrow-right</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import FkGruppeList from 'js/components/foerderkategorie-config-list.vue';
import { Kanton } from 'js/models/Kanton';

export default {
    components: {
        FkGruppeList
    },
    props: {
        kanton: {
            type: Kanton,
            default: null
        }
    },
    data: function () {
        return {
            apollonConfFk: null
        };
    },
    computed: {},
    methods: {
        emitValue(value) {
            this.apollonConfFk = value;
            this.$emit('input', value);
            this.$emit('next');
        }
    }
};
</script>
