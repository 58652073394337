<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="9">
                <v-form autocomplete="on" @submit.prevent="login">
                    <v-card class="trans-bg">
                        <v-card-title>{{ $t('login') }}</v-card-title>
                        <v-card-text>
                            <kp-textfield
                                v-model="username"
                                :label="$t('email')"
                                :placeholder="$t('zb') + ' name@domain.com'"
                                autocomplete="on"
                                :autofocus="true"
                            />
                            <kp-textfield v-model="password" :label="$t('pw')" type="password" />
                            <router-link :to="{ name: 'reset' }"> {{ $t('forgot_pw') }} </router-link>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn to="/" :disabled="loading">{{ $t('cancel') }}</v-btn>
                            <v-btn
                                color="action white--text"
                                :loading="loading"
                                :disabled="loading"
                                type="submit"
                                @click.prevent="login"
                            >
                                {{ $t('absenden') }}
                            </v-btn>
                        </v-card-actions>

                        <v-fade-transition>
                            <v-card-text v-if="error">
                                <v-alert type="error">{{ error }}</v-alert>
                            </v-card-text>
                        </v-fade-transition>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    data: function () {
        return {
            username: null,
            password: null
        };
    },
    computed: {
        error: function () {
            return this.$store.state.benutzer.error;
        },
        loading: function () {
            return this.$store.state.loading;
        }
    },
    methods: {
        ...mapActions('benutzer', ['loginTry']),
        ...mapActions(['redirectAfterLogin']),
        async login() {
            try {
                let user = await this.loginTry({ username: this.username, password: this.password });
                if (user) {
                    this.redirectAfterLogin();
                }
            } catch (e) {
                this.error = e;
            }
        }
    }
};
</script>
