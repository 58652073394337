/**
 * Represents the zeus.apollon_fk_conf.conf.projekt value object - the Foerderkategorie config
 * json structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model } from 'vue-mc';
import { ProjektFeldGruppe } from './ProjektFeldGruppe';

export class Projekt extends Model {
    defaults() {
        return {
            feldgruppen: []
        };
    }

    mutations() {
        return {
            feldgruppen: (val) => (val ? val.map((gruppe) => new ProjektFeldGruppe(gruppe)) : [])
        };
    }
}
