/**
 * Routes: /liegenschaften/ and below
 *
 * Will me merged into the router's 'route' array
 */
import { getStore } from 'js/store';
import { awaitAsyncFunction } from 'js/lib/helpers';

const liegenschaftList = () =>
    import(/* webpackChunkName: "liegenschaft" */ 'js/views/auth/liegenschaft/LiegenschaftenList.vue');
const liegenschaftForm = () =>
    import(/* webpackChunkName: "liegenschaft" */ 'js/views/auth/liegenschaft/LiegenschaftForm.vue');

const routes = [
    {
        path: '/liegenschaften',
        name: 'liegenschaften',
        component: () => awaitAsyncFunction(liegenschaftList, getStore()),
        meta: {
            requiresAuth: true,
            title: 'Liegenschaften'
        }
    },
    {
        path: '/liegenschaften/:id',
        name: 'liegenschaft_edit',
        component: () => awaitAsyncFunction(liegenschaftForm, getStore()),
        meta: {
            requiresAuth: true,
            title: (to) => `Liegenschaft: ${to.params.id}`
        }
    }
];

export default routes;
