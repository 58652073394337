<template>
    <v-btn-toggle
        :value="value"
        tile
        class="d-flex flex-wrap"
        borderless
        mandatory
        active-class="primary darken-3 white--text"
        color="grey lighten-3 text-inactive"
        @change="onChange"
    >
        <v-btn
            v-for="item of items"
            :key="item.value"
            :value="item.value"
            :loading="item.value === value && loading"
            :disabled="item.value !== value && loading"
            :to="item.to"
            class="tab flex-grow-1"
            exact
        >
            <v-avatar
                v-if="item.avatar"
                size="24"
                left
                :color="item.value !== value ? 'grey lighten-3 text-inactive' : 'primary lighten-1 white--text'"
                class="mr-1"
            >
                <span :style="{ fontWeight: item.value === value ? 'bold' : 'normal' }">{{ item.avatar }}</span>
            </v-avatar>
            <v-avatar
                v-if="item.icon"
                size="32"
                left
                :color="item.value !== value ? 'grey lighten-3 text-inactive' : 'primary lighten-1 white--text'"
                class="mr-1"
            >
                <v-icon small :color="item.value !== value ? 'text-inactive' : 'white'">
                    {{ item.icon }}
                </v-icon>
            </v-avatar>
            <span
                :style="{ fontWeight: item.value === value ? 'bold' : 'normal' }"
                :class="{
                    'text-inactive': item.value !== value,
                    label: true,
                    'd-none': true,
                    'd-md-inline': true
                }"
                >{{ item.text }}
            </span>
        </v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
    props: {
        value: { required: true, default: null },
        /**
         * items is an Array of objects with:
         * {
         *      avatar: Avatar-Text, if any
         *      icon: Avatar icon (content of v-icon), instead of avatar
         *      value: The button's value
         *      text: The button's text
         * }
         */
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            oldValue: this.value
        };
    },
    methods: {
        onChange: function (val) {
            this.$emit('input', val);
            this.$emit('change', val, this.oldValue);
            this.oldValue = val;
        }
    }
};
</script>

<style lang="scss" scoped>
.tab {
    border-top: 1px solid #ccc !important;
}
.label {
    font-size: 0.95rem;
}
.text-inactive {
    color: #000;
}
</style>
