/**
 * Represents the zeus.apollon_fk_conf.conf.objekte value object - the Foerderkategorie config
 * json structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model } from 'vue-mc';
import { ObjektRahmen } from './ObjektRahmen';

export class Objekte extends Model {
    defaults() {
        return {
            visible: false,
            rahmen: {}
        };
    }

    mutations() {
        return {
            rahmen: (val) => {
                if (val) {
                    for (const key in val) {
                        val[key] = new ObjektRahmen(val[key]);
                    }
                }
                return val;
            }
        };
    }
}
