/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';
import dayjs from 'dayjs';
import { instance as api } from 'js/lib/api';

export class Liegenschaft extends BaseModel {
    defaults() {
        return {
            id: null,
            strasse: null,
            strassen_nummer: null,
            ort_id: null,
            gemeinde_id: null,
            ist_neubau: false,
            egid: null,
            baujahr: null,
            baujahr_ist_geschaetzt: false,
            parzellen_nummer: null,
            assekuranz_nummer: null,
            coord_x: null,
            coord_y: null,
            created: null,
            modified: null,
            ersteller_id: null,

            // View fields
            adresse: null,
            gemeinde_bezeichnung: null,
            kanton_kuerzel: null,
            kanton_name: null,
            ort_plz: null,
            ort: null,
            ort_kurz: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            ist_neubau: Boolean,
            baujahr_ist_geschaetzt: Boolean,
            created: dayjs,
            modified: dayjs
        };
    }

    entityName() {
        return 'liegenschaft';
    }

    /**
     * Fetches and returns the GWR information from the API.
     * Uses Ort-ID, Strasse, Nr.
     *
     * Returns a promise to a object with the GWR data if matched, else null.
     */
    async egidQuery() {
        let res = await api().get('/lookup/egidQuery', {
            params: {
                ort_id: this.ort_id,
                gemeinde_id: this.gemeinde_id,
                strasse: this.strasse,
                strassen_nummer: this.strassen_nummer
            }
        });
        if (res && res.data && res.data.data) {
            return res.data.data;
        }
    }
}

export class LiegenschaftCollection extends BaseCollection {
    options() {
        return {
            model: Liegenschaft
        };
    }

    entityName() {
        return 'liegenschaft';
    }
}
