<template>
    <v-form v-model="valid">
        <v-card class="mb-2" max-width="600px" color="grey lighten-4">
            <v-card-title>{{ $t('bankangaben') }}</v-card-title>
            <v-card-text>
                <kp-textfield
                    v-model="gesuch.data.kontoinformation.iban"
                    :label="$t('iban') + ' *'"
                    :readonly="readOnly"
                    :rules="[rules.required, rules.iban]"
                    @blur="formatIban"
                /><kp-textfield
                    v-model="gesuch.data.kontoinformation.name_finanzinstitut"
                    :label="$t('name_finanzinstitut') + ' *'"
                    :rules="[rules.required]"
                    :readonly="readOnly"
                />
                <PlzOrtSelect
                    :readonly="readOnly"
                    :plz="gesuch.data.kontoinformation.plz_finanzinstitut"
                    :ort="gesuch.data.kontoinformation.ort_finanzinstitut"
                    @plzselect="gesuch.data.kontoinformation.plz_finanzinstitut = $event"
                    @ortselect="gesuch.data.kontoinformation.ort_finanzinstitut = $event"
                ></PlzOrtSelect>
                <kp-textfield
                    v-model="gesuch.data.kontoinformation.vermerk"
                    :label="$t('vermerk')"
                    rows="3"
                    auto-grow
                ></kp-textfield>
            </v-card-text>
        </v-card>
        <v-card class="mb-2" max-width="600px" color="grey lighten-4">
            <v-card-title>{{ $t('kontoinhaber') }}</v-card-title>
            <v-card-text>
                <kp-textfield
                    v-model="gesuch.data.kontoinformation.name_kontoinhaber"
                    :label="$t('name_kontoinhaber') + ' *'"
                    :readonly="readOnly"
                    :rules="[rules.required]"
                />
                <kp-textfield
                    v-model="gesuch.data.kontoinformation.adresse_kontoinhaber"
                    :label="$t('strasse_und_nr') + ' *'"
                    :rules="[rules.required]"
                    :readonly="readOnly"
                />
                <PlzOrtSelect
                    :readonly="readOnly"
                    :rules="[rules.required]"
                    :plz="gesuch.data.kontoinformation.plz_kontoinhaber"
                    :ort="gesuch.data.kontoinformation.ort_kontoinhaber"
                    @plzselect="gesuch.data.kontoinformation.plz_kontoinhaber = $event"
                    @ortselect="gesuch.data.kontoinformation.ort_kontoinhaber = $event"
                ></PlzOrtSelect>
            </v-card-text>
        </v-card>
        <span style="padding: 5px;">{{ $t('pflichtfelder_markiert') }}</span>
    </v-form>
</template>

<script>
import { formRules } from 'js/lib/helpers.js';
import PlzOrtSelect from 'js/components/plz-ort-select.vue';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        PlzOrtSelect,
        KpTextfield
    },
    props: {
        gesuch: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            rules: formRules,
            valid: false
        };
    },
    computed: {},
    watch: {},
    methods: {
        formatIban() {
            var tempValue = '',
                pattern = 'xxxx xxxx xxxx xxxx xxxx x',
                pLength = pattern.length,
                value = this.gesuch.data.kontoinformation.iban,
                vLenght = value ? value.length : 0,
                currentPosition = 0;

            if (
                value &&
                value.substr(0, 2).toUpperCase() === 'CH' &&
                ((pLength !== vLenght && value.split(' ').join('').length === pattern.split(' ').join('').length) ||
                    value.split(' ').join('').length === pattern.split(' ').join('').length)
            ) {
                value = value.split(' ').join('');
                for (var i = 0; i < pLength; i++) {
                    var letter = pattern[i];
                    if (letter === 'x') {
                        if (value[currentPosition]) {
                            tempValue += value[currentPosition];
                        } else {
                            tempValue += ' ';
                        }
                        currentPosition++;
                    } else {
                        tempValue += letter;
                    }
                }
            } else {
                tempValue = value ? value.split(' ').join('') : value;
            }
            this.gesuch.data.kontoinformation.iban = tempValue;
        }
    }
};
</script>
