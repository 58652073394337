<template>
    <div style="max-width: 950px;">
        <v-expansion-panels>
            <v-expansion-panel v-for="gruppe in gruppen" :key="`d-${gruppe.id}`">
                <v-expansion-panel-header>
                    {{ gruppe.bezeichnung }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list three-line>
                        <v-list-item-group v-model="apollonConfFk" color="primary" @change="onSelect">
                            <v-subheader v-if="gruppe.hilfetext" :key="`sh-${gruppe.id}`">
                                {{ gruppe.hilfetext }}
                            </v-subheader>
                            <v-divider :key="`d-${gruppe.id}`" />
                            <v-list-item
                                v-for="kat in apollonFkConfigList[gruppe.id || ' ']"
                                :key="kat.id"
                                :value="kat"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{ kat.bezeichnung_apollon }}</v-list-item-title>
                                    <div v-if="kat.hilfetext" style="color: #aaa;">
                                        <span v-html="kat.hilfetext"></span>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                        <v-overlay absolute :value="loading">
                            <v-progress-circular indeterminate />
                        </v-overlay>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ApollonConfFk } from 'js/models/ApollonConfFk';
import { FoerderkategorieGruppe } from 'js/models/FoerderkategorieGruppe';

/**
 * Loads ApollonConfFk records that defines the valid
 * Foerderkategorie/Kantone to create new Gesuche from.
 *
 * Creates a selectable list with FK-Groups and FKs,
 * filtered by a given Kanton, if needed.
 *
 * Emits the selected ApollonConfFk model. Note that this is
 * not the FULL model, but only a partial, virtual representation
 * with ID and a display name.
 */
export default {
    props: {
        value: {
            type: [ApollonConfFk, undefined],
            default: null
        },
        kantonKuerzel: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            apollonConfFk: null,
            gruppen: {}, // map of id => FoerderkategorieGruppe
            apollonFkConfigList: {} // map of fk-gruppe-id => [ApollonConfFk]
        };
    },

    computed: {
        ...mapState('apollonConfFk', ['records', 'loading'])
    },

    watch: {
        kantonKuerzel(kantonKuerzel) {
            this.buildGruppeTree(kantonKuerzel);
        },
        records() {
            this.buildGruppeTree(this.kantonKuerzel);
        }
    },

    created() {
        this.fetchApollonConfFk();
    },

    methods: {
        ...mapActions('apollonConfFk', ['fetchApollonConfFk']),
        onSelect(value) {
            this.apollonConfFk = value;
            this.$emit('input', value);
        },
        buildGruppeTree(kantonKuerzel) {
            kantonKuerzel = (kantonKuerzel || '').toLowerCase();
            let gruppen = {};
            let apollonFkConfigList = {};

            // Build FK-Gruppe and FK maps for the given Kanton:
            if (this.records && this.records.length > 0) {
                this.records.forEach((conf) => {
                    if (conf.kanton_kuerzel.toLowerCase() !== kantonKuerzel) {
                        return;
                    }
                    // Groups:
                    let gruppe = new FoerderkategorieGruppe({
                        id: conf.fk_gruppe_id,
                        bezeichnung: conf.foerderkategorie_gruppe,
                        hilfetext: conf.foerderkategorie_gruppe_hilfetext
                    });
                    let gruppe_id = conf.fk_gruppe_id || ' ';
                    gruppen[gruppe_id] = gruppe;

                    if (!apollonFkConfigList[gruppe_id]) {
                        apollonFkConfigList[gruppe_id] = [];
                    }

                    apollonFkConfigList[gruppe_id].push(conf);
                });
            }
            this.gruppen = gruppen;
            this.apollonFkConfigList = apollonFkConfigList;
        }
    }
};
</script>
