<template>
    <div>
        <v-row v-if="multi">
            <v-col v-for="kantonObj in kantone" :key="kantonObj.kuerzel" :value="kantonObj" cols="6" sm="2" md="4">
                <v-checkbox v-model="kantonMulti" :value="kantonObj.kuerzel" @change="onMultiSelect">
                    <template v-slot:label>
                        <img :src="kantonObj.icon" width="24px" class="mr-2" />
                        {{ kantonObj.kurz }}
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-radio-group v-else :value="value" @change="onSelect">
            <v-radio v-for="kantonObj in kantone" :key="kantonObj.kuerzel" :value="kantonObj">
                <template v-slot:label>
                    <img :src="kantonObj.icon" width="24px" class="mr-2" />
                    {{ kantonObj.kurz }}
                </template>
            </v-radio>
        </v-radio-group>
    </div>
</template>

<script>
import { Kanton } from 'js/models/Kanton';

export default {
    props: {
        value: {
            type: [Kanton, Array, undefined],
            default: null
        },
        multi: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            kanton: null,
            kantonMulti: [],
            kantone: [this.getKantonByKuerzel('TG'), this.getKantonByKuerzel('SH')]
        };
    },
    mounted() {
        if (this.value) {
            if (Array.isArray(this.value)) {
                this.kantonMulti = this.value;
            } else {
                this.kantonMulti = [this.value];
            }
        }
    },
    methods: {
        getKantonByKuerzel(kuerzel) {
            return this.$store.getters['kanton/getKantonByKuerzel'](kuerzel);
        },
        onSelect(value) {
            this.kanton = value;
            this.$emit('input', value);
        },
        onMultiSelect(value) {
            this.$emit('input', value);
        }
    }
};
</script>
