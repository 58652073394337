/**
 * Routes: / and some special routes, but all authenticated.
 *
 * Will me merged into the router's 'route' array
 */
import Home from 'js/views/auth/Home.vue';
import ChangePw from 'js/views/auth/ChangePw.vue';
import ChangeEmail from 'js/views/auth/ChangeEmail.vue';
import { vueI18n } from 'js/lib/i18n';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/change_pw',
        name: 'change_pw',
        component: ChangePw,
        meta: {
            requiresAuth: true,
            title: vueI18n.t('change_my_password')
        }
    },
    {
        path: '/change_email',
        name: 'change_email',
        component: ChangeEmail,
        meta: {
            requiresAuth: true,
            title: vueI18n.t('change_my_email')
        }
    }
];

export default routes;
