<template>
    <v-app id="guest-app">
        <Toast></Toast>
        <AppHeaderBarGuest v-if="$route.name !== 'welcome' && customer === 'default'" app />
        <AppHeaderBarGuestTG v-if="$route.name !== 'welcome' && customer === 'tg'" app />
        <AppHeaderBarGuestSH v-if="$route.name !== 'welcome' && customer === 'sh'" app />
        <v-main class="white lighten-2">
            <v-fade-transition mode="out-in">
                <router-view />
            </v-fade-transition>
        </v-main>
        <KpFooter v-if="customer === 'default'" app />
        <KpFooterTG v-if="customer === 'tg'" app />
        <KpFooterSH v-if="customer === 'sh'" app />
    </v-app>
</template>

<script>
import Toast from 'js/components/Toast.vue';
import AppHeaderBarGuest from 'js/components/default-customer/app-header-bar-guest.vue';
import AppHeaderBarGuestTG from 'js/components/tg/app-header-bar-guest.vue';
import AppHeaderBarGuestSH from 'js/components/sh/app-header-bar-guest.vue';
import KpFooter from 'js/components/default-customer/app-footer.vue';
import KpFooterTG from 'js/components/tg/app-footer.vue';
import KpFooterSH from 'js/components/sh/app-footer.vue';

export default {
    components: {
        AppHeaderBarGuest,
        Toast,
        AppHeaderBarGuestTG,
        AppHeaderBarGuestSH,
        KpFooter,
        KpFooterTG,
        KpFooterSH
    },
    computed: {
        customer() {
            return this.$store.state.customer;
        }
    }
};
</script>
