<template>
    <v-text-field :value="value" v-bind="{ ...$props, ...$attrs }" @blur="onBlur" @input="(v) => $emit('input', v)">
    </v-text-field>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null }
    },
    methods: {
        onBlur(args) {
            this.$emit('input', this.value ? this.value.trim() : this.value);
            this.$emit('blur', args);
        }
    }
};
</script>
