/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import { Foerderkategorie } from './Foerderkategorie';

export class FoerderkategorieGruppe extends BaseModel {
    defaults() {
        return {
            id: null,
            bezeichnung: null,
            hilfetext: null,
            reihenfolge: null,
            kategorien: []
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            kategorien: (kats) => (kats ? kats.map((k) => new Foerderkategorie(k)) : [])
        };
    }

    entityName() {
        return 'foerderkategorieGruppe';
    }
}
