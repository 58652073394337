<template>
    <div>
        <v-toolbar color="primary white--text" class="colored-toolbar">
            <v-toolbar-title>
                <v-icon left color="white">fas fa-at</v-icon>{{ $t('change_my_email') }}
            </v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-form ref="form" v-model="valid">
                <kp-textfield
                    v-model="newEmail"
                    type="text"
                    :label="$t('new_email')"
                    prepend-icon="fas fa-at"
                    :rules="[newEmail !== '', formRules.email, formRules.maxLength(255)]"
                />
                <kp-textfield
                    v-model="password"
                    type="password"
                    :label="$t('old_password')"
                    prepend-icon="fas fa-key"
                    :rules="[formRules.required]"
                />
                <v-btn
                    color="action white--text"
                    :disabled="!valid || loading"
                    :loading="loading"
                    @click="onChangeEmail"
                >
                    {{ $t('change_my_email') }}
                </v-btn>
            </v-form>
        </v-container>
        <v-dialog v-model="showConfirmation" :max-width="600">
            <v-card>
                <slot name="title">
                    <v-card-title class="headline">{{ $t('confirm_email_sent') }}</v-card-title>
                </slot>
                <slot>
                    <v-card-text>{{ $t('email_change_successful_txt', { email: newEmail }) }}</v-card-text>
                </slot>
                <slot name="actions">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="action" :loading="loading" @click="logout">
                            <v-icon left>fas fa-check</v-icon>
                            {{ $t('ok') }}
                        </v-btn>
                    </v-card-actions>
                </slot>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { instance as getApi } from 'js/lib/api.js';
import { errToStr } from 'js/lib/helpers.js';
import { formRules } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    data: function () {
        return {
            formRules,
            loading: false,
            newEmail: '',
            valid: false,
            password: '',
            showConfirmation: false
        };
    },
    methods: {
        ...mapActions(['runAsync']),
        async onChangeEmail() {
            this.loading = true;
            this.runAsync(async () => {
                try {
                    await getApi().post('/auth/changeEmail', {
                        new_email: this.newEmail,
                        password: this.password
                    });
                    this.showConfirmation = true;
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                } finally {
                    this.loading = false;
                }
            });
        },

        logout() {
            this.$router.push({ name: 'home' });
            document.location.reload();
        }
    }
};
</script>
