/**
 * Store module: adressen
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { Adresse, AdresseCollection } from 'js/models/Adresse';

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        /**
         * Update adress data
         *
         * @param {Adresse} address An Adress entity record
         */
        async storeAdresse({ commit, rootState, dispatch }, address) {
            commit('loading', true, { root: true });
            try {
                let res = await address.save();
                if (res.getData().id === rootState.benutzer.record.profilAdresse.id) {
                    commit('benutzer/updateProfilAdresse', Adresse.fromData(res.getData()), { root: true });
                }
            } finally {
                commit('loading', false, { root: true });
            }
        },

        /**
         * Delete address record
         *
         * @param {Adresse} address An Adress entity record
         */
        async destroyAdresse({ commit }, address) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                // await getApi().post('/adressen/destroy', addressData);
                await address.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         *
         * @param {Object} store The vuex store
         * @param {Object} options Future options like query params, paging etc.
         */
        async fetchAdressen({ commit }, options = {}) {
            let list = new AdresseCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        /**
         * Loads / creates a Adresse entity.
         *
         * If id is 'new', a new one will be created, otherwise loaded from the backend.
         *
         * @param {Object} store The vuex store
         * @param {Number|String} id The id to load, or 'new'
         */
        async loadAdresse({ commit, rootState }, options) {
            let adresse = null;
            try {
                commit('loading', true, { root: true });
                if (!options.id || options.id === 'new') {
                    return new Adresse();
                } else if (options.id === 'profil') {
                    adresse = new Adresse({ id: rootState.benutzer.record.adresse_id });
                } else {
                    adresse = new Adresse({ id: options.id });
                }
                await adresse.fetch();
            } finally {
                commit('loading', false, { root: true });
            }
            return adresse;
        }
    }
};
