/**
 * Store module: eigentuemerschaft
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { Eigentuemerschaft } from 'js/models/Eigentuemerschaft';
import { LookupCollection } from 'js/models/BaseCollection';

export default {
    namespaced: true,
    state: {
        records: null,
        loading: false,
        loadingCounter: 0
    },
    mutations: {
        loading(state, loading) {
            if (loading) {
                state.loadingCounter++;
            } else {
                state.loadingCounter = Math.max(0, state.loadingCounter - 1);
            }
            state.loading = state.loadingCounter > 0;
        },
        setEigentuemerschaften(state, anreden) {
            state.records = anreden;
        }
    },
    actions: {
        /**
         *
         * @param {Object} store The vuex store
         */
        async fetchEigentuemerschaften({ commit, state }) {
            if (state.records !== null || state.loading) {
                return state.records;
            }
            try {
                commit('loading', true);
                let collection = new LookupCollection(null, { model: Eigentuemerschaft });
                await collection.query({});
                commit('setEigentuemerschaften', collection);
            } finally {
                commit('loading', false);
            }
            return state.records;
        }
    }
};
