/**
 * Store module: liegenschaften
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { Liegenschaft, LiegenschaftCollection } from 'js/models/Liegenschaft';

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        /**
         * Update liegenschaft data
         *
         * @param {Object} store The vuex store
         * @param {Liegenschaft} liegenschaft A Liegenschaft entity record
         */
        async storeLiegenschaft({ commit }, foobar) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                let response = await foobar.save();
                if (response.response) {
                    res = response.response.data;
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         * Delete liegenschaft record
         *
         * @param {Object} store The vuex store
         * @param {Liegenschaft} liegenschaft A Liegenschaft entity record
         */
        async destroyLiegenschaft({ commit }, liegenschaft) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await liegenschaft.delete();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         *
         * @param {Object} store The vuex store
         * @param {Object} options Future options like query params, paging etc.
         */
        async fetchLiegenschaften({ commit }, options = {}) {
            let list = new LiegenschaftCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        /**
         * Loads / creates a Liegenschaft entity.
         *
         * If id is 'new', a new one will be created, otherwise loaded from the backend.
         *
         * @param {Object} store The vuex store
         * @param {Number|String} id The id to load, or 'new'
         */
        async loadLiegenschaft({ commit }, options) {
            let liegenschaft = null;
            try {
                commit('loading', true, { root: true });
                if (!options.id || options.id === 'new') {
                    liegenschaft = new Liegenschaft();
                } else {
                    liegenschaft = new Liegenschaft({ id: options.id });
                    await liegenschaft.fetch();
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return liegenschaft;
        }
    }
};
