<template>
    <v-autocomplete
        autocomplete="new-password"
        v-bind="{ ...$props, ...$attrs }"
        :value="value"
        :readonly="readOnly"
        :items="orteCollection ? orteCollection.models : []"
        :loading="orteIsLoading"
        :search-input.sync="ortSearch"
        prepend-icon="fas fa-search"
        item-text="plz_ort"
        item-value="id"
        :clearable="!readOnly"
        clear-icon="fas fa-times"
        @input="onSelect"
    >
    </v-autocomplete>
</template>

<script>
import { debounce } from 'js/lib/helpers.js';
import { Ort } from 'js/models/Ort';
import { LookupCollection } from 'js/models/BaseCollection';

/**
 * Ort-Select
 *
 * loads Orte from /lookup/ort,
 * emits the selected
 */
export default {
    props: {
        value: {
            type: Number,
            default: null
        },
        readOnly: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            orteCollection: null,
            orteIsLoading: false,
            ortSearch: null
        };
    },

    watch: {
        ortSearch: debounce(async function (val) {
            if (!val || val.length < 3) {
                return;
            }
            if (!this.orteCollection) {
                this.orteCollection = new LookupCollection(null, { model: Ort });
            } else {
                this.orteCollection = this.orteCollection.filter((ort) => `${ort.plz_ort}` === val);
            }
            if (!this.orteCollection.models.length) {
                await this.loadOrte({ query: val });
            }
        })
    },

    created() {
        if (this.value) {
            this.loadOrte({ id: this.value });
        }
    },

    methods: {
        onSelect(value) {
            this.$emit('input', value);
        },

        async loadOrte(query) {
            this.orteIsLoading = true;
            if (!this.orteCollection) {
                this.orteCollection = new LookupCollection(null, { model: Ort });
            }
            await this.orteCollection.query(query);
            this.orteIsLoading = false;
        }
    }
};
</script>
