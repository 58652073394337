<template>
    <v-container fluid>
        <v-card-actions>
            <v-btn v-if="!errorMsg" :loading="loading" @click="download">
                <v-icon left>fas fa-download</v-icon>
                {{ $t(type) }} {{ $t('download') }}
            </v-btn>
            <v-spacer />
            <v-btn class="mt-2" color="action" :loading="loading" @click="$emit('next')">
                <v-icon left>fas fa-arrow-right</v-icon>
                {{ $t('next_step') }}
            </v-btn>
        </v-card-actions>
        <v-alert v-if="errorMsg" type="info" :icon="false" border="left" colored-border elevation="2">
            <span>{{ $t('no_file') }}</span>
        </v-alert>
        <v-card
            v-if="!errorMsg"
            :loading="loadedRatio >= 0 && loadedRatio < 1"
            elevation="10"
            class="mt-2 pa-0"
            max-width="700pt"
        >
            <v-pagination v-if="numPages > 1" v-model="page" :length="numPages" circle></v-pagination>
            <v-card-text>
                <VuePdfEmbed
                    :source="src"
                    ref="pdfRef"
                    :page="page"
                    @progress="loadedRatio = $event"
                    @loading-failed="error"
                    @rendering-failed="error"
                    @printing-failed="error"
                    @internal-link-clicked="numPages = $event"
                    @rendered="handleDocumentRender"
                ></VuePdfEmbed>
            </v-card-text>
            <v-pagination v-if="numPages > 1" v-model="page" :length="numPages" circle></v-pagination>
        </v-card>
    </v-container>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { buildUrl } from 'js/lib/api.js';
import { mapActions } from 'vuex';
export default {
    components: {
        VuePdfEmbed
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        gesuch: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: null,
            required: true
        }
    },
    data() {
        return {
            numPages: 0,
            page: 1,
            loadedRatio: 0,
            errorMsg: false,
            src: ''
        };
    },

    mounted() {
        setTimeout(
            () => (this.src = buildUrl('/gesuch/getFoerderDok', { gesuch_id: this.gesuch.id, type: this.type })),
            1
        );
    },
    methods: {
        ...mapActions('gesuch', ['getFoerderDok']),
        loadPdf() {
            let url = buildUrl('/gesuch/getFoerderDok', {
                gesuch_id: this.gesuch.id,
                type: this.type
            });
            this.$refs.pdfRef.document = url;
        },
        download() {
            let url = buildUrl('/gesuch/getFoerderDok', {
                gesuch_id: this.gesuch.id,
                type: this.type
            });
            window.open(url, 'apollon-foerderdok-dl');
        },
        error(err) {
            this.errorMsg = true;
            this.loadedRatio = -1;
        },
        handleDocumentRender() {
            this.numPages = this.$refs.pdfRef.pageCount;
        }
    }
};
</script>
