var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('choose_org')))]),(_vm.rolle === 'unternehmer')?_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('choose_or_create_org_force')))]):_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('choose_or_create_org')))]),_c('v-card-text',[(_vm.rolle !== 'unternehmer')?_c('v-checkbox',{attrs:{"label":_vm.$t('org_person')},model:{value:(_vm.showOrgGrid),callback:function ($$v) {_vm.showOrgGrid=$$v},expression:"showOrgGrid"}}):_vm._e(),(_vm.showOrgGrid)?_c('AdressenGrid',{attrs:{"edit":false,"dense":false,"selectable":true,"height":300,"addbtn":_vm.$t('new_organisation'),"headerwidths":{
                typ: '5%',
                name: '18%',
                vorname: '0%',
                adresse: '25%',
                land: '0%',
                plz: '14%',
                ort: '18%',
                action: '0%'
            },"selection":_vm.adresse,"typfilter":_vm.typFilter,"reload":_vm.reloadGrid},on:{"selection":_vm.onSelect,"reload":function($event){_vm.reloadGrid = false},"new-address":_vm.onNewOrg}}):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"action lighten-1 white--text"},on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t('back'))+" ")],1),_c('v-btn',{attrs:{"disabled":_vm.nextDisabled,"color":"action lighten-1 white--text"},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fas fa-arrow-right")])],1)],1),(_vm.showAddOrgDlg)?_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.showAddOrgDlg),callback:function ($$v) {_vm.showAddOrgDlg=$$v},expression:"showAddOrgDlg"}},[_c('v-card',[_c('AdresseForm',{attrs:{"new-address":_vm.newOrg,"avoid-type-edit":""},on:{"close":_vm.onNewOrgClose}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }