/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';

export class Adresse extends BaseModel {
    constructor(attributes = {}, collection = null, options = {}) {
        attributes = attributes || {};
        delete attributes.adresse;
        delete attributes.display_name;
        delete attributes.plz_ort;
        super(attributes, collection, options);
    }

    static get TYP_ORGANISATION() {
        return 'organisation';
    }
    static get TYP_PERSON() {
        return 'person';
    }

    defaults() {
        return {
            id: null,
            ersteller_id: null,
            typ: null,
            anrede: null,
            vorname: null,
            nachname: null,
            organisation_name: null,
            organisation_tel_1: null,
            organisation_tel_2: null,
            organisation_email: null,
            strasse: null,
            strasse_nr: null,
            adresszusatz: null,
            postfach: null,
            plz: null,
            ort: null,
            kanton_kuerzel: null,
            tel_1: null,
            tel_2: null,
            email: null,
            created: null,
            modified: null,
            uid: null,
            land_id: null,
            anrede2: null,
            vorname2: null,
            nachname2: null,
            eigentuemerschaft: null,
            benutzer_id: null,

            // View fields:
            laendercode: null,
            land: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            land_id: (id) => (Number(id) > 0 ? Number(id) : null)
        };
    }

    get adresse() {
        return [this.strasse, this.strasse_nr].filter((e) => e !== null).join(' ');
    }

    get display_name() {
        if (this.typ === 'organisation') {
            return this.organisation_name;
        }
        return this.nachname;
    }

    get plz_ort() {
        return [this.plz, this.ort].filter((e) => e !== null).join(' ');
    }

    get vorname_name() {
        return [this.vorname, this.nachname].filter((e) => e !== null).join(' ');
    }

    entityName() {
        return 'adressen';
    }
}

export class AdresseCollection extends BaseCollection {
    options() {
        return {
            model: Adresse
        };
    }

    entityName() {
        return 'adressen';
    }
}
