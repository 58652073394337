<template>
    <v-dialog :value="value" persistent :max-width="600" @click:outside="$emit('cancel')">
        <v-card>
            <slot name="title">
                <v-card-title class="headline">{{ $t('profile_missing') }}</v-card-title>
            </slot>
            <slot>
                <v-card-text>{{ $t('profile_missing_for_gesuch') }}</v-card-text>
            </slot>
            <slot name="actions">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text :loading="loading" @click="$emit('cancel')">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="action white--text" :disabled="loading" @click="onProfile">
                        {{ $t('edit_profile') }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {};
    },
    computed: {
        benutzer() {
            return this.$store.state.benutzer.record;
        }
    },
    methods: {
        onProfile() {
            this.$router.push('/adressen/profil');
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}
</style>
