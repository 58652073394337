<template>
    <v-dialog :value="value" :max-width="maxWidth" persistent>
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text v-if="text">
                {{ text }}
            </v-card-text>
            <v-card-text>
                <v-btn ref="chooseBtn" color="primary white--text" justify-center :disabled="progress">
                    <v-icon left class="small-icon">far fa-folder-open</v-icon>
                    {{ $t('choose_file') }}
                </v-btn>
                <span v-if="file" class="ml-3">{{ file.name }}</span>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red" text :disabled="progress" @click="$emit('input', false)">
                    {{ $t('cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn v-if="file" color="action white--text" justify-center :loading="progress" @click="startUpload">
                    <v-icon left class="small-icon">fas fa-upload</v-icon>
                    {{ $t('upload_doc') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { vueI18n } from 'js/lib/i18n';
import Flow from '@flowjs/flow.js';
import { getCsrfToken } from 'js/lib/auth';
import { errToStr } from 'js/lib/helpers.js';

export default {
    props: {
        value: { type: Boolean, default: false },
        title: { type: String, default: vueI18n.t('upload_doc') },
        text: { type: String, default: '' },
        target: { type: String, required: true },
        maxWidth: { type: [String, Number], required: false, default: null },
        query: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            progress: false,
            flow: null,
            file: null
        };
    },

    mounted() {
        this.flow = new Flow({
            singleFile: true,
            target: this.target,
            testChunks: false,
            chunkSize: (flowFile) => flowFile.size,

            query: this.query,
            headers: {
                'X-CSRF-Token': getCsrfToken()
            }
        });
        this.flow.on('fileAdded', this.onFlowFileAdded.bind(this));
        this.flow.on('fileSuccess', this.onFlowFileSuccess.bind(this));
        this.flow.on('fileError', this.onFlowFileError.bind(this));

        this.flow.assignBrowse(this.$refs.chooseBtn.$el, false, true, {
            accept: '.doc,.docx,.docm,.pdf,.png,.jpg,.jpeg,.tif,.tiff,.gif,.msg,.xls,.xlsx,.xlsm,.heic'
        });
    },

    beforeDestroy() {
        this.flow.off();
    },

    methods: {
        onFlowFileAdded(file) {
            this.file = file;
        },
        onFlowFileSuccess(file, msg) {
            this.file = null;
            this.progress = false;
            try {
                let response = JSON.parse(msg);
                let beilageInfo = response.record;
                this.$emit('uploadsuccess', beilageInfo);
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },
        onFlowFileError(file, msg) {
            this.file = null;
            this.$toast({ message: errToStr(msg), color: 'error' });
            this.$emit('uploadfailed', msg);
        },
        startUpload() {
            this.progress = true;
            this.flow.upload();
        }
    }
};
</script>
