<template>
    <v-container>
        <v-fade-transition mode="out-in">
            <v-card v-if="mode === 'register'">
                <v-card-title>{{ $t('register') }}</v-card-title>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <v-card-text v-html="$t('register_lead_text')"> </v-card-text>
                <v-divider />
                <v-card-text>
                    <v-form v-model="valid" @submit.stop="onSubmit">
                        <kp-textfield
                            v-model="formData.email"
                            :label="$t('emailaddress')"
                            placeholder="name@beispiel.com"
                            type="email"
                            autofocus
                            dense
                            :rules="[rules.required, rules.email]"
                        />
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="$router.go(-1)">{{ $t('back') }}</v-btn>
                    <v-btn
                        color="primary"
                        type="submit"
                        :disabled="!valid || loading"
                        :loading="loading"
                        @click="onSubmit"
                    >
                        {{ $t('register') }}!
                        <v-icon right>fas fa-check fa-xs</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-slide-y-transition>
                    <v-alert v-if="error" type="error" dense tile>{{ error }}</v-alert>
                </v-slide-y-transition>
            </v-card>

            <v-card v-if="mode === 'confirmation'">
                <v-card-title>{{ $t('thanks') }}!</v-card-title>
                <v-card-text>
                    <p>
                        {{ $t('activation_mail_info', { email: formData.email }) }}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn to="/" color="primary">{{ $t('back') }}</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-fade-transition>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { instance as getApi } from 'js/lib/api.js';
import { errToStr } from 'js/lib/helpers.js';
import { formRules } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    data() {
        return {
            mode: 'register',
            valid: false,
            error: null,
            formData: {
                email: ''
            },

            rules: formRules
        };
    },
    computed: {
        ...mapState({
            loading: 'loading'
        })
    },

    methods: {
        ...mapActions(['runAsync']),
        async onSubmit() {
            this.error = null;
            this.runAsync(async () => {
                try {
                    await getApi().post('/auth/register', this.formData);
                    this.mode = 'confirmation';
                } catch (e) {
                    this.error = errToStr(e);
                }
            });
        },
        kantonDisplay(value) {
            return value.toUpperCase();
        }
    }
};
</script>

<style scoped>
.fancy {
    color: Tomato;
}
</style>
