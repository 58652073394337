<template>
    <v-expansion-panel
        :disabled="mitteilung.is_deleted"
        :class="{ 'msg-deleted': mitteilung.is_deleted }"
        @change="$emit('change')"
    >
        <v-expansion-panel-header>
            <div class="msg-header">
                <v-avatar>
                    <v-icon v-if="mitteilung.is_deleted">fas fa-trash</v-icon>
                    <v-icon v-else-if="mitteilung.mode === MODE.BROADCAST">fas fa-bullhorn</v-icon>
                    <v-icon v-else-if="mitteilung.mode === MODE.ENERGIEFACHSTELLE">far fa-calendar-plus</v-icon>
                    <v-icon v-else-if="fullyRead">far fa-envelope-open</v-icon>
                    <v-icon v-else>far fa-envelope</v-icon>
                </v-avatar>
                <div class="msg-title">
                    <div>
                        {{ titletext }}
                    </div>
                    <div v-if="mitteilung.is_deleted !== true">
                        <v-icon v-if="mitteilung.attachments && mitteilung.attachments.length > 0" small>
                            fas fa-paperclip
                        </v-icon>
                        {{ mitteilung.subject }}
                    </div>
                    <div v-if="mitteilung.is_deleted === true">
                        {{ $t('deleted_at') }}: {{ mitteilung.deleted_at.format('DD.MM.YYYY') }}
                    </div>
                </div>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="mitteilung.is_deleted !== true">
            <div v-if="mitteilung.apollon_ersteller_id === user.id && mitteilung.mode !== MODE.ENERGIEFACHSTELLE">
                <v-form v-model="formValid">
                    <kp-textfield
                        v-model="mitteilung.subject"
                        :label="$t('betreff')"
                        :rules="[formRules.required]"
                    ></kp-textfield>
                    <v-textarea
                        v-model="mitteilung.mitteilung"
                        :label="$t('mitteilung')"
                        :rules="[formRules.required]"
                    ></v-textarea>
                </v-form>
            </div>
            <div v-else style="white-space: pre-line;">
                {{ mitteilung.mitteilung }}
            </div>
            <v-card v-if="mitteilung.attachments && mitteilung.attachments.length" class="mt-5">
                <v-card-title>{{ $t('anhaenge') }}</v-card-title>
                <v-list dense class="pt-0">
                    <template v-for="att in mitteilung.attachments">
                        <v-list-item :key="att.id" dense @click.stop="onDAttachmentClick(att)">
                            <v-list-item-avatar>
                                <v-icon>fas fa-file-alt</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ att.orig_filename }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ formatDate(att.created) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon color="action white--text" small>fas fa-download</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="mitteilung.apollon_ersteller_id === user.id"
                    icon
                    color="action white--text"
                    :disabled="!formValid || mitteilung.is_deleted"
                    @click="save"
                >
                    <v-icon small>fas fa-save</v-icon>
                </v-btn>
                <v-btn
                    v-if="mitteilung.apollon_ersteller_id === user.id"
                    icon
                    color="stop white--text"
                    :disabled="!formValid || mitteilung.is_deleted"
                    @click="deleteMsg"
                >
                    <v-icon small>fas fa-trash</v-icon>
                </v-btn>
            </v-card-actions>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapActions } from 'vuex';
import { formRules, errToStr } from 'js/lib/helpers.js';
import { buildUrl } from 'js/lib/api';
import { MODE } from 'js/models/Mitteilung.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    props: {
        mitteilung: {
            type: Object,
            default: null
        },
        gesuch: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            value: false,
            formRules,
            formValid: false,
            MODE: MODE
        };
    },
    computed: {
        ...mapState('benutzer', {
            user: 'record'
        }),
        created() {
            return dayjs(this.mitteilung.created).format('DD.MM.YYYY HH:mm');
        },
        fullyRead() {
            return (
                this.mitteilung.gelesen_rollen &&
                this.mitteilung.gelesen_rollen.length === this.mitteilung.empfaenger_rollen.length
            );
        },
        ersteller_rolle() {
            return this.getRolleBezeichnung(this.mitteilung.ersteller_rolle);
        },
        empfaenger_rollen() {
            if (this.mitteilung.empfaenger_rollen) {
                return this.mitteilung.empfaenger_rollen
                    .map((item) => {
                        return this.getRolleBezeichnung(item);
                    })
                    .join(', ');
            }
            return '';
        },
        titletext() {
            var res = this.created + ' | ' + this.ersteller_rolle + ' (' + this.mitteilung.ersteller;
            if (this.mitteilung.ersteller_telefon) {
                res += ' ' + this.mitteilung.ersteller_telefon;
            }
            res += ')';
            switch (this.mitteilung.mode) {
                case MODE.STANDARD:
                    res += ' → ' + this.empfaenger_rollen;
                    break;
                case MODE.BROADCAST:
                    res += ' | ' + this.$t('gesuch_comment');
                    break;
                case MODE.ENERGIEFACHSTELLE:
                    res += ' → ' + this.$t('bearbeiter');
                    break;
            }
            return res;
        }
    },
    methods: {
        ...mapActions('mitteilung', ['storeMitteilung', 'destroyMitteilung']),
        async save() {
            try {
                await this.storeMitteilung(this.mitteilung);
            } catch (res) {
                console.error(res);
                this.$toast({ message: errToStr(res), color: 'error' });
            }
        },
        async deleteMsg() {
            try {
                await this.destroyMitteilung(this.mitteilung);
                this.$emit('mitteilungdestroyed');
            } catch (res) {
                console.error(res);
                this.$toast({ message: errToStr(res), color: 'error' });
            }
        },
        getRolleBezeichnung(rolle) {
            if (rolle !== 'gesuchspruefer') {
                if (this.gesuch.config.config.kontakte[rolle] && this.gesuch.config.config.kontakte[rolle].label) {
                    return this.gesuch.config.config.kontakte[rolle].label;
                }
            }
            return this.$t('apollon_rolle_' + rolle);
        },
        onDAttachmentClick(att) {
            let url = buildUrl('/mitteilung/downloadAttachment', {
                id: att.id
            });
            window.open(url, 'apollon-dl');
        },
        formatDate(value) {
            return dayjs(value).format('DD.MM.YYYY HH:mm');
        }
    }
};
</script>

<style lang="sass" scoped>
.msg-header
    display: flex
    align-items: center
    .msg-title
        display: inline-block
</style>
