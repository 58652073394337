/**
 * Store module: lookup
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { LookupCollection } from 'js/models/BaseCollection';

export default {
    namespaced: true,
    state: {
        records: null,
        loading: false,
        loadingCounter: 0
    },
    mutations: {
        loading(state, loading) {
            if (loading) {
                state.loadingCounter++;
            } else {
                state.loadingCounter = Math.max(0, state.loadingCounter - 1);
            }
            state.loading = state.loadingCounter > 0;
        },
        setValues(state, values) {
            state.records = values;
        }
    },
    actions: {
        /**
         *
         * @param {Object} store The vuex store
         * @param {Object} options The options object for the request (sent as params).
         *            The special properts 'options.apiCancelToken' can be set to define a
         *            global ajax cancel token to cancel running requests of the same token
         *            for different collections.
         *            See BaseCollection for more info.
         */
        async lookupValue({ commit, state }, options = {}) {
            try {
                commit('loading', true);
                let collection = new LookupCollection(null);
                collection.set('apiCancelToken', options.apiCancelToken || null);
                let optsCopy = Object.assign({}, options);
                delete optsCopy.apiCancelToken;

                await collection.queryValues(optsCopy);
                commit('setValues', collection);
            } finally {
                commit('loading', false);
            }
            return state.records;
        }
    }
};
