/**
 * Guest routes: routes that do not need authentication
 * (login, welcome etc).
 *
 * Will me merged into the router's 'route' array
 */

import Welcome from 'js/views/guest/default-customer/Welcome.vue';
import WelcomeTG from 'js/views/guest/tg/Welcome.vue';
import WelcomeSH from 'js/views/guest/sh/Welcome.vue';
import Login from 'js/views/guest/Login.vue';
import Register from 'js/views/guest/Register.vue';
import Reset from 'js/views/guest/Reset.vue';
import Activate from 'js/views/guest/Activate.vue';
import JoinTeam from 'js/views/guest/JoinTeam.vue';
import { getStore } from 'js/store/index.js';

const componentMap = {
    default: {
        welcome: Welcome
    },
    sh: {
        welcome: WelcomeSH
    },
    tg: {
        welcome: WelcomeTG
    }
};

const routes = [
    {
        path: '/welcome',
        name: 'welcome',
        component: () =>
            new Promise((success, error) => {
                success(componentMap[getStore().state.customer].welcome);
            }),
        meta: {
            guest: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true,
            title: 'Anmelden'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            guest: true,
            title: 'Registrieren'
        }
    },
    {
        path: '/activate',
        name: 'activate',
        component: Activate,
        meta: {
            guest: true,
            title: 'Aktivieren'
        }
    },
    {
        path: '/join_team',
        name: 'join_team',
        component: JoinTeam
    },
    {
        path: '/reset',
        name: 'reset',
        component: Reset,
        meta: {
            guest: true
        }
    }
];

export default routes;
