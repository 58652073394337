<template>
    <v-select
        :items="laender ? laender.models : []"
        item-text="name"
        :dense="dense"
        item-value="id"
        :label="label"
        :value="value"
        :loading="landIsLoading"
        clearable
        clear-icon="fas fa-times"
        @input="onSelect"
    ></v-select>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Number,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            landIsLoading: false
        };
    },

    computed: {
        laender() {
            return this.$store.state.land.records;
        }
    },
    created() {
        this.load();
    },

    methods: {
        ...mapActions('land', ['fetchLaender']),
        async load() {
            this.adrLoading = true;
            try {
                this.fetchLaender();
            } finally {
                this.adrLoading = false;
            }
        },

        onSelect(value) {
            this.$emit('input', value);
            let land = this.laender.find({ id: value });
            if (land) {
                this.$emit('landchange', land);
            }
        }
    }
};
</script>

<style></style>
