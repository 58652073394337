/**
 * Routes: /adressen/ and below
 *
 * Will me merged into the router's 'route' array
 */
import { getStore } from 'js/store';
import { awaitAsyncFunction } from 'js/lib/helpers';

const adressenList = () => import(/* webpackChunkName: "adresse" */ 'js/views/auth/adressen/AdressenList.vue');
const adressenForm = () => import(/* webpackChunkName: "adresse" */ 'js/views/auth/adressen/AdressenFormPanel.vue');

const routes = [
    {
        path: '/adressen',
        name: 'adressen',
        component: () => awaitAsyncFunction(adressenList, getStore()),
        meta: {
            requiresAuth: true,
            title: 'Adressen'
        }
    },
    {
        path: '/adressen/:id',
        name: 'adresse',
        component: () => awaitAsyncFunction(adressenForm, getStore()),
        meta: {
            requiresAuth: true,
            title: (to) => `Adresse: ${to.params.id}`
        }
    }
];

export default routes;
