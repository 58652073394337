import '@fortawesome/fontawesome-free/css/all.css';

import Vue from 'vue';
// import Vuetify from 'vuetify';
import Vuetify from 'vuetify/lib';
// import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';

// Locale definition:
import localeDe from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'fa'
    },
    theme: {
        themes: {
            light: {
                accent: colors.green.accent4,
                anchor: '#0645ad',
                action: colors.green.darken1,
                accept: colors.green.darken1,
                decline: colors.grey.accent4,
                subtleHint: colors.blue.lighten5,
                stop: colors.red.accent4
            }
        },
        options: {
            customProperties: true
        }
    },
    lang: {
        locales: { de: localeDe },
        current: 'de'
    }
};

export default new Vuetify(opts);
