/**
 * This file is part of Zeus Apollon
 *
 * This model represents a Zeus Dokument entity.
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from 'js/models/BaseModel';
import dayjs from 'dayjs';

export class ZeusDokument extends BaseModel {
    defaults() {
        return {
            id: null,
            beschreibung: null,
            erstellt_am: null,
            bearbeitet_am: null,
            name: null,
            filename: null,
            gesuch_id: null,
            mime: null,
            orig_filename: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            erstellt_am: dayjs,
            bearbeitet_am: dayjs
        };
    }

    entityName() {
        return 'dokument';
    }
}
