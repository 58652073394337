<template>
    <v-container fluid>
        <KontoinformationForm :gesuch="gesuch" :loading="loading" :read-only="readOnly" />
        <v-card-actions>
            <v-spacer />
            <v-btn class="mt-2" color="action" :loading="loading" @click="$emit('next')">
                <v-icon left>fas fa-arrow-right</v-icon>
                {{ $t('next_step') }}
            </v-btn>
        </v-card-actions>
    </v-container>
</template>

<script>
import KontoinformationForm from 'js/components/kontoinformation-form.vue';
import { Gesuch } from 'js/models/Gesuch';

export default {
    components: {
        KontoinformationForm
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        gesuch: {
            type: Gesuch,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {};
    },
    computed: {},
    methods: {}
};
</script>

<style scoped></style>
