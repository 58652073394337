/**
 * Represents a zeus.apollon_fk_conf.conf.projekt.feldgruppen[x].felder value object - the Foerderkategorie config
 * json structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model } from 'vue-mc';
import dayjs from 'dayjs';

export const TEXT = null;

export class ProjektFeld extends Model {
    defaults() {
        return {
            feld_id: null,
            feld_name: null,
            db_type: null,
            ist_link: false,
            link_tabellenname: null,
            link_id_field: null,
            link_value_field: null,
            linktabelle_produktkategorie_id: null,
            ist_werteliste: false,
            werteliste_tabelle: null,
            werteliste_id_field: null,
            werteliste_value_field: null,
            label: null,
            required: null,
            hilfetext: null,
            warnmeldung: null,
            value_from: null,
            value_to: null,
            einreichung: null,
            einreichung_from: null,
            einreichung_to: null,
            abschluss: null,
            abschluss_from: null,
            abschluss_to: null,
            zeus_feld: null
        };
    }

    mutations() {
        return {
            feld_id: (id) => (Number(id) > 0 ? Number(id) : null),
            einreichung_from: (date) => (date ? dayjs(date) : null),
            einreichung_to: (date) => (date ? dayjs(date) : null),
            abschluss_from: (date) => (date ? dayjs(date) : null),
            abschluss_to: (date) => (date ? dayjs(date) : null),
            db_type: (val) => {
                switch (val) {
                    case 'character varying':
                        return 'string';
                    case 'text':
                        return 'text';
                    case 'boolean':
                        return 'boolean';
                    case 'integer':
                        return 'integer';
                    case 'real':
                        return 'float';
                    case 'date':
                        return 'date';
                    case 'ja_nein_unknown':
                        return 'ja_nein_unknown';
                    case 'ja_nein_teilweise':
                        return 'ja_nein_teilweise';
                    default:
                        console.warn('cannot find db_type, given value: ' + val);
                        return 'string';
                }
            }
        };
    }
}
