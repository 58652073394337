<template>
    <v-dialog :value="value" persistent :max-width="700" @click:outside="$emit('close')">
        <v-card>
            <slot name="title">
                <v-card-title>{{ $t('hilfe_kontakt') }}</v-card-title>
            </slot>
            <slot>
                <v-card-text>
                    <h3>Haben Sie technische Fragen zum Projekt oder zu einem bestimmten Gesuch?</h3>
                    <p>Dann wenden Sie sich an:</p>
                    <div class="d-flex">
                        <img :src="tgImg" height="24" alt="Thurgau Wappen" style="margin-right: 5px" />
                        <h4>Kanton Thurgau</h4>
                    </div>
                    <div v-html="tgHelp"></div>
                    <div class="d-flex mt-2">
                        <img :src="shImg" height="24" alt="Schaffhausen Wappen" style="margin-right: 5px" />
                        <h4>Kanton Schaffhausen</h4>
                    </div>
                    <div v-html="shHelp"></div>
                    <h3>Haben Sie technische Fragen zum Energieförderportal?</h3>
                    <p>Dann wenden Sie sich an:</p>
                    <div v-html="afeHelp"></div>
                </v-card-text>
            </slot>
            <slot name="actions">
                <v-card-actions>
                    <v-btn color="decline" text :loading="loading" @click="$emit('close')">
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
import link from 'js/components/link.vue';
import { TgWappenSvg, ShWappenSvg } from 'js/lib/images.js';

export default {
    components: {
        'kp-link': link
    },
    props: {
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            tgImg: TgWappenSvg,
            shImg: ShWappenSvg,
            tgHelp: '',
            shHelp: '',
            afeHelp: ''
        };
    },
    async mounted() {
        this.tgHelp = await (await fetch(this.$store.state.webroot + '/static/helptg.html')).text();
        this.shHelp = await (await fetch(this.$store.state.webroot + '/static/helpsh.html')).text();
        this.afeHelp = await (await fetch(this.$store.state.webroot + '/static/helpafe.html')).text();
    }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}

h3 {
    margin-top: 1.5em;
}

address {
    font-style: normal;
}
</style>
