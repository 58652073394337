/**
 * Represents the zeus.apollon_fk_conf.conf.kontakte value object - the Foerderkategorie config
 * json structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model } from 'vue-mc';
import { KontaktConfig } from './KontaktConfig';

export class Kontakte extends Model {
    defaults() {
        return {
            eigentuemer: null,
            unternehmer: null,
            kontakt: null,
            bevollmaechtigter: null
        };
    }

    mutations() {
        return {
            eigentuemer: (val) => new KontaktConfig(val),
            unternehmer: (val) => new KontaktConfig(val),
            kontakt: (val) => new KontaktConfig(val),
            bevollmaechtigter: (val) => new KontaktConfig(val)
        };
    }
}
