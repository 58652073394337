/**
 * Store module: mitteilung
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { Mitteilung, MitteilungCollection } from 'js/models/Mitteilung';
import { instance as getApi } from '@/js/lib/api';
import dayjs from 'dayjs';

export default {
    namespaced: true,
    state: {
        unread_mitteilungen: {
            entries: []
        }
    },
    mutations: {
        setUnreadMitteilungen(state, mitteilungen) {
            state.unread_mitteilungen.entries = mitteilungen || [];
        }
    },
    actions: {
        async loadUnreadMitteilungen({ commit, state }) {
            commit('loading', true, { root: true });
            try {
                let data = await getApi().get('/mitteilung/getUnreadMessages');
                commit('setUnreadMitteilungen', data.data.records);
            } finally {
                commit('loading', false, { root: true });
            }
            return state.unread_mitteilungen.entries;
        },

        /**
         * Update mitteilung data
         *
         * @param {Mitteilung} mitteilung A Mitteillung entity record
         */
        async storeMitteilung({ commit }, mitteilung) {
            commit('loading', true, { root: true });
            try {
                await mitteilung.save();
            } finally {
                commit('loading', false, { root: true });
            }
        },

        /**
         * Delete mitteilung record
         *
         * @param {Mitteilung} mitteilung A Mitteillung entity record
         */
        async destroyMitteilung({ commit }, mitteilung) {
            commit('loading', true, { root: true });
            let res = null;
            try {
                await mitteilung.markDeleted();
                mitteilung.is_deleted = true;
                mitteilung.deleted_at = dayjs();
            } finally {
                commit('loading', false, { root: true });
            }
            return res;
        },

        /**
         *
         * @param {Object} store The vuex store
         * @param {Object} options Future options like query params, paging etc.
         */
        async fetchMitteilungen({ commit }, options = {}) {
            let list = new MitteilungCollection();
            try {
                commit('loading', true, { root: true });
                await list.query(options);
            } finally {
                commit('loading', false, { root: true });
            }
            return list;
        },

        /**
         * Loads / creates a Mitteilung entity.
         *
         * If id is 'new', a new one will be created, otherwise loaded from the backend.
         *
         * @param {Object} store The vuex store
         * @param {Number|String} id The id to load, or 'new'
         */
        async loadMitteilung({ commit }, options) {
            let mitteilung = null;
            try {
                commit('loading', true, { root: true });
                if (!options.id || options.id === 'new') {
                    mitteilung = new Mitteilung();
                } else {
                    mitteilung = new Mitteilung({ id: options.id });
                    await mitteilung.fetch({ params: { gesuch_id: options.gesuch_id } });
                }
            } finally {
                commit('loading', false, { root: true });
            }
            return mitteilung;
        }
    }
};
