<template>
    <v-container>
        <v-card>
            <v-slide-y-transition>
                <v-alert v-if="error" type="error" dense tile>{{ error }}</v-alert>
            </v-slide-y-transition>
            <v-card-title>{{ $t('activate_login') }}</v-card-title>
            <v-card-text>
                <v-row align="center">
                    <v-col cols="10">
                        <kp-textfield v-model="code" :disabled="activateCodeInput" label="Aktivierungscode" />
                    </v-col>
                    <v-col cols="2">
                        <v-btn
                            :disabled="activateCodeInput"
                            color="action white--text"
                            :loading="loading"
                            @click="loadBenutzerInfo"
                        >
                            {{ $t('check') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <div v-if="code && benutzer">
                <v-card-title>
                    {{
                        newEmail ? $t('email_confirm', { email: newEmail }) : $t('activation_of_mail', { email: email })
                    }}
                </v-card-title>
                <v-card-text v-if="!newEmail">
                    <p>{{ $t('please_define_pw') }}:</p>
                    <kp-textfield v-model="email" :label="$t('email')" autocomplete="on" disabled />
                    <v-row>
                        <v-col>
                            <kp-textfield
                                v-model="pw1"
                                type="password"
                                :label="$t('pw_min_chars', { chars: 8 })"
                                dense
                                :rules="[formRules.minLength(8)]"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <kp-textfield
                                v-model="pw2"
                                type="password"
                                :label="$t('repeat_pw')"
                                dense
                                :error-messages="passwordsValid ? null : $t('pw_not_match')"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        v-if="newEmail"
                        color="primary"
                        :loading="activationLoading"
                        @click="$router.push({ name: 'login' })"
                    >
                        {{ $t('to_login') }}
                    </v-btn>
                    <v-btn
                        v-else
                        color="primary"
                        :disabled="!passwordsValid || activationLoading"
                        :loading="activationLoading"
                        @click="onSubmit"
                    >
                        {{ $t('activate') }}
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { instance as getApi } from 'js/lib/api.js';
import { errToStr } from 'js/lib/helpers.js';
import { formRules } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    data() {
        return {
            error: null,
            code: null,
            pw1: '',
            pw2: '',
            benutzer: null,
            loading: false,
            activationLoading: false,
            formRules,
            newEmail: null
        };
    },
    computed: {
        email() {
            return this.benutzer ? this.benutzer.email : '';
        },
        activateCodeInput() {
            return this.loading === true || (this.code !== null && this.benutzer !== null);
        },
        passwordsValid() {
            return this.pw1.length > 0 && this.pw1 === this.pw2;
        }
    },

    created() {
        this.code = this.$route.query.code || null;
    },

    beforeRouteUpdate(to) {
        this.code = to.query.code || null;
        this.benutzer = null;
        if (this.code) {
            this.loadBenutzerInfo();
        }
    },

    mounted() {
        if (this.code) {
            this.loadBenutzerInfo();
        }
    },

    methods: {
        ...mapActions(['runAsync']),
        async loadBenutzerInfo() {
            this.error = null;
            this.loading = true;
            this.runAsync(async () => {
                try {
                    let res = await getApi().get('/auth/getActivationInfo', {
                        params: {
                            code: this.code
                        }
                    });
                    this.benutzer = res.data.benutzer;
                    this.newEmail = res.data.email;
                } catch (e) {
                    this.error = errToStr(e);
                } finally {
                    this.loading = false;
                }
            });
        },
        async onSubmit() {
            this.error = null;
            this.activationLoading = true;
            let me = this;
            this.runAsync(async () => {
                try {
                    await getApi().post('/auth/activate', {
                        email: this.benutzer.email,
                        code: this.code,
                        password: this.pw1
                    });
                    this.mode = 'confirmation';
                    me.showLoginInfo();
                    this.$router.push({ name: 'login' });
                } catch (e) {
                    this.error = errToStr(e);
                } finally {
                    this.activationLoading = false;
                }
            });
        },
        showLoginInfo() {
            this.$toast({
                message: this.$t('activation_successful', { email: this.email }),
                color: 'success',
                timeout: 12000
            });
        }
    }
};
</script>
