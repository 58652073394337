<template>
    <v-skeleton-loader
        v-if="!adresse || adrLoading"
        type="list-item-avatar, list-item-three-line@3, actions"
    ></v-skeleton-loader>
    <div v-else>
        <v-toolbar color="primary white--text" class="colored-toolbar">
            <v-toolbar-title>
                <v-icon left color="white">far fa-address-book</v-icon>
                {{ $t('adresse') }}
            </v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-card max-width="950">
                <v-card-text v-if="!avoidTypeEdit && !isProfil">
                    <v-container fluid>
                        {{ $t('adresstyp') }}
                        <v-radio-group
                            v-model="adresse.typ"
                            class="mt-0"
                            dense
                            hide-details
                            row
                            :mandatory="true"
                            @change="typeChanged"
                        >
                            <v-radio
                                :label="$t(addressTypes.person.lang_key)"
                                :value="addressTypes.person.value"
                            ></v-radio>
                            <v-radio
                                :label="$t(addressTypes.organisation.lang_key)"
                                :value="addressTypes.organisation.value"
                            ></v-radio>
                        </v-radio-group>
                    </v-container>
                </v-card-text>
                <PersonCard
                    v-if="adresse.typ === 'person'"
                    :adresse="adresse"
                    :is-profil="isProfil"
                    :loading="adrLoading"
                    :is-new="isNew"
                    :reduced-contact-data="reducedContactData"
                    @validchange="(v) => (personValid = v)"
                ></PersonCard>
                <OrganisationCard
                    v-if="adresse.typ === 'organisation'"
                    :adresse="adresse"
                    :is-profil="isProfil"
                    :loading="adrLoading"
                    :is-new="isNew"
                    :gesuch-context="!!gesuchAddress"
                    @validchange="(v) => (organisationValid = v)"
                ></OrganisationCard>
                <v-card-actions>
                    <v-btn
                        v-if="!isNew && !gesuchAddress"
                        class="hidden-sm-and-up"
                        color="red"
                        :disabled="loading"
                        text
                        @click="onDestroy"
                    >
                        <v-icon>fas fa-trash</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="!isNew && !isProfil && !gesuchAddress"
                        class="hidden-xs-only"
                        color="red"
                        :disabled="loading"
                        text
                        @click="onDestroy"
                    >
                        {{ $t('delete') }}
                    </v-btn>

                    <v-spacer />
                    <v-btn
                        v-if="!isNew || !isProfil"
                        class="hidden-sm-and-up"
                        color="decline"
                        text
                        :disabled="loading"
                        @click="closeForm"
                    >
                        <v-icon>fas fa-arrow-left</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="!isNew || !isProfil"
                        class="hidden-xs-only"
                        color="decline"
                        :disabled="loading"
                        text
                        @click="closeForm"
                    >
                        {{ $t('close') }}
                    </v-btn>
                    <v-btn
                        class="hidden-xs-only"
                        color="action white--text"
                        type="submit"
                        :disabled="!valid || loading"
                        :loading="loading"
                        @click="onSubmit"
                    >
                        <v-icon left>fas fa-save</v-icon>
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn
                        class="hidden-sm-and-up"
                        color="action white--text"
                        type="submit"
                        :disabled="!valid || loading"
                        :loading="loading"
                        @click="onSubmit"
                    >
                        <v-icon>fas fa-save</v-icon>
                    </v-btn>
                </v-card-actions>
                <ConfirmDlg
                    v-model="confirmDelDlg"
                    :title="$t('bestaetigung')"
                    :text="$t('really_delete_rec')"
                    @yes="confirmDestroy"
                />
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { addressTypes, errToStr } from 'js/lib/helpers.js';
import { mapState, mapActions } from 'vuex';
import ConfirmDlg from 'js/components/confirm-dlg.vue';
import { Adresse, AdresseCollection } from 'js/models/Adresse';
import OrganisationCard from 'js/views/auth/adressen/OrganisationCard.vue';
import PersonCard from 'js/views/auth/adressen/PersonCard.vue';

/**
 * This is the (inner) AddressForm (See AddressFormPanel): It knows several modes:
 * - standard mode is editing a single address: It loads an address from the API to
 *   edit it.
 * - If it is a new address (route: /new, or some other considerations, see isNew()), no address is loaded from the backend
 * - if the property "newAddress" is set, the form is in the New-Organisation-Mode: It then is only
 *    meant to edit the given organisation record, to create one in-place. No address is loaded.
 * - If in profile mode, the form is displayed unter the "Profile" function.
 */
export default {
    components: {
        ConfirmDlg,
        OrganisationCard,
        PersonCard
    },
    props: {
        organisations: {
            type: AdresseCollection,
            default: null
        },
        newAddress: {
            type: Adresse,
            default: null
        },
        gesuchAddress: {
            type: Adresse,
            default: null
        },
        avoidTypeEdit: {
            type: Boolean,
            default: false
        },
        reducedContactData: {
            type: Boolean,
            default: false
        },
        avoidBenutzerIdEdit: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            confirmDelDlg: false,
            adresse: null,
            adrLoading: true,
            addressTypes: addressTypes,
            personValid: false,
            organisationValid: false,
            origAnsprechsPersonEmail: null
        };
    },
    computed: {
        ...mapState({
            loading: 'loading'
        }),
        isNew() {
            return (
                this.newAddress !== null ||
                this.$route.params.id === 'new' ||
                (this.$route.params.id === 'profil' && this.adresse.$.typ === null)
            );
        },
        isProfil() {
            return this.$route.params.id === 'profil';
        },
        valid() {
            if (this.adresse.typ === 'person') {
                return this.personValid;
            }
            if (this.adresse.typ === 'organisation') {
                return this.organisationValid;
            }
            return false;
        }
    },

    watch: {
        $route: function () {
            // We already are loaded, do nothing:
            if (this.adresse && this.adresse.id === this.$route.params.id) {
                return;
            }
            this.loadRecord(this.$route.params.id);
        },
        adresse: function (newAddress, oldAddress) {
            if (newAddress !== oldAddress) {
                this.$emit('change:address', newAddress);
            }
        }
    },

    created() {
        if (this.newAddress) {
            this.adresse = this.newAddress;
            this.adrLoading = false;
        } else if (this.gesuchAddress) {
            this.adresse = this.gesuchAddress.clone();
            this.adrLoading = false;
        } else {
            this.loadRecord(this.$route.params.id);
        }
        this.origAnsprechsPersonEmail = this.adresse?.email;
    },
    methods: {
        ...mapActions('adressen', ['storeAdresse', 'destroyAdresse', 'loadAdresse', 'fetchAdressen']),
        async onSubmit() {
            setTimeout(async () => {
                this.cleanAddress();
                this.cleanBenutzerId();
                if (this.gesuchAddress) {
                    this.$emit('close', this.adresse);
                } else {
                    try {
                        await this.storeAdresse(this.adresse);
                        this.$toast({ message: 'Daten erfolgreich gespeichert', color: 'success' });
                        this.$emit('close', this.adresse);
                    } catch (res) {
                        this.$toast({ message: errToStr(res), color: 'error' });
                    }
                }
            }, 100);
        },
        async loadRecord(id) {
            this.adrLoading = true;
            try {
                this.adresse = await this.loadAdresse({ id: id });
                this.adresse.typ = this.typ ? this.typ : this.adresse.typ;
            } catch (res) {
                this.$toast({ message: errToStr(res), color: 'error' });
            } finally {
                this.adrLoading = false;
            }
        },
        async onDestroy() {
            this.confirmDelDlg = true;
        },

        closeForm() {
            this.$emit('close');
        },

        async confirmDestroy() {
            try {
                await this.destroyAdresse(this.adresse);
                this.adresse = null;
                this.closeForm();
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },

        isDirty() {
            return this.adresse ? this.adresse.isDirty() : false;
        },

        typeChanged(value) {
            if (value === 'person') {
                this.adresse.email = this.adresse.email || this.adresse.organisation_email;
                this.adresse.tel_1 = this.adresse.tel_1 || this.adresse.organisation_tel_1;
                this.adresse.tel_2 = this.adresse.tel_2 || this.adresse.organisation_tel_2;
            } else {
                this.adresse.organisation_email = this.adresse.organisation_email || this.adresse.email;
                this.adresse.organisation_tel_1 = this.adresse.organisation_tel_1 || this.adresse.tel_1;
                this.adresse.organisation_tel_2 = this.adresse.organisation_tel_2 || this.adresse.tel_2;
            }
        },
        cleanAddress() {
            if (this.adresse.typ === 'person') {
                this.adresse.organisation_email = null;
                this.adresse.organisation_tel_1 = null;
                this.adresse.organisation_tel_2 = null;
                this.adresse.organisation_name = null;
            } else if (!this.gesuchAddress) {
                this.adresse.email = null;
                this.adresse.tel_1 = null;
                this.adresse.tel_2 = null;
                this.adresse.vorname = null;
                this.adresse.nachname = null;
            }
        },
        cleanBenutzerId() {
            if (
                !this.avoidBenutzerIdEdit &&
                this.adresse.typ === 'organisation' &&
                this.origAnsprechsPersonEmail !== this.adresse.email
            ) {
                this.adresse.benutzer_id = null;
            }
        }
    }
};
</script>
