/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';
import dayjs from 'dayjs';
import { vueI18n } from 'js/lib/i18n';

export class TeamMitglied extends BaseModel {
    defaults() {
        return {
            id: null,
            benutzer_id: null,
            team_id: null,
            status: null,
            created: null,
            modified: null,

            // View props:
            team_ersteller_id: null,
            team_name: null,
            benutzer_email: null,
            team_mitglied_name: null,
            ersteller_name: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            benutzer_id: (id) => (Number(id) > 0 ? Number(id) : null),
            team_ersteller_id: (id) => (Number(id) > 0 ? Number(id) : null),
            team_id: (id) => (Number(id) > 0 ? Number(id) : null),
            created: dayjs,
            modified: dayjs
        };
    }

    get status_display() {
        let status = {
            accepted: { text: vueI18n.t('accepted'), icon: 'fas fa-check', color: 'success' },
            pending: { text: vueI18n.t('pending'), icon: 'far fa-hourglass', color: 'warning' },
            declined: { text: vueI18n.t('declined'), icon: 'fas fa-times', color: 'error' }
        };
        return status[this.status] || '-';
    }

    entityName() {
        return 'team_mitglied';
    }
}

export class TeamMitgliedCollection extends BaseCollection {
    options() {
        return {
            model: TeamMitglied
        };
    }

    entityName() {
        return 'team_mitglied';
    }
}
