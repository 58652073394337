/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';
import dayjs from 'dayjs';

export class Team extends BaseModel {
    defaults() {
        return {
            id: null,
            name: null,
            created: null,
            modified: null,
            ersteller_id: null,
            ersteller_name: null,
            anzahl_mitglieder: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            ersteller_id: (ersteller_id) => (Number(ersteller_id) > 0 ? Number(ersteller_id) : null),
            anzahl_mitglieder: (anzahl_mitglieder) =>
                Number(anzahl_mitglieder) > 0 ? Number(anzahl_mitglieder) : null,
            created: dayjs,
            modified: dayjs
        };
    }

    entityName() {
        return 'team';
    }
}

export class TeamCollection extends BaseCollection {
    options() {
        return {
            model: Team
        };
    }

    entityName() {
        return 'team';
    }
}
