/**
 * Base entity model. All Apollon models should inherit this class.
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import { Adresse } from './Adresse';

export class Benutzer extends BaseModel {
    defaults() {
        return {
            id: null,
            email: null,
            aktiv: false,
            rolle: null,
            letzter_login: null,
            login_versuche: null,
            adresse_id: null,
            created: null,
            modified: null,

            profilAdresse: null,
            team_mitglieder: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            aktiv: Boolean,
            login_versuche: Number,
            profilAdresse: (data) => Adresse.fromData(data)
        };
    }

    entityName() {
        return 'benutzer';
    }
}
