<template>
    <v-card :loading="loading" tile>
        <v-card-title v-if="liegenschaftEntry">
            {{ liegenschaftEntry.strasse }} {{ liegenschaftEntry.strassen_nummer }}<br />
            {{ liegenschaftEntry.ort_plz }} {{ liegenschaftEntry.ort }}
            <v-spacer />
            <v-btn icon x-small color="primary" @click.stop="$emit('edit-liegenschaft-zusatzdaten', liegenschaft)">
                <v-icon v-if="readOnly" x-small>fas fa-eye</v-icon>
                <v-icon v-else x-small>fas fa-pen</v-icon>
            </v-btn>
            <v-btn
                icon
                x-small
                color="stop"
                :disabled="!canRemove() || readOnly"
                @click.stop="$emit('delete-liegenschaft', liegenschaftEntry.id)"
            >
                <v-icon x-small>fas fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text v-if="liegenschaftEntry">
            {{ $t('gemeinde') }}: {{ liegenschaftEntry.gemeinde_bezeichnung }}<br />
            {{ $t('baujahr') }}: {{ liegenschaftEntry.baujahr }}<br />
            {{ $t('egid') }}: {{ liegenschaftEntry.egid }}<br />
            {{ $t('parzellen_nummer') }}: {{ liegenschaftEntry.parzellen_nummer }}<br />
            {{ $t('assekuranz_nummer') }}: {{ liegenschaftEntry.assekuranz_nummer }}<br />
        </v-card-text>
        <v-divider v-if="gesuch.config.config.objekte.visible && zusatzdaten.length > 0" class="mx-4"></v-divider>
        <v-card-text v-if="gesuch.config.config.objekte.visible && zusatzdaten.length > 0">
            <div v-for="data in zusatzdaten" :key="data.label">
                <span>{{ data.label }}: </span>
                <span v-if="data.value && typeof data.value === 'object'">{{ data.value.display }}</span>
                <span v-else-if="data.value">{{ data.value }}</span>
                <span v-else style="color: red">{{ $t('value_missing') }}</span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Gesuch } from 'js/models/Gesuch';
import { Liegenschaft } from 'js/models/Liegenschaft';
import dayjs from 'dayjs';

export default {
    props: {
        gesuch: {
            type: Gesuch,
            required: true
        },
        liegenschaft: {
            type: Object,
            default: () => {}
        },
        readOnly: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            loading: true,
            editing: false,
            confirmDelDlg: false,
            liegenschaftEntry: null,
            zusatzdatenEntry: null,
            zusatzdaten: [],
            initialLoading: false
        };
    },
    created() {
        this.initialLoading = true;
        this.liegenschaftEntry =
            this.liegenschaft.liegenschaft instanceof Liegenschaft
                ? this.liegenschaft.liegenschaft.clone()
                : { ...this.liegenschaft.liegenschaft };
        this.extractZusatzdatenConfig();
        this.zusatzdatenEntry = { ...this.liegenschaft.liegenschaft_zusatzdaten };
        this.loading = false;
        this.initialLoading = false;
    },
    methods: {
        ...mapActions(['runAsync']),
        ...mapState('benutzer', {
            benutzer: 'record'
        }),
        canRemove() {
            return this.benutzer.id === this.liegenschaft.ersteller_id;
        },
        extractZusatzdatenConfig() {
            if (!this.liegenschaft.liegenschaft_zusatzdaten) {
                this.liegenschaft.liegenschaft_zusatzdaten = [];
            }
            let rahmen = this.gesuch.config.config.objekte.rahmen;
            for (let r in rahmen) {
                if (this.isComponentVisible(rahmen[r]) && r !== 'objekt_felder') {
                    for (let feld in rahmen[r].felder) {
                        if (this.isComponentVisible(rahmen[r].felder[feld]) && rahmen[r].felder[feld].required) {
                            this.zusatzdaten.push({
                                label: rahmen[r].felder[feld].label,
                                value: this.liegenschaft.liegenschaft_zusatzdaten[rahmen[r].felder[feld].feld_name]
                            });
                        }
                    }
                }
            }
        },
        isComponentVisible(comp) {
            let visible = true;
            if (this.gesuch.phase === 'einreichung') {
                if (comp.einreichung_from) {
                    visible &= dayjs(comp.einreichung_from).isBefore(dayjs());
                }
                if (comp.einreichung_to) {
                    visible &= dayjs().isBefore(dayjs(comp.einreichung_to).add(1, 'day'));
                }
                visible &= comp.einreichung;
                return visible;
            }
            if (this.gesuch.phase === 'abschluss') {
                if (comp.abschluss_from) {
                    visible &= dayjs(comp.abschluss_from).isBefore(dayjs());
                }
                if (comp.abschluss_to) {
                    visible &= dayjs().isBefore(dayjs(comp.abschluss_to).add(1, 'day'));
                }
                visible &= comp.abschluss;
                return visible;
            }
            return false;
        }
    }
};
</script>
