/**
 * Represents the zeus.apollon_fk_conf.conf value object - the Foerderkategorie config
 * json structure
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import { Model } from 'vue-mc';
import { Kontakte } from './Kontakte';
import { Projekt } from './Projekt';
import { Objekte } from './Objekte';

export class Config extends Model {
    defaults() {
        return {
            kontakte: null,
            objekte: null,
            projekt: null,
            dokumente: null
        };
    }

    mutations() {
        return {
            kontakte: (val) => new Kontakte(val),
            objekte: (val) => new Objekte(val),
            projekt: (val) => new Projekt(val),
            dokumente: (val) => val || {}
        };
    }
}
