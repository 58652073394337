<template>
    <v-card>
        <v-card-title>{{ $t('choose_org') }}</v-card-title>
        <v-card-subtitle v-if="rolle === 'unternehmer'">{{ $t('choose_or_create_org_force') }}</v-card-subtitle>
        <v-card-subtitle v-else>{{ $t('choose_or_create_org') }}</v-card-subtitle>
        <v-card-text>
            <v-checkbox v-if="rolle !== 'unternehmer'" v-model="showOrgGrid" :label="$t('org_person')"></v-checkbox>
            <AdressenGrid
                v-if="showOrgGrid"
                :edit="false"
                :dense="false"
                :selectable="true"
                :height="300"
                :addbtn="$t('new_organisation')"
                :headerwidths="{
                    typ: '5%',
                    name: '18%',
                    vorname: '0%',
                    adresse: '25%',
                    land: '0%',
                    plz: '14%',
                    ort: '18%',
                    action: '0%'
                }"
                :selection="adresse"
                :typfilter="typFilter"
                :reload="reloadGrid"
                @selection="onSelect"
                @reload="reloadGrid = false"
                @new-address="onNewOrg"
            ></AdressenGrid>
        </v-card-text>
        <v-card-actions>
            <v-btn color="action lighten-1 white--text" @click="$emit('back')">
                <v-icon left>fas fa-arrow-left</v-icon>
                {{ $t('back') }}
            </v-btn>
            <v-btn :disabled="nextDisabled" color="action lighten-1 white--text" @click="$emit('next')">
                {{ $t('next') }}
                <v-icon right>fas fa-arrow-right</v-icon>
            </v-btn>
        </v-card-actions>
        <v-dialog v-if="showAddOrgDlg" v-model="showAddOrgDlg" max-width="1000">
            <v-card>
                <AdresseForm :new-address="newOrg" avoid-type-edit @close="onNewOrgClose" />
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import AdressenGrid from 'js/components/adressen-grid.vue';
import { Adresse } from 'js/models/Adresse';
import AdresseForm from 'js/views/auth/adressen/AdressenForm.vue';

export default {
    components: {
        AdressenGrid,
        AdresseForm
    },
    props: {
        rolle: {
            type: String,
            default: null
        }
    },
    data: function () {
        return {
            showOrgGrid: false,
            adresse: null,
            loading: true,
            typFilter: Adresse.TYP_ORGANISATION,
            showAddOrgDlg: false,
            reloadGrid: false
        };
    },
    computed: {
        nextDisabled() {
            if (this.rolle === 'unternehmer') {
                return !this.adresse;
            } else {
                return this.showOrgGrid && !this.adresse;
            }
        }
    },
    watch: {
        rolle: {
            handler(val) {
                this.showOrgGrid = val === 'unternehmer';
                this.onSelect(null);
            }
        },
        showOrgGrid: {
            handler(val) {
                if (!val) {
                    this.onSelect(null);
                }
            }
        }
    },
    mounted() {
        this.showOrgGrid = this.rolle === 'unternehmer';
        this.onSelect(null);
    },
    methods: {
        onSelect(value) {
            this.adresse = value;
            this.$emit('input', value);
        },
        async onNewOrgClose(org) {
            this.showAddOrgDlg = false;
            this.onSelect(org);
            this.reloadGrid = true;
        },
        onNewOrg() {
            this.newOrg = new Adresse();
            this.newOrg.typ = Adresse.TYP_ORGANISATION;
            this.showAddOrgDlg = true;
        }
    }
};
</script>
