<template>
    <v-app-bar
        :app="app"
        color="white"
        class="kp-header"
        :class="$store.state.config.env ? $store.state.config.env : ''"
        clipped-left
        height="90px"
    >
        <v-container fluid>
            <v-row justify="space-between" align="center">
                <img :src="tgImg" height="45" alt="Thurgau-Logo" class="ml-2 mr-3 hidden-sm-and-up" />
                <v-col class="hidden-xs-only">
                    <div class="d-none d-sm-block" style="min-width: 340px;">
                        <h1 class="title">
                            {{ $t('g_efp') }}
                        </h1>
                        <div class="subtitle">
                            {{ $t('k_tg') }}
                        </div>
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="auto" style="text-align: end;">
                    <slot></slot>
                </v-col>
                <img :src="tgImg" height="45" alt="Thurgau-Logo" class="mr-3 mb-2 hidden-sm-and-down" />
            </v-row>
            <v-row>
                <v-col cols="12" style="height: 24px;" class="tg-horizonte">&nbsp;</v-col>
            </v-row>
        </v-container>
        <template v-slot:extension>
            <v-tabs show-arrows icons-and-text class="topnav" active-class="kp-main-tabs-active">
                <v-tab :to="{ name: 'home' }" exact>
                    <span class="d-none d-sm-flex">
                        {{ $t('home') }}
                    </span>
                    <v-icon dense>fas fa-home fa-xs</v-icon>
                </v-tab>
                <v-tab :to="{ name: 'gesuche' }">
                    <span class="d-none d-sm-flex">
                        {{ $t('gesuche') }}
                    </span>
                    <v-icon dense>fas fa-balance-scale fa-fw</v-icon>
                </v-tab>
                <v-tab :to="{ name: 'adressen' }">
                    <span class="d-none d-sm-flex">
                        {{ $t('adressen') }}
                    </span>
                    <v-icon dense>fas fa-address-book fa-fw</v-icon>
                </v-tab>
                <v-tab :to="{ name: 'liegenschaften' }">
                    <span class="d-none d-sm-flex">
                        {{ $t('liegenschaften') }}
                    </span>
                    <v-icon dense>fas fa-building fa-fw</v-icon>
                </v-tab>
                <v-tab :to="{ name: 'teams' }">
                    <v-badge
                        color="error"
                        overlap
                        :value="teamrequests ? teamrequests.length : 0"
                        :content="teamrequests ? teamrequests.length : 0"
                    >
                        <v-icon dense>fas fa-users fa-fw</v-icon>
                        <span class="d-none d-sm-flex">
                            {{ $t('teams') }}
                        </span>
                    </v-badge>
                </v-tab>
                <v-tab v-if="benutzer.rolle === 'admin'" :to="{ name: 'news' }">
                    <span class="d-none d-sm-flex">
                        {{ $t('news') }}
                    </span>
                    <v-icon dense>fas fa-rss fa-fw</v-icon>
                </v-tab>
            </v-tabs>
        </template>
    </v-app-bar>
</template>

<script>
import { TgLogo, TgHorizont } from 'js/lib/images.js';
import { mapState } from 'vuex';

export default {
    props: {
        app: {
            type: Boolean,
            default: false
        },
        teamrequests: {
            type: Array,
            default: () => []
        }
    },
    data: () => {
        return {
            tgImg: TgLogo,
            tgHorizont: TgHorizont
        };
    },
    computed: {
        ...mapState('benutzer', {
            benutzer: 'record'
        })
    }
};
</script>

<style lang="scss">
.kp-header {
    .topnav {
        border-bottom: 1px solid #ccc;

        .v-tab {
            font-size: 16px;
            &:not(.v-tab--active) {
                color: black !important;
            }
        }
    }

    .v-toolbar__content {
        padding: 0 !important;
    }
    .tg-horizonte {
        background-image: url(~resources/horizont.png);
        background-repeat: repeat-x;
        background-size: 24px;
    }
}
</style>
