<template>
    <kp-textfield :value="value" v-bind="$attrs" @input="inputChanged"> </kp-textfield>
</template>

<script>
import { formatTelefonNumber } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    props: {
        value: { type: String, default: null }
    },
    methods: {
        ...formatTelefonNumber,
        inputChanged(value) {
            this.$emit('input', formatTelefonNumber(value));
        }
    }
};
</script>

<style></style>
