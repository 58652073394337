<template>
    <v-row>
        <v-col>
            <v-card-title>Admin-Tools</v-card-title>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <div class="d-flex">
                                <kp-textfield v-model="impersonationEmail" label="User-Email:" />
                                <v-btn @click="impersonate">zum User werden</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <div class="d-flex">
                                <kp-textfield v-model="openGesuchNr" label="Gesuchs-Nr:" />
                                <v-btn @click="openGesuch">Gesuch öffnen</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>
                            Download Zähler
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :items="downloadCounts" class="elevation-1">
                                <template v-slot:[`item.letzter_download`]="{ item }">
                                    {{ formatDate(item.letzter_download) }}
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { instance as api } from 'js/lib/api';
import KpTextfield from 'js/components/kp-textfield.vue';
import dayjs from 'dayjs';

export default {
    components: {
        KpTextfield
    },

    data: function () {
        return {
            impersonationEmail: null,
            openGesuchNr: null,
            downloadCounts: [],
            headers: [
                {
                    text: 'Datei',
                    align: 'start',
                    value: 'filename'
                },
                { text: 'Downloads', value: 'count' },
                { text: 'letzter Download', value: 'letzter_download' }
            ]
        };
    },

    created() {
        this.loadDownloadCounts(this.$route.params.id);
    },

    methods: {
        ...mapActions(['runAsync']),
        ...mapActions('downloadcount', ['fetchDownloadCounts']),
        async impersonate() {
            this.runAsync(async () => {
                let res = await api().get('/auth/impersonateSession', {
                    params: {
                        userEmail: this.impersonationEmail
                    }
                });
                alert('You are now logged in as ' + res.data.benutzer.email, ', the page is reloaded.');
                document.location.reload();
            });
        },

        openGesuch() {
            if (this.openGesuchNr) {
                this.$router.push({ name: 'gesuch', params: { nr: this.openGesuchNr.toUpperCase() } });
            }
        },

        async loadDownloadCounts() {
            let counts = await this.fetchDownloadCounts();
            this.downloadCounts = counts.models;
        },

        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
        }
    }
};
</script>
