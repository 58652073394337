//Polyfills:
// import '@babel/polyfill';
// import 'regenerator-runtime/runtime';

import 'app.scss';
import Vue from 'vue';
import vuetify from 'js/plugins/vuetify.js';
import { vueI18n } from 'js/lib/i18n.js';
import toast from 'js/plugins/toast.js';
import App from 'js/App.vue';
import router from 'js/routes';
import api from 'js/lib/api.js';
import { storeCsrfToken } from 'js/lib/auth.js';
import store from 'js/store/index.js';
import dayjs from 'dayjs';
import 'dayjs/locale/de-ch';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Configure dayjs locale:
dayjs.locale('de-ch');
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

// Start app only after the remote auth / session info is loaded:
api.loadAuthInfo(window.apiRoot).then((authInfo) => {
    storeCsrfToken(authInfo.csrfToken);
    // init api with known base url:
    api.init({ baseURL: window.apiRoot });

    // init store with initial data:
    const storeInst = store.init({
        session: null,
        appVersion: window.appVersion || '',
        webroot: authInfo.webroot || ''
    });

    storeInst.commit('benutzer/setBenutzer', authInfo.apollonBenutzer);
    storeInst.commit('setConfig', authInfo.config);
    storeInst.commit('setCustomer', authInfo.customer);
    Vue.use(toast, { store });

    new Vue({
        el: '#app',
        router,
        store: storeInst,
        vuetify,
        toast,
        i18n: vueI18n,
        render: (h) => h(App)
    });
});
