<template>
    <v-container>
        <v-card>
            <v-slide-y-transition>
                <v-alert v-if="error" type="error" dense tile>{{ error }}</v-alert>
            </v-slide-y-transition>
            <v-card-title>{{ $t('team_join') }}</v-card-title>
            <v-card-text v-if="mode === 'code'">
                <v-row align="center">
                    <v-col cols="10">
                        <kp-textfield v-model="code" :disabled="loading" label="Aktivierungscode" />
                    </v-col>
                    <v-col cols="2">
                        <v-btn :loading="loading" color="action white--text" @click="onActivateBtn">
                            {{ $t('check') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-fade-transition>
                <v-card-text v-if="mode === 'success'">
                    <v-alert type="success">
                        {{ $t('team_join_success') }}
                    </v-alert>
                    <v-btn
                        color="action white--text"
                        :to="{ name: 'team_edit', params: { id: member ? member.team_id : null } }"
                    >
                        {{ $t('team_go') }}: {{ member ? member.team_name : '' }}
                    </v-btn>
                </v-card-text>
            </v-fade-transition>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { instance as getApi } from 'js/lib/api.js';
import { errToStr } from 'js/lib/helpers.js';
import { formRules } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    data() {
        return {
            error: null,
            code: null,
            loading: false,
            activationLoading: false,
            formRules,
            mode: 'code',
            member: null
        };
    },
    computed: {
        activateCodeInput() {
            return this.loading === true || this.code !== null;
        }
    },

    created() {
        this.code = this.$route.query.code || null;
    },

    beforeRouteUpdate(to) {
        this.code = to.query.code || null;
    },

    methods: {
        ...mapActions(['runAsync']),
        async onActivateBtn() {
            this.error = null;
            this.activationLoading = true;
            this.runAsync(async () => {
                try {
                    let ret = await getApi().post('/auth/joinTeam', {
                        code: this.code
                    });
                    this.mode = 'success';
                    this.member = ret.data;
                } catch (e) {
                    this.error = errToStr(e);
                } finally {
                    this.activationLoading = false;
                }
            });
        }
    }
};
</script>
