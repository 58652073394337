<template>
    <v-combobox
        :items="eigentuemerschaften ? eigentuemerschaften : []"
        item-text="bezeichnung"
        item-value="bezeichnung"
        :label="label"
        :dense="dense"
        :value="value"
        :loading="eigentuemerschaftIsLoading"
        :rules="rules"
        hide-selected
        @input="onSelect"
    ></v-combobox>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: String,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            eigentuemerschaftIsLoading: false,
            eigentuemerschaften: []
        };
    },

    created() {
        this.load();
    },

    methods: {
        ...mapActions('eigentuemerschaft', ['fetchEigentuemerschaften']),
        async load() {
            this.eigentuemerschaftIsLoading = true;
            try {
                let eigentuemerschaften = await this.fetchEigentuemerschaften();
                if (eigentuemerschaften) {
                    this.eigentuemerschaften = eigentuemerschaften.models.map((a) => a.bezeichnung);
                }
            } finally {
                this.eigentuemerschaftIsLoading = false;
            }
        },

        onSelect(value) {
            this.$emit('input', value);
        }
    }
};
</script>

<style></style>
