<template>
    <span :class="['status', status, badge ? 'badge' : '']">{{ statusText }}</span>
</template>

<script>
import { STATUS } from 'js/models/Gesuch';

export default {
    props: {
        status: {
            type: String,
            default: null
        },
        badge: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },

    computed: {
        statusText() {
            switch (this.status) {
                case STATUS.AB_APPROVED:
                    return this.$t('apollon_status_ab_approved');
                case STATUS.AB_CANCELED:
                    return this.$t('apollon_status_ab_canceled');
                case STATUS.AB_IN_PROGRESS:
                    return this.$t('apollon_status_ab_in_progress');
                case STATUS.AB_INCOMPLETE:
                    return this.$t('apollon_status_ab_incomplete');
                case STATUS.AB_REJECTED:
                    return this.$t('apollon_status_ab_rejected');
                case STATUS.AB_SUBMIT:
                    return this.$t('apollon_status_ab_submit');
                case STATUS.APPROVED:
                    return this.$t('apollon_status_approved');
                case STATUS.AB_READY_FOR_SUBMIT:
                    return this.$t('apollon_status_ab_ready_for_submit');
                case STATUS.CANCELED:
                    return this.$t('apollon_status_canceled');
                case STATUS.CLOSED:
                    return this.$t('apollon_status_closed');
                case STATUS.IN_PROGRESS:
                    return this.$t('apollon_status_in_progress');
                case STATUS.INCOMPLETE:
                    return this.$t('apollon_status_incomplete');
                case STATUS.NEW:
                    return this.$t('apollon_status_new');
                case STATUS.READY_FOR_SUBMIT:
                    return this.$t('apollon_status_ready_for_submit');
                case STATUS.REJECTED:
                    return this.$t('apollon_status_rejected');
                case STATUS.SUBMIT:
                    return this.$t('apollon_status_submit');
                default:
                    return `(${this.$t('apollon_status_' + this.status)})`;
            }
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.status {
    font-size: 1em;
    &.new {
        color: red;
    }
    &.ab_approved {
        color: rgb(96, 187, 96);
    }
    &.ab_canceled {
        color: rgb(190, 167, 32);
    }
    &.ab_in_progress {
        color: rgb(190, 167, 32);
    }
    &.ab_incomplete {
        color: red;
    }
    &.ab_rejected {
        color: red;
    }
    &.ab_submit {
        color: rgb(96, 187, 96);
    }
    &.approved {
        color: rgb(96, 187, 96);
    }
    &.canceled {
        color: rgb(190, 167, 32);
    }
    &.closed {
        color: rgb(96, 187, 96);
    }
    &.in_progress {
        color: rgb(190, 167, 32);
    }
    &.incomplete {
        color: red;
    }
    &.ready_for_submit {
        color: rgb(190, 167, 32);
    }
    &.ab_ready_for_submit {
        color: rgb(190, 167, 32);
    }
    &.rejected {
        color: red;
    }
    &.submit {
        color: rgb(96, 187, 96);
    }
    &.badge {
        color: white;
        text-shadow: 1px 1px 0 #464646;
        border-radius: 1em;
        padding: 0.1em 0.4em;
        line-height: 1em;
        vertical-align: middle;
        &.new {
            background-color: red;
        }
        &.ab_approved {
            background-color: rgb(96, 187, 96);
        }
        &.ab_canceled {
            background-color: rgb(190, 167, 32);
        }
        &.ab_in_progress {
            background-color: rgb(190, 167, 32);
        }
        &.ab_incomplete {
            background-color: red;
        }
        &.ab_rejected {
            background-color: red;
        }
        &.ab_submit {
            background-color: rgb(96, 187, 96);
        }
        &.approved {
            background-color: rgb(96, 187, 96);
        }
        &.canceled {
            background-color: rgb(190, 167, 32);
        }
        &.closed {
            background-color: rgb(96, 187, 96);
        }
        &.in_progress {
            background-color: rgb(190, 167, 32);
        }
        &.incomplete {
            background-color: red;
        }
        &.ready_for_submit {
            background-color: rgb(190, 167, 32);
        }
        &.ab_ready_for_submit {
            background-color: rgb(190, 167, 32);
        }
        &.rejected {
            background-color: red;
        }
        &.submit {
            background-color: rgb(96, 187, 96);
        }
    }
}
</style>
