/**
 * i18n functionality
 *
 * Part of the Zeus application
 * (c) Kaden und Partner AG
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const texts = {
    // apollonStrings must be loaded already:
    de: window.apollonStrings || {}
};

export const vueI18n = new VueI18n({
    locale: 'de',
    messages: texts
});
