<template>
    <v-combobox
        :items="anreden ? anreden : []"
        item-text="bezeichnung"
        item-value="bezeichnung"
        :label="label"
        :dense="dense"
        :value="value"
        :loading="anredeIsLoading"
        hide-selected
        @input="onSelect"
    ></v-combobox>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: String,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            anredeIsLoading: false,
            anreden: []
        };
    },

    created() {
        this.load();
    },

    methods: {
        ...mapActions('anrede', ['fetchAnreden']),
        async load() {
            this.adrLoading = true;
            try {
                let anreden = await this.fetchAnreden();
                if (anreden) {
                    this.anreden = anreden.models.map((a) => a.bezeichnung);
                }
            } finally {
                this.adrLoading = false;
            }
        },

        onSelect(value) {
            this.$emit('anredechanged', value.trim());
            this.$emit('input', value.trim());
        }
    }
};
</script>

<style></style>
