/**
 * Store module: kanton
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { Kanton } from 'js/models/Kanton';
import { kantone } from 'js/lib/helpers';

export default {
    namespaced: true,
    state: {
        records: kantone.map((kanton) => new Kanton(kanton))
    },
    getters: {
        getKantonByKuerzel: (state) => (kuerzel) => {
            for (let k of state.records) {
                if (k.kuerzel === kuerzel.toLowerCase()) {
                    return k;
                }
            }
            return null;
        }
    },
    actions: {}
};
