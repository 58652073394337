/**
 * Mitteilung Model
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';
import dayjs from 'dayjs';

export const MODE = {
    STANDARD: 'standard',
    BROADCAST: 'broadcast',
    ENERGIEFACHSTELLE: 'energiefachstelle'
};
export class Mitteilung extends BaseModel {
    static get ROLLE_GESUCHSPRUEFER() {
        return 'gesuchspruefer';
    }

    defaults() {
        return {
            id: null,
            gesuch_id: null,
            zeus_ersteller_id: null,
            apollon_ersteller_id: null,
            created: null,
            modified: null,
            deleted_at: null,
            subject: null,
            mitteilung: null,
            ersteller_rolle: null,
            empfaenger_email: [],
            mode: null,

            // View fields:
            gesuch_nummer: null,
            ersteller: null,
            ersteller_telefon: null,
            empfaenger_rollen: [],
            empfaenger_apollon_ids: [],
            gelesen_zeus_ids: [],
            gelesen_apollon_ids: [],
            gelesen_rollen: [],
            attachments: [],
            is_deleted: false
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            gesuch_id: (id) => (Number(id) > 0 ? Number(id) : null),
            zeus_ersteller_id: (id) => (Number(id) > 0 ? Number(id) : null),
            apollon_ersteller_id: (id) => (Number(id) > 0 ? Number(id) : null),
            created: dayjs,
            modified: dayjs,
            deleted_at: dayjs,
            is_deleted: Boolean
        };
    }

    entityName() {
        return 'mitteilung';
    }

    /** custom request to request deletion (but not remove from collection) */
    markDeleted() {
        let method = this.getOption('methods.delete');
        let route = this.getRoute('delete');
        let params = this.getRouteParameters();
        let url = this.getURL(route, params);

        return this.createRequest({ method, url }).send();
    }
}

export class MitteilungCollection extends BaseCollection {
    options() {
        return {
            model: Mitteilung
        };
    }

    entityName() {
        return 'mitteilung';
    }
}
