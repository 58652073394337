/**
 * Store module: apollonConfFk
 *
 * Part of the Zeus Apollon application
 * (c) Kaden und Partner AG
 */
import { instance as getApi } from 'js/lib/api.js';

export default {
    namespaced: true,
    state: {
        records: null,
        loading: false
    },
    mutations: {
        loading(state, isLoading) {
            state.loading = !!isLoading;
        },
        setRecords(state, recs) {
            state.records = recs || [];
        }
    },
    actions: {
        /**
         * Loads all apollonConfFk entities, once, and commits it to the store
         * while also returning them.
         *
         * @param {Object} store The vuex store
         */
        async fetchApollonConfFk({ commit, state }) {
            if (state.records || state.loading) {
                return state.records;
            }
            try {
                commit('loading', true);
                let response = await getApi().get(`/lookup/apollonConfFk`, {});
                commit('setRecords', response.data.records);
            } finally {
                commit('loading', false);
            }
            return state.records;
        }
    },
    getters: {}
};
