/**
 * Several small helper functions and common data
 *
 * Part of the Zeus application
 * (c) Kaden und Partner AG
 */

import { vueI18n } from 'js/lib/i18n';
import dayjs from 'dayjs';
import { TgWappenSvg, ShWappenSvg } from 'js/lib/images';

/**
 * An array with all Kantone
 */
export const kantone = [
    { kuerzel: 'ag', kurz: 'AG', lang: 'Aargau' },
    { kuerzel: 'ai', kurz: 'AI', lang: 'Appenzell Innerrhoden' },
    { kuerzel: 'ar', kurz: 'AR', lang: 'Appenzell Ausserrhoden' },
    { kuerzel: 'be', kurz: 'BE', lang: 'Bern' },
    { kuerzel: 'bl', kurz: 'BL', lang: 'Basel-Landschaft' },
    { kuerzel: 'bs', kurz: 'BS', lang: 'Basel-Stadt' },
    { kuerzel: 'fr', kurz: 'FR', lang: 'Freiburg' },
    { kuerzel: 'ge', kurz: 'GE', lang: 'Genf' },
    { kuerzel: 'gl', kurz: 'GL', lang: 'Glarus' },
    { kuerzel: 'gr', kurz: 'GR', lang: 'Graubünden' },
    { kuerzel: 'ju', kurz: 'JU', lang: 'Jura' },
    { kuerzel: 'lu', kurz: 'LU', lang: 'Luzern' },
    { kuerzel: 'ne', kurz: 'NE', lang: 'Neuenburg' },
    { kuerzel: 'nw', kurz: 'NW', lang: 'Nidwalden' },
    { kuerzel: 'ow', kurz: 'OW', lang: 'Obwalden' },
    { kuerzel: 'sg', kurz: 'SG', lang: 'St. Gallen' },
    { kuerzel: 'sh', kurz: 'SH', lang: 'Schaffhausen', icon: ShWappenSvg },
    { kuerzel: 'so', kurz: 'SO', lang: 'Solothurn' },
    { kuerzel: 'sz', kurz: 'SZ', lang: 'Schwyz' },
    { kuerzel: 'tg', kurz: 'TG', lang: 'Thurgau', icon: TgWappenSvg },
    { kuerzel: 'ti', kurz: 'TI', lang: 'Tessin' },
    { kuerzel: 'ur', kurz: 'UR', lang: 'Uri' },
    { kuerzel: 'vd', kurz: 'VD', lang: 'Waadt' },
    { kuerzel: 'vs', kurz: 'VS', lang: 'Wallis' },
    { kuerzel: 'zg', kurz: 'ZG', lang: 'Zug' },
    { kuerzel: 'zh', kurz: 'ZH', lang: 'Zürich' }
];

export const addressTypes = {
    person: { lang_key: 'person', value: 'person' },
    organisation: { lang_key: 'organisation', value: 'organisation' }
};

export const gesuchRoles = {
    eigentuemer: { ident: 'eigentuemer', text: vueI18n.t('eigentuemer') },
    unternehmer: { ident: 'unternehmer', text: vueI18n.t('unternehmer') },
    bevollmaechtigter: { ident: 'bevollmaechtigter', text: vueI18n.t('bevollmaechtigter') },
    kontakt: { ident: 'kontakt', text: vueI18n.t('kontakt') }
};

/**
 * Common form rules, to be used as rules in vuetify forms
 */
export const formRules = {
    /** Value is required: */
    required: (value) => !!value || vueI18n.t('value_please'),

    /** Must be an email address: */
    email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return value ? pattern.test(value) || vueI18n.t('invalid_email') : true;
    },

    /** Only alpha-chars */
    charsOnly: (value) => {
        const antiPattern = /[0-9]/;
        return value ? !antiPattern.test(value) || vueI18n.t('chars_only') : true;
    },

    /**
     * Creator function: use with formRules.minLength(8) to get a min-8-length validator function:
     */
    minLength: (minLength, ignoreWhitespace) => (value) => {
        if (value && ignoreWhitespace) {
            value = value.replaceAll(' ', '');
        }
        return (value || '').length >= minLength || vueI18n.t('min_chars', { chars: minLength });
    },
    /**
     * Creator function: use with formRules.maxLength(10) to get a max-10-length validator function:
     */
    maxLength: (maxLength, ignoreWhitespace) => (value) => {
        if (value && ignoreWhitespace) {
            value = value.replaceAll(' ', '');
        }
        return (value || '').length <= maxLength || `${value.length}/${maxLength}`;
    },

    /**
     * Creator function: use with formRules.between(1000,2000) to allow numbers between 1000 and 2000
     */
    between: (min, max, errorMsg) => (value) => {
        if (min === null && max === null) {
            return true;
        }
        if (min === null && max !== null) {
            return value === null || value <= max ? true : errorMsg || vueI18n.t('value_max', { max });
        }
        if (max === null && min !== null) {
            return value === null || value >= min ? true : errorMsg || vueI18n.t('value_min', { min });
        }
        return value === null || (value >= min && value <= max)
            ? true
            : errorMsg || vueI18n.t('value_between', { min, max });
    },

    /**
     *  UID Number in the format like CHE-106.050.103
     */
    uid: (value) => {
        const pattern = /^CHE-[0-9][0-9][0-9]\.[0-9][0-9][0-9]\.[0-9][0-9][0-9]$/;
        return value ? pattern.test(value) || vueI18n.t('invalid_uid') : true;
    },

    dateFormat: (dateStr) => (value) => {
        return !value || dayjs(value, dateStr, true).isValid() || vueI18n.t('wrong_format', { format: dateStr });
    },

    isInt: () => (value) => (value ? /^\d*$/.test(value) || vueI18n.t('int_only') : true),

    houseNumber: (value) => {
        const pattern = /^[0-9a-zA-Z]*\.?[0-9a-zA-Z]*$/;
        return value ? pattern.test(value) || vueI18n.t('invalid_house_number') : true;
    },

    iban: (value) => {
        if (!value) {
            return false;
        }
        if (value.startsWith('CH') || value.startsWith('LI')) {
            value = value.replaceAll(' ', '');
            return (value || '').length === 21 || `${value.length}/${21}`;
        }
        if (value.startsWith('AT')) {
            value = value.replaceAll(' ', '');
            return (value || '').length === 20 || `${value.length}/${20}`;
        }
        if (value.startsWith('DE')) {
            value = value.replaceAll(' ', '');
            return (value || '').length === 22 || `${value.length}/${22}`;
        }
        return true;
    }
};

export function debounce(fn, millis = 350) {
    let timeout;
    return function () {
        let context = this;
        let args = arguments;
        let callbackFn = function () {
            timeout = null;
            fn.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(callbackFn, millis);
        if (!timeout) {
            fn.apply(context, args);
        }
    };
}

export async function awaitAsyncFunction(asyncFn, vuexStore) {
    return await vuexStore.dispatch('runAsync', asyncFn);
}

export function formatTelefonNumber(value) {
    var cleaned = ('' + value).replace(/\D/g, '');
    var match = cleaned.match(/^(0041|041|\+41|\+\+41|41)?(0|\(0\))?([1-9]\d{1})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        value = (match[1] ? '+41 ' : '0') + match[3] + ' ' + match[4] + ' ' + match[5] + ' ' + match[6];
    }
    return value;
}

/**
 * Tries to make sense of the given "Error", which may be a string,
 * an error object, some kind of response.... you name it.
 * @param {Something} error The error "thingie"
 * @return A string representing the error msg.
 */
export function errToStr(error) {
    let msg = 'Unbekannter Fehler';

    if (error) {
        // Try to extract a data property:
        let data =
            (error.response && error.response.data) ||
            (error.response && error.response.response && error.response.response.data);

        if (data && data.error && data.error.msg) {
            msg = data.error.msg;
        } else if (error.response && error.response.responseText) {
            let obj = JSON.parse(error.response.responseText);
            if (obj && obj.error && obj.error.msg) {
                msg = `${obj.error.code}: ${obj.error.msg}`;
            } else {
                msg = String(error.response.responseText);
            }
        } else if (error.responseText) {
            let obj = JSON.parse(error.responseText);
            if (obj && obj.error && obj.error.msg) {
                msg = `${obj.error.code}: ${obj.error.msg}`;
            } else {
                msg = String(error.responseText);
            }
        } else if (typeof error === 'string') {
            let obj = JSON.parse(error);
            if (obj && obj.error && obj.error.msg) {
                msg = `${obj.error.code}: ${obj.error.msg}`;
            } else {
                msg = String(error);
            }
        } else {
            msg = String(error);
        }
    }
    return msg;
}

/**
 * Tests if two object are equal as their properties are equal (not their references)
 * @param {Object} objA
 * @param {Object} objB
 */
export function objectEquality(objA, objB) {
    const aProps = Object.getOwnPropertyNames(objA);
    const bProps = Object.getOwnPropertyNames(objB);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];

        if (objA[propName] !== objB[propName]) {
            return false;
        }
    }

    return true;
}

/**
 * Mixin for form components:
 * It defines a route guard mixin to ask before leave if the form is dirty:
 * If the form component defines a 'isDirty' method, it is called before the route
 * is left. isDirty() must return true if the form is dirty. Then a confirmation msg.
 * is shown and the route is either executed or cancelled.
 *
 * The following properties on the target component are evaluated:
 *
 * - method 'this.isDirty()': should return true if the form is dirty and needs a confirmation
 * - property 'this.notSavedMsg': The message to ask the user (defaults to $t(form_not_saved))
 */
export const routeGuardFormConfirmMixin = {
    beforeRouteUpdate(to, from, next) {
        routeGuardFormConfirmFn(this.isDirty, this.notSavedMsg, next);
    },
    beforeRouteLeave(to, from, next) {
        routeGuardFormConfirmFn(this.isDirty, this.notSavedMsg, next);
    }
};

/**
 * Helper function for the routeGuardFormConfirmMixin, see above
 *
 * @param {Function} isDirty
 * @param {String} notSavedMsg
 * @param {Function} next
 */
function routeGuardFormConfirmFn(isDirty, notSavedMsg, next) {
    if (isDirty && isDirty()) {
        if (window.confirm(notSavedMsg || vueI18n.t('form_not_saved'))) {
            return next();
        } else {
            return next(false);
        }
    } else {
        return next();
    }
}

export function saveToLocalStorage(key, value) {
    if (Storage) {
        localStorage.setItem('apollon.' + key, JSON.stringify(value));
    }
}

export function getFromLocalStorage(key) {
    if (Storage) {
        return JSON.parse(localStorage.getItem('apollon.' + key));
    }
    return null;
}

export function completeStreetString(street) {
    if (street && street.toLowerCase().endsWith('str')) {
        return street + 'asse';
    }
    if (street && street.toLowerCase().endsWith('str.')) {
        return street.substring(0, street.length - 1) + 'asse';
    }
    return street;
}
