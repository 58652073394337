/**
 * News Entity
 *
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';
import dayjs from 'dayjs';

export class News extends BaseModel {
    defaults() {
        return {
            id: null,
            titel: null,
            inhalt: null,
            kanton: [],
            anzeige_von: null,
            anzeige_bis: null,
            ersteller_id: null,
            statische_anzeige: null,

            // View fields
            ersteller_name: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            ersteller_id: (id) => (Number(id) > 0 ? Number(id) : null),
            statische_anzeige: (val) => !!val,
            created: dayjs,
            modified: dayjs
        };
    }

    entityName() {
        return 'news';
    }
}

export class NewsCollection extends BaseCollection {
    options() {
        return {
            model: News
        };
    }

    entityName() {
        return 'news';
    }
}
